import React, { Component} from 'react';
import { getAPI, postAPI, postFile, putAPI,deleteAPI } from '../utils/api';
import { Link } from 'react-router-dom';
import {storageUrl} from '../utils/BaseUrl';


export default class Resources extends Component {
    state={
        resources:[],
        isLoading:false,
        resourceObj:{}
    };

    componentDidMount(){
        this.getResources();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
          this.getResources();
        }
      }
    getResources=()=>{
        this.setState({isLoading:true})
        getAPI(`resource/resource?language=${this.props.langId}`).then(res=>{
            const {status,data}=res.data;
            // console.log(status,data);
            if(status===1){
                // console.log(data);
                this.setState({resources:data,isLoading:false})
            }else{
                this.setState({resources:[],isLoading:false})
            }
        })
    }
    handleDeleteSelect=(Obj)=>{
        this.setState({resourceObj:Obj})
    }
    handleDelete=()=>{
        const d={};
        deleteAPI(`resource/resource?uid=${this.state.resourceObj.uid}`,d).then(res=>{
            const {status}=res.data;
            // console.log(status);
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
        const {isLoading,resources,resourceObj}=this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Resources</h1>
                                </div>
                                <div className="col-auto">
                                    <a href="/createresources" className="btn btn-primary lift"> Create Resources </a>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-header-title">All Resources </h4>
                                </div>
                                <div className="table-responsive mb-0">
                                    <table className="table table-sm table-wrap card-table">
                                        <thead>
                                            <tr>
                                                <th>Resource Type</th>
                                                <th>Title</th>
                                                <th colSpan="2">logo</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading?
                                            <tr>
                                            <td colspan="3"> 
                                                <div className="text-center">
                                                    <div class="spinner-border text-danger" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                            :
                                            resources.map((item,idx)=>(
                                                <tr key={idx}>
                                                <td>{item?.resourceType?.name['en']}</td>
                                                <td>{item?.title}</td>
                                                <td>
                                                    <div className="d-flex justify-content-between align-items-center">
                                                    <div className="box_img">
                                                        <img src={`${storageUrl+item.logo}`} alt="..." className="img-fluid rounded" />
                                                    </div>
                                                    <Link to={{pathname:'viewresources',state:{data:item}}}className="view_btn" type="button">View more</Link>
                                                    </div>
                                                </td>
                                              <td className="text-right">
                                                <div className="dropdown">
                                                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical" />
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right">
                                                        {/* <a href="/viewresources" className="dropdown-item">
                                                           View More
                                                         </a> */}
                                                         <a href="#!" className="dropdown-item" data-target="#deleteresource" data-toggle="modal" onClick={e=>this.handleDeleteSelect(item)}>
                                                           Delete
                                                         </a>
                                                    </div>
                                                </div>
                                              </td>
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12">
                        <nav aria-label="Page navigation example ">
                            <ul class="pagination pagination-lg justify-content-center">
                            <li class="page-item "><a class="page-link" href="#!">Next</a></li>
                            <li class="page-item active"><a class="page-link" href="#!">1</a></li>
                            <li class="page-item"><a class="page-link" href="#!">2</a></li>
                            <li class="page-item"><a class="page-link" href="#!">Previous</a></li>
                            </ul>
                        </nav>

                        </div> */}
                    </div>
                </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="deleteresource" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Resource <span className="text-color">{resourceObj.title } ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-primary btn-lg mr-3"   onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                            <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
            </div>

        )
    }
}