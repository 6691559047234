import React, {Component} from 'react'
import Dropzone from 'react-dropzone'

export default class HomeTextBannerModal extends Component {
  constructor(){
    super();
    this.state = {
        errMsg: '',
        
      title: '',
      sub: '',
      country: '',
      name: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const {selectedBanner} = nextProps;
    if(selectedBanner){
      const {title, sub, country, name} = selectedBanner;
      return {title, sub, country, name}
    } else {
      return null
    }
  }

  render(){
    const {title, sub, country, name} = this.state;
    const {handleChange, handleSave, submitting} = this.props;
    return(
      <React.Fragment>
          <div
                className="modal fade fixed-right"
                id="txtbanner"
                tabIndex={-1}
                role="dialog"
                aria-modal="true"
              >
                <div className="modal-dialog modal-dialog-vertical" role="document">
                  <form className="modal-content" id="demoForm">
                    <div className="modal-body">
                      <a className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </a>
                      <h2 className="text-left mb-2">Text Banner</h2>
                      <small className="form-text text-muted">Sub Title Banner</small>
                      {/* Divider */}
                      <hr className="mb-4" />
                      <div className="form-box">
                        <div className="form-group">
                          <label htmlFor="Title">Name</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="name"
                            placeholder="Enter Title"
                            name="name"
                            value={name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                          <label htmlFor="Description">Description</label>
                          <textarea className="form-control" id="Description focus-out" rows="3"
                            name="sub"
                            value={sub}
                            onChange={handleChange}>

                          </textarea>
                      </div>
                      <div className="form-group">
                          <label htmlFor="Title">Country</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="country"
                            placeholder="Enter country"
                            name="country"
                            value={country}
                            onChange={handleChange}
                          />
                        </div>
                    </div>
                    <div className="modal-footer border-0 text-center">
                      {/* Button */}
                      <button type="button" className="btn btn-primary ml-auto" disabled={submitting}
                        onClick={(e) => handleSave(e, 'textBanner', 1)}>
                        {submitting ? 'Saving...' : 'Save'}
                      </button>
                      <button type="button" className="btn btn-primary mr-auto"  data-dismiss="modal" disabled={submitting}>
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
      </React.Fragment>
		)
	}
}