//------------------------------------for-local-----------------------------------*/

//export const Baseurl = 'http://localhost:3101/';

//------------------------------------for-staging-----------------------------------*/

// export const Baseurl ='http://18.208.41.11:3201/';
// export const storageUrl = 'https://knitterspride-dev.s3.amazonaws.com/'

//------------------------------------for-live------------------------------------*/
export const Baseurl =process.env.React_App_BASE_URL;
export const storageUrl =process.env.React_App_STORAGE_URL;
