import React, {Component} from 'react';
// import ReactQuill from 'react-quill' // ES6
// import 'react-quill/dist/quill.snow.css';

import { getAPI, postAPI, putAPI } from '../../utils/api';

import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';



export default class PrivacyPolicy extends Component {
  state = {
    isLoading: true,
    submitting: false,
    textBanner: [{title: 'New Banner', sub: 'description'}],

    apiList: [ 'textBanner'],
    selectedBanner: {title: 'code'},
    ogFields: {}
  }

  componentDidMount(){
    console.log(this.props.language)
    const errors = []
    this.state.apiList.map(item => {
      getAPI(`template/getTemplate?menuId=7&type=${item}&lang=${this.props.language}`)
        .then(res => {
          const {data, status} = res.data;
          // console.log(data)
          data && (status === 1 
            ? this.setState({[item]: data, selectedBanner: data[0], isLoading: false, ogFields: (data[0].ogFields)[this.props.language]}) 
            : errors.push(item))
        }).catch(err => {console.log(`error${item}:`, err)});      
      return null 
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.componentDidMount();
    }
  }

  handleChange = (e) => {
    const {selectedBanner} = this.state;
    const newData = {...selectedBanner, [e.target.name]: e.target.value}
    this.setState({selectedBanner: newData})
  }

  onCodeChange = (code) => {
    const {selectedBanner} = this.state;
    const newData = {...selectedBanner, title: code}
    this.setState({selectedBanner: newData});
  }

  handleSave = (e, name, menuId) => {
    const {language} = this.props;
    const {selectedBanner, ogFields} = this.state;
    // const {image} = selectedBanner;
    
     
      this.setState({submitting: true}); 
      let newObj = {
        ...selectedBanner,
        title: {[language]: selectedBanner.title},
        sub: {[language]: ''},
        // buttonText: {[language]: selectedBanner.buttonText},
      }
      
      if(newObj.id){
        const data = {
          "type": name,
          "menuId": menuId,
          "templateData": newObj,
          "ogFields": ogFields,
          "lang":this.props.language
        }
        console.log(data)
        putAPI('template/editTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({submitting: false});
        }).catch(error => {
          console.log(error)
        })
      } else {
        const data = {
          "templateType": name,
          "menuId": menuId,
          "templateData": newObj,
          "ogFields": ogFields,
          "lang":this.props.language
        }
        postAPI('template/addTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({submitting: false});
        }).catch(error => {
          console.log(error)
        })
      }    
  }

  handleOgDataChange = e => {
    console.log(e.target.getAttribute('name'), e.target.value)
    this.setState({
        ogFields:{
            ...this.state.ogFields,
            [e.target.getAttribute('name')] : e.target.value
            
        }
    })
  }

  render(){
    const {selectedBanner, submitting, isLoading, ogFields} = this.state;
    const {title} = selectedBanner;
    const options = {
			lineNumbers: true,
		};
    console.log(selectedBanner)

    return(isLoading ? '' : 
            <React.Fragment>
              
              <div className="main-section pt-5">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="d-flex">
                        <h1 className="header-title">Privacy Policy</h1>
                      </div>
                    </div>
                  </div>
                </div>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="spac w-100" />
                      {/* <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            placeholder="Title"
                            name="title"
                            value={title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div> */}
                      {/* <div className="col-sm-12">
                      <div className="form-group">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control focus-out"
                          rows={20}
                          placeholder="Enter Description"
                          name="sub"
                          value={sub}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div> */}
                    <div className="col-sm-12">
                      <div className="form-group" style={{border: '1px solid rgba(0, 0, 0, 0.125)'}}>
                        {/* <ReactQuill theme="snow" modules={this.modules}
                            formats={this.formats}  onChange={this.rteChange}
                            value={this.state.comments || ''}
                        /> */}
                        {/* <ReactSummernote
                          value="Default value"
                          options={{
                            height: 350,
                            // dialogsInBody: true,
                            toolbar: [                          
                              ['view', ['codeview']]
                            ]
                          }}
                          codeview={true}
                          onChange={this.onChange}
                        /> */}
                        
                        <CodeMirror value={title} onChange={this.onCodeChange} options={options} />
                      </div>
                      {/* OG tags fields */}
                <div className="col-12">
                  <div className="spac w-100" />
                    <div className="form-group">
                        <label>OG Title</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Title..."
                            required="required"
                            name="ogTitle"
                            value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>OG Description</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Description..."
                            required="required"
                            name="ogDescription"
                            value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>OG Image</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Image..."
                            required="required"
                            name="ogImage"
                            value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                    <hr className="my-5"/>
                </div>
                      <div className='form-group'>
                      <button type="button" className="btn btn-primary mr-auto" disabled={submitting}
                        onClick={(e) => this.handleSave(e, 'textBanner', 7)}
                      >
                        {submitting ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                </div>

                
                
              </div>
            </div>
            <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
              </div>

            </React.Fragment>
          )
        }
      }