import React, {Component} from 'react'

export default class SocialFeatureBannerModal extends Component {
  state={ 
    err_image_msg:'',

            title: '',
            sub: '',
            image: '',
            buttonText: '',
            buttonLink: '',
            file: []
        }
      
    
      static getDerivedStateFromProps(nextProps, prevState){
        const {selectedBanner} = nextProps;
        if(selectedBanner){
          const {title, sub, image, buttonText, buttonLink, file} = selectedBanner;
          return {title, sub, image, buttonLink, buttonText, file}
        } else {
          return null
        }
      }
  render(){
    const {title, sub, image, buttonText, buttonLink, file} = this.state;
    const {handleChange, handleSave, onDrop, submitting, imageUploading} = this.props;
    return(
			          <React.Fragment>
                  <div
                    className="modal fade fixed-right"
                    id="social_feature"
                    tabIndex={-1}
                    role="dialog"
                    aria-modal="true"
                  >
                    <div className="modal-dialog modal-dialog-vertical" role="document">
                      <form className="modal-content" id="demoForm">
                        <div className="modal-body">
                          <a className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </a>
                          <h2 className="text-left mb-2">Create New Feature Banner 1</h2>
                          <small className="form-text text-muted">Sub Title Banner</small>
                          {/* Divider */}
                          <hr className="mb-4" />
                          <div className="form-box">
                            <div className="form-group">
                              <label htmlFor="Title">Title</label>
                              <input
                                type="text"
                                className="form-control focus-out"
                                id="Title"
                                placeholder="Enter Title"
                                name="title"
                                  value={title}
                                  onChange={handleChange}
                              />
                            </div>
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <textarea className="form-control" id="description" rows="3" name="sub"
                                  value={sub}
                                  onChange={handleChange}
                              ></textarea>
                            </div>
                          </div>
                          {/* <div className="upload-img-box border w-100 position-relative">
                            <div className="dz-default dz-message">
                              <button className="dz-button position-relative" type="button">
                                <input type="file" className="back-inp c-pointer" />
                                Drop files here to upload
                              </button>
                            </div>
                          </div> */}
                        </div>
                        <div className="modal-footer border-0 text-center">
                        <button type="button" className="btn btn-white ml-auto" data-dismiss="modal" disabled={imageUploading || submitting}>
                            Cancel
                          </button>
                          <button type="button" className="btn btn-primary mr-auto"disabled={imageUploading || submitting}
                              onClick={(e) => handleSave(e, 'textBanner1', 3)}
                            >
                              {submitting ? 'Saving...' : 'Save'}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
              </React.Fragment>
			)

	}
}