import React, { Component } from 'react'
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';
import Dropzone from 'react-dropzone';
import {putAPI,postMultipleFile, getAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';

export default class Mailer extends Component {
    state={
        uid:"",
        htmlbody:"",
        subject:"",
        hasAttachment:true,
        attachment:[],
        imageUploading:false,
        isLoading:true,
        issubmitting:false
    }
    componentDidMount(){
        this.getMailTemplate();
    }
    componentDidUpdate(prevProps){
        if (this.props.language !== prevProps.language) {
             this.setState({isLoading:true});
            this.getMailTemplate();

        }
    }
    getMailTemplate=()=>{
        getAPI(`newsletterTemplate/template/${this.props.langId}`).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({uid:data.uid,hasAttachment:data.hasAttachment,htmlbody:data.body,attachment:data.attachments,subject:data.subject,isLoading:false})
            }
            // console.log(data,status);
        }).catch(err=>{
            console.log(err);
        })
    }
    handleChange=(e)=>{
        const {name,value}=e.target;
        this.setState({[name]:value})
    }
    onCodeChange=(code)=>{
        this.setState({htmlbody:code})
    }
    onDropBody=(acceptedFiles)=>{
        this.setState({ imageUploading: true })
            let attachments = [];
            postMultipleFile('upload/upload/attachments', acceptedFiles).then(res => {
                const { status, data } = res.data;
                if (status === 1) {
                    for (let i = 0; i < data.length; i++) {
                        attachments[i] = storageUrl+ data[i].s3key;
                    }
                    this.setState({ imageUploading: false, attachment: attachments })
                }
                else {
                    alert('Files not Uploaded')
                }
            }).catch(err => {
                console.log('Error', err);
            })
    }
    handleAttachment=(e,flag)=>{
        this.setState({hasAttachment:flag})
    }
    routeChange = () => {
        let path = `/promo`;
        this.props.history.push(path);
    }
    handleSave=()=>{
        this.setState({issubmitting:true})
        const {hasAttachment,attachment,subject,uid,htmlbody}=this.state;

        let data={
            hasAttachment,
            attachments:attachment,
            subject,
            body:htmlbody
        }
        putAPI(`newsletterTemplate/template/${uid}`,data).then(res=>{
            // console.log(res);
            this.setState({issubmitting:true});
            let path = `/promo`;
            this.props.history.push(path);
        }).catch(err=>{
            console.log(err);
        })
        // console.log(data);
    }
    render() {
        const options = {
			lineNumbers: true,
        };
        const {htmlbody,subject,attachment,hasAttachment,imageUploading,isLoading,issubmitting}=this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Mail Template</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                {isLoading?
                <div className="form-group text-center">
                <div class="spinner-border text-danger text-center" role="status">
                <span class="sr-only">Loading...</span>
              </div>
              </div>
              :
                    <div className="row">
                    <div className="col-12">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input type="text" className="form-control" placeholder="subject.."
                                        name="subject" onChange={this.handleChange} value={subject}/>
                                </div>
                            </div>
                        <div className="col-12">
                        <label>Body</label>
                            <div className="form-group" style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }}>
                                <CodeMirror value={htmlbody} onChange={this.onCodeChange} options={options}/>
                            </div>        
                        </div>
                        <div className="col-12">
                            <label>Has attachments ? </label>
                            <br/>
                            <label htmlFor="male" className="mr-3"> Yes</label>
                                        <input type="radio" id="Promo" name="select" defaultValue="Promo" className="mr-3 top-3" defaultChecked={hasAttachment} onClick={e=>this.handleAttachment(e,true)}/>
                                        <label htmlFor="female" className="mr-3"> No</label>
                                        <input type="radio" id="Form" name="select" defaultValue="Form" className="top-3" defaultChecked={!hasAttachment} onClick={e=>this.handleAttachment(e,false)}/>
                        </div>
                        {hasAttachment?
                        <div className="col-12">
                                <div className="form-group">
                                    <label>Upload Attachment</label>
                                    <div className="upload-img-box border w-100 position-relative over-hidden f-out">
                                        <div className="dz-default dz-message py-5">
                                            <Dropzone onDrop={e => this.onDropBody(e)} accept={'image/*,.pdf'}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} className="back-inp c-pointer" />
                                                        <span className="dz-button center-box">Drag and drop or click to upload</span>
                                                        {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {attachment.length > 0 ? attachment.map((image, index) => (
                                        <div className="box-im d-flex align-items-center mt-4" key={index}>
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={image} alt="..." />
                                            </div>

                                            <div className="show-img-info">
                                                <h4 className="mb-0  f-size">{image}</h4>
                                            </div>

                                        </div>
                                    )) : ""}
                                    <hr className="my-5" />
                                </div>
                               
                            </div>:<hr className="my-5" />}
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.routeChange} disabled={imageUploading || issubmitting}>Cancel</button>
                                    <button className="btn btn-primary " onClick={this.handleClick} disabled={imageUploading || issubmitting} onClick={this.handleSave}>{issubmitting?'Saving...':'Save'}</button>
                                </div>
                                </div>
                    </div>
                    }
                </div>
            </div>
        )
    }
}