import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getAPI,deleteAPI} from '../utils/api'
import moment from 'moment';
import { storageUrl } from '../utils/BaseUrl';


export default class Brands extends Component {
    constructor(){
        super();
        this.state={brands:[],brandObj:{},isLoading:false}
    }
    componentDidMount(){
        this.getBrands();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.setState({categ:[]});
            this.getBrands();
        }
      }
    getBrands(){
        const url='brand/brand/'+this.props.langId;
        this.setState({isLoading:true});
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({brands:data,isLoading:false})
            }else{
                this.setState({brands:[],isLoading:false})
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    handleSelect(brand){
        this.setState({brandObj:brand});
    }
    handleCancel=()=>{
        this.setState({brandObj:{}})
    }
    handleDelete=()=>{
        const url='brand/brand/'+this.state.brandObj.uid;
        const data={};
        deleteAPI(url,data).then(res=>{
            const {status}=res.data;
            if(status===1){
                this.setState({brandObj:{}});
                this.getBrands();
            }
        }).catch(err=>{
            console.log(err)
        })

    }
    render() {
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Brands</h1>
                                    </div>
                                     <div className="col-auto">
                                        <Link to={{pathname:'/createbrand',state:{status:0}}}  className="btn btn-primary lift">Create Brand</Link>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Brands </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th className="w-10">Order</th>
                                                    <th>Brands NAME</th>
                                                    <th>Tagline</th>
                                                    <th colSpan="2">Created On</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                this.state.isLoading
                                                ?<tr>
                                                <td colspan="4"> 
                                                    <div className="text-center">
                                                        <div class="spinner-border text-danger" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                                :this.state.brands.map((brand,idx)=>(

                                                    <tr key={idx}>
                                                        <td>{brand.order}</td>
                                                    <td>

                                                        <a href={brand.icon} target="_blank"  className="avatar avatar-xs d-inline-block mr-2">
                                                        <img src={`${storageUrl+ brand.icon}`} alt="..." className="avatar-img rounded-circle" />
                                                        </a>

                                                    <span>{brand.name}</span>

                                                    </td>
                                                    <td>{brand.tagline}</td>
                                                <td>{moment(brand.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <div className="dropdown">
                                                            <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                    
                                                    <Link to={{pathname:'/createbrand',state:{status:1,data:brand}}}className="dropdown-item">
                                                    Edit
                                                    </Link>
                                                    <a href="#!" className="dropdown-item" data-target="#delete_brand" data-toggle="modal" onClick={e=>{this.handleSelect(brand)}}>
                                                    Delete
                                                    </a>
                                                    
                                                    </div>
                                                        </div>
                                                    </td>
                                                    </tr>


                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <nav aria-label="Page navigation example">
                                    <ul class="pagination pagination-lg justify-content-center">
                                    <li class="page-item"><a class="page-link" href="#!">Previous</a></li>
                                    <li class="page-item active"><a class="page-link" href="#!">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#!">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#!">Next</a></li>
                                    </ul>
                                </nav> */}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="delete_brand" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Brand <span className="text-color">{this.state.brandObj.name } ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-primary btn-lg mr-3"   onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                            <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>                     
                </div>

            </>
        )
    }
}