import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAPI, deleteAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';


export default class Assets extends Component {
    state = {
        Assets: []
    }

    componentDidMount() {
        this.getAssets()
    }
    componentDidUpdate(prevProps){
        if(prevProps.language!=this.props.language){
            this.getAssets();
        }

    }
    getAssets = () => {
        getAPI(`assets/asset/${this.props.langId}`)
            .then(res => {
                let { status, data } = res.data
                if (status === 1) {
                    this.setState({ Assets: data })
                }
            })
            .catch(error => console.log(error))
    }

    deleteAssets = (uid) => {
        deleteAPI(`assets/asset/${uid}`)
        .then(res => {
            if(res.data.status === 1){
                this.getAssets()
            }
        })
        .catch(error => console.log(error))
    }

    render() {
        const { Assets } = this.state
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> Assets Management</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/CreateAssets" className="btn btn-primary lift">
                                            Create Assets
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All assets </h4>

                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th>Brand</th>
                                                    <th>category</th>
                                                    <th>Materials</th>
                                                    <th colSpan="2">images</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Assets.length > 0
                                                        ?
                                                        Assets.map((ele, index) => (
                                                            <tr key={index}>
                                                                <td>{ele?.brand?.name}</td>
                                                                <td>{ele?.category?.name}</td>
                                                                <td>{ele?.material?.name} </td>
                                                                <td>
                                                                    <div className="avatar-group">
                                                                        {
                                                                            ele?.image?.length > 0 && ele.image.map((item, index) => (
                                                                                <a className="avatar  avatar-sm" key={index}>
                                                                                    <img src={`${storageUrl+item}`} className="avatar-img rounded-circle" alt="..." />
                                                                                </a>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td className="text-right">
                                                                    <div className="dropdown">
                                                                        <a className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <i className="fe fe-more-vertical" />
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-right">
                                                                            <Link to={{
                                                                                pathname: "/CreateAssets", 
                                                                                state: {
                                                                                    assets: ele
                                                                                }
                                                                                }} className="dropdown-item" style={{"cursor": "pointer"}}>
                                                                                Edit
                                                                            </Link>
                                                                            <a className="dropdown-item" onClick={() => this.deleteAssets(ele.uid)} style={{"cursor": "pointer"}}>
                                                                                Delete
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                        :
                                                        <tr>
                                                    <td colspan="4"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="col-12 ">
                                    {/* <nav aria-label="Page navigation example ">
                                        <ul className="pagination pagination-lg justify-content-center">
                                            <li className="page-item "><a className="page-link" href="#!">Previous</a></li>
                                            <li className="page-item active"><a className="page-link" href="#!">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#!">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#!">Next</a></li>
                                        </ul>
                                    </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
