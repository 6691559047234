import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getAPI,deleteAPI} from '../utils/api'; 
import ReactHtmlParser from 'react-html-parser';
import { storageUrl } from '../utils/BaseUrl';

export default class AddgroupList extends Component {

    constructor(){
        super();
        this.state={groups:[],isLoading:true,productUid:'',groupObj:{},brand:'',type:''}
    }
    componentDidMount(){
        const productId=this.props.location.state.productUid;
        const {data}=this.props.location.state;
        this.setState({productUid:productId,brand:data.brand?.name,type:data.productType?.name});
        this.getGroups();
    }
    getGroups(){
        const url='productGroup/productGroup/'+this.props.location.state.productUid;
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({groups:data,isLoading:false})
            }else{
                this.setState({groups:[],isLoading:false})
            }
        }
        ).catch(err=>{
            console.log(err);
        })
    }
    handleSelect=(obj)=>{
        this.setState({groupObj:obj});
    }
    handleDelete=()=>{
        const data={};
        const url='productGroup/productGroup/'+this.state.groupObj.uid;
        deleteAPI(url,data).then(res=>{
            const {status}=res.data;
            if(status===1){
              this.setState({groupObj:{}});
              this.getGroups();  
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
        const {groups, isLoading,productUid,brand,type} = this.state;
        const {data}=this.props.location.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Size Group List</h1>
                                        <h4 className="header-title mt-2">Brand : <span className="header-subtitle">{brand}</span></h4>
                                        <h4 className="header-title mt-2">Type : <span className="header-subtitle">{type}</span></h4>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={{pathname:"/creategroup",state:{status:0,productUid:productUid,data:data}}} className="btn btn-primary lift">Create Group</Link>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Group </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th className="w-10">Order</th>
                                                    <th > NAME</th>
                                                    <th>description</th>
                                                    <th colSpan="2"> Images</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading
                                                    ? <tr>
                                                    <td colspan="4"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    :
                                                    groups.map((item,idx)=>(
                                                        <tr key={idx}>
                                                    <td>{item.order}</td>
                                                  <td>
                                                        {item.sizeName}
                                                  </td>
                                                  <td>{ReactHtmlParser(item.desc)}</td>
                                                  <td>
                                                  <div className="avatar-group">
                                                      {item.images.map((image,indx)=>(
                                                           <Link to="/#" className="avatar avatar-sm" key={indx}>
                                                               {image?.type===0?<img src={`${storageUrl+ image.value}`} className="avatar-img rounded-circle" alt="..."/>:<img  src={`https://img.youtube.com/vi/${image.video_id}/hqdefault.jpg`} className="avatar-img rounded-circle" alt="..."/>}
                                                        {/* <img src={image} className="avatar-img rounded-circle" alt="..."/> */}
                                                        </Link>
                                                      ))}
                                                        
                                                    </div>
                                                  </td>
                                                  <td className="text-right">
                                                      <div className="dropdown">
                                                        <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <Link to={{pathname:"/creategroup",state:{status:1,data:item,data1:data}}} className="dropdown-item">
                                                            Edit
                                                        </Link >
                                                        <Link to={{pathname:"/sizeList",state:{data:item,data1:data}}} className="dropdown-item">
                                                        Add Sizes (SKU's)
                                                        </Link>
                                                        <a href="/#" className="dropdown-item" data-target="#delete_group" data-toggle="modal" onClick={e=>{this.handleSelect(item)}}>
                                                            Delete
                                                        </a>
                                                        </div>
                                                    </div>
                                                  </td>
                                                  
                                               </tr>
                                               
                                                    ))
                                                }
                                             
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="delete_group" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Group <span className="text-color">{this.state.groupObj.sizeName } ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-primary btn-lg mr-3"   onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                            <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>      
                </div>

            </>
        )
    }
}