import React, {Component} from 'react'

export default class HomeFeatureBannerTwoModal extends Component {
  render(){
    return(
			          <React.Fragment>
                        <div
                          className="modal fade fixed-right"
                          id="cr_feature2"
                          tabIndex={-1}
                          role="dialog"
                          aria-modal="true"
                        >
                          <div className="modal-dialog modal-dialog-vertical" role="document">
                            <form className="modal-content" id="demoForm">
                              <div className="modal-body">
                                <a className="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">×</span>
                                </a>
                                <h2 className="text-left mb-2">Create New Feature Banner 2</h2>
                                <small className="form-text text-muted">Sub Title Banner</small>
                                {/* Divider */}
                                <hr className="mb-4" />
                                <div className="form-box">
                                  <div className="form-group">
                                    <label htmlFor="Title">Title</label>
                                    <input
                                      type="text"
                                      className="form-control focus-out"
                                      id="Title"
                                      placeholder="Enter Title"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="SubTitle">Sub Title</label>
                                    <input
                                      type="text"
                                      className="form-control focus-out"
                                      id="SubTitle"
                                      placeholder="Enter SubTitle"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="ButtonText">Button Text</label>
                                    <input
                                      type="text"
                                      className="form-control focus-out"
                                      id="ButtonText"
                                      placeholder="Enter Button Text"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label htmlFor="ButtonLink">Button Link</label>
                                    <input
                                      type="text"
                                      className="form-control focus-out"
                                      id="ButtonLink"
                                      placeholder="Enter Button Link"
                                    />
                                  </div>
                                </div>
                                <div className="upload-img-box border w-100 position-relative">
                                  <div className="dz-default dz-message">
                                    <button className="dz-button position-relative" type="button">
                                      <input type="file" className="back-inp c-pointer" />
                                      Drop files here to upload
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer border-0 text-center">
                                <button type="submit" className="btn btn-white ml-auto">
                                  Cancel
                                </button>
                                <button type="submit" className="btn btn-primary mr-auto">
                                  Save
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                </React.Fragment>
			)

	}
}