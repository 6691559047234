import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import Dropzone from 'react-dropzone';
import { postFile, postAPI, getAPI, putAPI, deleteAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';
import moment from 'moment';

export default class Newsletter extends Component {
    constructor() {
        super();

        this.state = {
            date: new Date(),
            content: {
                "link": "",
                "title": "",
                "image": ""
            },
            publishedOn: "",
            languageUid: "",
            allNewsletter: [],
            newsUid: ''
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.getNewsLetter();
        }
    }

    componentDidMount() {
        this.getNewsLetter()
    }

    handleChange = (e) => {
        let { name, value } = e.target
        this.setState({
            content: {
                ...this.state.content, [name]: value
            }
        })
    }

    onDropBody = (acceptedFiles) => {
        if (acceptedFiles.length > 1) {
            alert('Only One Image allowed');
        } else {
            if (acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png') {
                this.setState({ imageUploading: true });
                postFile('upload/upload/promoImages', acceptedFiles[0])
                    .then(res => {
                        if (res.data.status === 1) {
                            this.setState({
                                imageUploading: false,
                                content: {
                                    ...this.state.content, image: storageUrl + res.data.data[0].s3key
                                }
                            })
                        }
                        else {
                            alert('Image not Uploaded')
                        }
                    }).catch(err => console.log(err))
            } else {
                alert('Only JPEG and PNG file accepted.')
            }

        }
    }

    getNewsLetter = () => {
        getAPI(`newsletters/newsletter/${this.props.langId}`)
            .then(res => {
                this.setState({ allNewsletter: res.data.data })
            })
            .catch(err => console.log('Product list error: ', err))
    }

    editNewsletter = (ele) => {
        if (ele) {
            this.setState({
                content: ele.content,
                publishedOn: ele.publishedOn,
                newsUid: ele.uid
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        if (this.state.newsUid.length > 0) {
            let data = {
                content: this.state.content,
                publishedOn: this.state.publishedOn
            }
            putAPI('newsletters/newsletter/' + this.state.newsUid, data).then(res => {
                const { status} = res.data;
                if (status === 1) {
                    this.getNewsLetter()
                    document.getElementById('closeModal').click()
                    this.setState({
                        content: {
                            ...this.state.content,
                            "link": "",
                            "title": "",
                            "image": ""
                        },
                        publishedOn: "",
                        languageUid: "",
                    })
                } else {
                    alert('newsletter not updated')
                }
            }).catch(err => {
                console.log(err);
            })
        } else {
            let data = {
                content: this.state.content,
                publishedOn: this.state.publishedOn,
                languageUid: this.props.langId
            }
            postAPI('newsletters/newsletter', data).then(res => {
                const { status } = res.data;
                if (status === 1) {
                    this.getNewsLetter()
                    document.getElementById('closeModal').click()
                    this.setState({
                        content: {
                            ...this.state.content,
                            "link": "",
                            "title": "",
                            "image": ""
                        },
                        publishedOn: "",
                        languageUid: "",
                    })
                } else {
                    alert('newsletter not created')
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }

    deleteNews = (uid) => {
        deleteAPI(`newsletters/newsletter/${uid}`).then(res => {
            let { status } = res.data
            if(status === 1){
                this.getNewsLetter();
            }
            alert("Error in deleting...")
        }).catch(err => console.log('Delete newsletter error: ', err))
    }

    render() {
        const { content, allNewsletter } = this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Newsletter</h1>
                                </div>
                                <div className="col-auto">
                                    <Link to="#!" className="btn btn-primary lift" data-toggle="modal" data-target="#newsletter" >Add Newsletter</Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-header-title">All Newsletter </h4>
                                </div>
                                <div className="table-responsive mb-0">
                                    <table className="table table-sm table-wrap card-table">
                                        <thead>
                                            <tr>
                                                <th>title</th>
                                                <th>Link</th>
                                                <th>Date</th>
                                                <th colSpan="2">img</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                allNewsletter.length > 0 && allNewsletter.map((ele, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{ele.content.title}</td>
                                                            <td>{ele.content.link}</td>
                                                            <td>{moment(ele.publishedOn).format('DD MMM YYYY')}</td>
                                                            <td>
                                                                <div className="avatar avatar-4by3">
                                                                    <img src={ele.content.image} alt="..." className="avatar-img rounded" />
                                                                </div>
                                                            </td>
                                                            <td className="text-right">
                                                                <div className="dropdown">
                                                                    <a href="#!" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fe fe-more-vertical" />
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a href="#!" className="dropdown-item" onClick={() => this.deleteNews(ele.uid)}>  Delete </a>
                                                                        <a href="#!" className="dropdown-item" data-toggle="modal" data-target="#newsletter" onClick={() => this.editNewsletter(ele)}>  Edit </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {/* <div className="col-12">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination pagination-lg justify-content-center">
                                        <li class="page-item "><a class="page-link" href="#!">Next</a></li>
                                        <li class="page-item active"><a class="page-link" href="#!">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#!">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#!">Previous</a></li>
                                    </ul>
                                </nav>

                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>

                {/* ==================modal============ */}

                <div className="modal fade" id="newsletter" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-card card">
                                <div className="card-body">
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <input type="text" className="form-control" name="title" value={content.title} onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Link</label>
                                                <input type="text" className="form-control" name="link" value={content.link} onChange={this.handleChange} />
                                            </div>
                                            <div className="form-group">
                                                <label>Date</label>
                                                <Flatpickr
                                                    className="form-control"
                                                    onChange={(e) => {
                                                        this.setState({
                                                            publishedOn: moment(e[0]).format('YYYY-MM-DD')
                                                        });
                                                    }}
                                                    value={this.state.publishedOn}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Image</label>
                                                <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                                    <div className="dz-default dz-message">
                                                        <Dropzone onDrop={e => this.onDropBody(e)}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <section>
                                                                    <div {...getRootProps()}>
                                                                        <input {...getInputProps()} />
                                                                        <p className="mb-0">Drop file here to upload</p>
                                                                    </div>
                                                                </section>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                                {content.image ?
                                                    <div className="box-im d-flex align-items-center mt-4">
                                                        <div className="img-box-s mr-2">
                                                            <img className="drop-image" src={content.image} alt="..." />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className="form-group text-center">
                                                <button type="button" className="btn btn-primary mr-2" onClick={this.handleSubmit}>Create</button>
                                                <button type="button" className="btn btn-outline-primary" data-dismiss="modal" >Cancel</button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ===================modal close============= */}
            </div>
        )
    }
}