import React from 'react';
import UploadComponent from '../Common/UploadComponent';
import {postFile} from '../utils/api';
import {storageUrl} from '../utils/BaseUrl';

export default class StyleAndScript extends React.Component {
    state ={
        cssUploading: false,
        jsUploading: false
    }

    onDrop = (acceptedFiles, fileExt) => {   
    //  console.log(acceptedFiles[0].type, acceptedFiles[0].type === 'application/x-javascript', fileExt) 
     //   console.log("check",fileExt)
        if(acceptedFiles.length > 1){
          alert(`Only one file is allowed. You tried ${acceptedFiles.length} files.`)
        } else if((fileExt === 'css' && acceptedFiles[0].type === 'text/css') || (fileExt === 'js' && acceptedFiles[0].type === 'text/javascript')) {
          this.setState({[`${fileExt}Uploading`]: true})

          postFile('upload/upload/testImage', acceptedFiles[0])
            .then(res => {
                // console.log(res.data.data[0].s3key, res.data);
                alert(`${fileExt} file uploaded`);
                this.setState({cssUploading: false, jsUploading: false})
                
            }).catch(err => console.log(err)) 
        } else {
            fileExt === 'css' 
                ? alert('Only CSS file accepted.')
                : alert('Only JS file accepted.')
        }
    }

    render(){
        const {cssUploading, jsUploading} = this.state;
        return(
            <React.Fragment>
                <div className="main-section mt-5">
                    <div className="container-fluid">
                        <div className="col-sm-12">
                            <div className="d-flex">
                                <h2 className="header-title">Add Stylesheet file</h2>
                            </div>
                            <div className="spac w-100" />
                            <UploadComponent imageUploading={cssUploading} onDrop={files => this.onDrop(files, 'css')} accept={'css'}/>
                        </div>
                        <div className="spac w-100" />
                        <div className="col-sm-12">
                            <div className="d-flex">
                                <h2 className="header-title">Add JS file</h2>
                            </div>
                            <div className="spac w-100" />
                            <UploadComponent imageUploading={jsUploading} onDrop={files => this.onDrop(files, 'js')} accept={'js'}/>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3 mt-4">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>            
        )
    }
}