import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getAPI,deleteAPI} from '../utils/api'; 
import { storageUrl } from '../utils/BaseUrl';

export default class SkuList extends Component {

    constructor(){
        super();
        this.state={skus:[],isLoading:true,groupUid:'',skuObj:{}}
    }
    componentDidMount(){
      const {data}=this.props.location.state;
      //console.log(data);
      this.setState({groupUid:data.uid});
      this.getSkus();
    }
    getSkus(){
        const url='productSku/productSku/'+this.props.location.state.data.uid;
        // console.log('Url',url);
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            // console.log(data,status);
            if(status===1){
                this.setState({skus:data,isLoading:false})
            }else{
                this.setState({skus:[],isLoading:false})
            }
        }
        ).catch(err=>{
            console.log(err);
        })
    }
    handleSelect=(obj)=>{
        this.setState({skuObj:obj});
    }
    handleDelete=()=>{
        const data={};
        const url='productSku/productSku/'+this.state.skuObj.uid;
        deleteAPI(url,data).then(res=>{
            const {status}=res.data;
            if(status===1){
              this.setState({skuObj:{}});
              this.getSkus();  
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
       const {isLoading,skus,groupUid}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Size List</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={{pathname:"/addsize",state:{status:0,uid:groupUid}}} className="btn btn-primary lift">Add Size</Link>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Sizes </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th className="w-10">Order</th>
                                                    <th > NAME</th>
                                                    <th>Product Code</th>
                                                    <th>Color</th>
                                                    <th colSpan="2"> Images</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading
                                                    ?<tr>
                                                    <td colspan="4"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    :
                                                    skus.map((item,idx)=>(
                                                        <tr key={idx}>
                                                    <td>{item.order}</td>
                                                  <td>
                                                        {item.size}
                                                  </td>
                                                  <td>{item.code}</td>
                                                  <td>{item.color}</td>
                                                  <td>
                                                  <div className="avatar-group">
                                                      {item.images.map((image,indx)=>(
                                                           <Link to="/#" className="avatar avatar-sm" key={indx}>
                                                        {image?.type===0?<img src={`${storageUrl+image.value}`} className="avatar-img rounded-circle" alt="..."/>:<img src={`https://img.youtube.com/vi/${image.video_id}/hqdefault.jpg`}  className="avatar-img rounded-circle" alt="..."/>}
                                                        </Link>
                                                      ))}
                                                        
                                                    </div>
                                                  </td>
                                                  <td className="text-right">
                                                      <div className="dropdown">
                                                        <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <Link to={{pathname:"/addsize",state:{status:1,data:item}}} className="dropdown-item">
                                                            Edit
                                                        </Link >
                                                        <a href="/#" className="dropdown-item" data-target="#delete_sku" data-toggle="modal" onClick={e=>{this.handleSelect(item)}}>
                                                            Delete
                                                        </a>
                                                        </div>
                                                    </div>
                                                  </td>
                                                  
                                               </tr>
                                               
                                                    ))
                                                }
                                             
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="delete_sku" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Size <span className="text-color">{this.state.skuObj.size} ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-primary btn-lg mr-3"   onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                            <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>      
                </div>

            </>
        )
    }
}