import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { getAPI, postAPI, putAPI ,postFile} from '../../utils/api';


export default class CatalogueDownload extends Component {

    constructor(props) {
        super(props);
        this.state = {
          header:"",pdf:"",topBanner:{title:{}},isSubmitting:false,isLoading:false,imageUploading:false
          };
        this.modules = {
            toolbar: [
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['link','image','video'],
              ['clean']
            ]
        };
    
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background',
            'link','image','video'
          ];
    }
    componentDidMount(){
        this.getCatalogue();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
        //   this.setState({isLoading: true});
          this.getCatalogue();
        }
      }

    getCatalogue=()=>{
        getAPI(`template/getTemplate?menuId=17&type=topBanner&lang=${this.props.language}`)
        .then(res=>{
            const {data,status}=res.data;
            console.log(data,status);
            this.setState({topBanner:data[0],header:data[0]?.header,pdf:data[0]?.pdf})
        }).catch(err=>{
            console.log(err);
        })

    }
    handleChangeEditor=(e,string)=>{
        if(string==='header'){
            this.setState({header:e})
        }else{
            this.setState({
                footer:e
            })
        }
    }
    handleSave=(e)=>{
        console.log(this.state.topBanner)
        const {language} = this.props;
        const topBanner=this.state.topBanner;
        this.setState({isSubmitting:true});
        let newObj={ 
            id:topBanner?.id,
            header:{[language]:this.state.header},
            pdf:{[language]:this.state.pdf}
        }
        const data = {
            "type": 'topBanner',
            "menuId": 17,
            "templateData": newObj
          }
          putAPI('template/editTemplate', data).then(res => {
            console.log(res);
            // this.componentDidMount();
            this.setState({isSubmitting: false});
          }).catch(error => {
            console.log(error)
          })
        
    }
    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    onDropBody=(acceptedFiles)=>{
        if(acceptedFiles.length>1){
            alert("Only Single file allowed")
        }
        else{
            if (acceptedFiles[0].type == 'image/jpeg' || acceptedFiles[0].type == 'image/png') {
                alert('Only PDF file accepted.')
            }else{
                this.setState({imageUploading:true})
        postFile('upload/upload/catalogue', acceptedFiles[0])
        .then(res => {
            if (res.data.status === 1) {
                this.setState({
                    imageUploading: false,
                    pdf: res.data.data[0].s3key
                })
            }
            else {
                alert('File not Uploaded')
            }
        }).catch(err => console.log(err))
    }
    }
    }
    render(){
        const {header,isSubmitting,isLoading,imageUploading,pdf}=this.state;
        return(
            <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        {/* <h6 className="header-pretitle"> </h6> */}
                                        <h1 className="header-title">Product Replacement</h1>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {isLoading?'Loading...'
                        :
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label>Content</label>
                               <ReactQuill theme="snow" modules={this.modules}
                                formats={this.formats}  onChange={(e)=>{this.handleChangeEditor(e,'header')}}
                                value={header}/>
                               
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Catalogue Link</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="url..."
                                 
                                 name="pdf"
                                 value={this.state.pdf}
                                 onChange={e => this.handleChange(e)}
                                 />
                                
                                </div>
                                <hr className="my-5"/>
                            </div>
                           
                            
                            <div className="col-12">
                            <div className='form-group'>
                          <button type="button" className="btn btn-primary mr-auto" onClick={e=>this.handleSave()}>
                           {isSubmitting?'Saving..':'Save'}
                          </button>
                        </div>
                            </div>
                        </div>}
                    </div>
                    </div>
        )
    }
}