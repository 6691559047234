import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import UploadComponent from '../Common/UploadComponent';
import { postAPI, postMultipleFile, putAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';
import ReactQuill from 'react-quill';

export default class AddgroupList extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            desc: '',
            images: [],
            order: '',
            link:"",
            islink:false,
            imagesUploading: false,
            files_Body: null,
            files_body_content: [],
            files_Movement_content: [],
            files_Performace_content: [],
            files_Movement: null,
            files_Performace: null,
            err_image_msg: '',
            err_image_msgg: '',
            err_image_msggg: '',
            errors: {
                name: '',
                order: ''
            },
            isRequired: false
        }
    }
    componentDidMount() {
        const { status, data } = this.props.location.state;
        if (status === 1) {
            this.setState({
                name: data.sizeName,
                desc: data.desc,
                images: data.images,
                order: data.order
            })
        }
    }
    handleChange(e) {
        if (typeof (e) === 'string') {
            this.setState({ desc: e })
        } else {
            const { errors } = this.state;
            const { name, value } = e.target;
            switch (name) {
                case 'name':
                    errors.name =
                        value.length <= 0
                            ? 'Field is Required!'
                            : '';
                    break;
                case 'order':
                    errors.order =
                        value.length <= 0
                            ? 'Field is Required!'
                            : '';
                    break;
                default:
                    break;
            }
            this.setState({ errors, [e.target.name]: e.target.value });
        }
    }
    handleLinkInput = () => {
        this.setState({ islink: !this.state.islink,link:''});
    }
    removeImage=(e,value)=>{
        let imagesArray=this.state.images;
        let newArray=[];
        for(let i=0;i<imagesArray.length;i++){
            if(imagesArray[i].value!==value){
                imagesArray[i].order=newArray.length;
                newArray.push(imagesArray[i]);
            }
        }
        console.log(newArray);
        this.setState({images:newArray});
    }
    handleUploadImageAndLink=(acceptedFiles,state)=>{
        const imagesArray=this.state.images;
        if(state==='image'){
            postMultipleFile('upload/upload/productImages',acceptedFiles).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    for (let i = 0; i < data.length; i++) {
                        let obj={
                            type:0,
                            value:data[i].s3key,
                            order:imagesArray.length
                        };
                        imagesArray.push(obj);
                    }
                    // console.log(imagesArray);
                    this.setState({images:imagesArray})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
        else{
            let link = this.state.link;
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = link.match(regExp);
            let video_id='';
            if(match&&match[7].length==11){
                video_id=match[7];
            }
            let obj={
                type:1,
                order:imagesArray.length,
                value:link,
                video_id:video_id
            }
            //console.log(video_id);
            imagesArray.push(obj);
            // console.log(imagesArray);
            this.setState({link:'',images:imagesArray,islink:false});
            
        }
    }
    handleCancel = () => {
        const { status, data, data1 } = this.props.location.state;
        if (status === 0) {
            this.props.history.push({
                pathname: '/groupList',
                state: { status: 0, productUid: this.props.location.state.productUid, data: data }
            })
        }
        else {
            this.props.history.push({
                pathname: '/groupList',
                state: { status: 0, productUid: this.props.location.state.data.productUid, data: data1 }
            })
        }
    }
    handleSubmit = () => {
        const { status, productUid } = this.props.location.state;
        const data = {
            sizeName: this.state.name,
            desc: this.state.desc,
            images: this.state.images,
            productUid: productUid,
            order: this.state.order
        }
        const { errors } = this.state;
        if (data.sizeName.length === 0 || data.order.length === 0) {
            if (data.sizeName.length === 0 && data.order.length === 0) {
                errors.name = 'Field is Required!';
                errors.order = 'Field is Required!';
                this.setState({ errors, isRequired: true })
            }
            else {
                if (data.sizeName.length === 0) {
                    errors.name = 'Field is Required!';
                    this.setState({ isRequired: true, errors });
                }
                if (data.order.length === 0) {
                    errors.order = 'Field is Required!';
                    this.setState({ isRequired: true, errors });
                }
            }
        }
        else if (status === 0) {
            postAPI('productGroup/productGroup', data).then(res => {
                const { status } = res.data;
                if (status === 1) {
                    this.props.history.push({
                        pathname: '/groupList',
                        state: { status: 0, productUid: productUid, data: this.props.location.state.data }
                    })
                }
            }).catch(err => {
                console.log(err);
            })
        }
        else {
            const url = 'productGroup/productGroup/' + this.props.location.state.data.uid;
            putAPI(url, data).then(res => {
                const { status } = res.data;
                if (status === 1) {
                    this.props.history.push({
                        pathname: '/groupList',
                        state: { status: 1, productUid: this.props.location.state.data.productUid, data: this.props.location.state.data1 }
                    })
                } else {
                    alert('group not edited')
                }
            })
        }
    }
    render() {

        const { status } = this.props.location.state;
        const { errors, isRequired,islink,link,imagesUploading,images } = this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">{status === 1 ? 'Edit Group' : 'Create Group'} </h1>
                                    </div>
                                    <div className="col-auto">
                                        {/* <a href="#!" className="btn btn-primary lift">Create Type </a> */}
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">Sort Order:</span>
                                            <input type="text" className="form-control width-auto" name="order" value={this.state.order} onChange={e => { this.handleChange(e) }} />
                                        </div>
                                        {isRequired && errors.order.length > 0 ?
                                            <span className="error-field-error">{errors.order}</span> : ''}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" value={this.state.name} className="form-control" onChange={(e) => this.handleChange(e)} />
                                    {isRequired && errors.name.length > 0 ?
                                        <span className="error-field-error">{errors.name}</span> : ''}
                                </div>

                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Description</label>
                                    {/* <textarea type="text" className="form-control" name="desc" value={this.state.desc} onChange={(e)=>this.handleChange(e)} /> */}
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={(e) => this.handleChange(e)}
                                        value={this.state.desc}
                                    />
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <label>Images</label>

                                <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple rem_border">
                                    <UploadComponent imageUploading={imagesUploading} onDrop={files => this.handleUploadImageAndLink(files,'image')}/>
                                    <div className="upload_link section text-center">
                                        <h2 className="color_text">or</h2>
                                        <button className="btn_cs" onClick={this.handleLinkInput}>upload link</button>
                                        {islink?<div className="link_box d-flex justify-content-center mb-4">
                                            <input type="text" className="link_text d-block" name="link" value={this.state.link} onChange={(e)=>this.handleChange(e)} placeholder="url.."/>
                                            <button type="button" className="btn_submit" onClick={e=>this.handleUploadImageAndLink(e,'link')}>add</button>
                                        </div>:''}
                                    </div>
                                </div>
                                {images.length ?
                                    <div className="box-im d-flex align-items-center mt-4">
                                        {
                                            images.map((item, idx) => (
                                                <div className="img-box-s mr-2 position-relative in_box_size" key={idx}>
                                                    {item.type===0?<img className="drop-image" src={`${storageUrl+item.value}`} />:<img className="drop-image" src={`https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`} />}
                                                    <button type="button" className="btn_cr" onClick={e=>this.removeImage(e,item.value)}><span className="d-block">X</span></button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                                }


                                <hr className="my-5" />
                            </div>
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                    <button className="btn btn-primary" disabled={this.state.imageUploading} onClick={this.handleSubmit}>{status === 1 ? 'Save' : 'Create Group'}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}