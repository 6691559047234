import React, { Component } from 'react';
import Select from 'react-select';
import Dropzone from 'react-dropzone'
import { getAPI, postAPI, postFile, putAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';

export default class Createresources extends Component {
    state = {
        selectedResourceType: null,
        selectedBrand:null,
        showBrands:false,
        title:'',
        logo:'',
        images:[],
        resourceOptions:[],
        brandOptions:[],
        selectedType:null,
        imageUploading:false
    };
    componentDidMount(){
        this.getResourceTypes();
        this.getBrands();
    }
    getResourceTypes(){
        const lang=this.props.language;
        getAPI('resource/resourceTypes?parent=0').then(res=>{
            const {status,data}=res.data;
            if(status===1){
                const optionArray = data.map(d => {
                    let a = {};
                    a['label'] = d.name[lang];
                    a['value'] = d.uid;
                    a['hasBrand']=d.hasBrand;
                    return a;
                })
                this.setState({ resourceOptions: optionArray });
            }else{
                this.setState({ resourceOptions: [] });
            }
        }).catch(err=>{console.log(err)})
    }
    getBrands(){
        const lang=this.props.langId;
        getAPI(`brand/brand/${this.props.langId}`).then(res=>{
            const {status,data}=res.data;
            // console.log(status,data);
            if(status===1){
                const optionArray = data.map(d => {
                    let a = {};
                    a['label'] = d.name;
                    a['value'] = d.uid;
                    return a;
                })
                this.setState({ brandOptions: optionArray });
            }else{
                this.setState({ brandOptions: [] });
            }
        }).catch(err=>{console.log(err)})
    }

    handleSelectChange = (selectedOption,type) => {
        if(type==='a'){
        this.setState({ selectedResourceType:selectedOption,selectedBrand:null, showBrands:selectedOption.hasBrand });
        console.log(`Option selected:`, selectedOption);
        }else{
            this.setState({selectedBrand:selectedOption});
            console.log(`Option selected:`, selectedOption);
        }
    };
    handleChange=(e)=>{
        const {name,value}=e.target;
        this.setState({[name]:value});
    }
    onDropBody=(acceptedFiles)=>{
        if(acceptedFiles.length>1){
            alert('Only One Image allowed')
        }else{
            this.setState({imageUploading:true})
            postFile('upload/upload/resourceLogo', acceptedFiles[0])
                .then(res => {
                    if (res.data.status === 1) {
                        this.setState({
                            imageUploading: false,
                            logo:res.data.data[0].s3key
                        })
                    }
                    else {
                        alert('Image not Uploaded')
                    }
                }).catch(err => console.log(err))
        }
    }
    handleCancel=()=>{
        this.props.history.push('/resources');
    }
    handleSubmit=()=>{
        const d={
            "title":this.state.title,
            "brandUid":this.state.selectedBrand?.value,
            "resourceTypeUid":this.state.selectedResourceType?.value,
            "logo":this.state.logo,
            "assets":[],
            "languageUid":this.props.langId
        }
        postAPI('resource/resource',d).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.props.history.push('/resources');
            }else{
                alert('not created')
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
        const { selectedResourceType,showBrands,selectedBrand,brandOptions,title,logo,resourceOptions,imageUploading} = this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Create Resources</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Resource type</label>
                                <Select
                                    value={selectedResourceType}
                                    onChange={e=>this.handleSelectChange(e,'a')}
                                    options={resourceOptions}
                                />
                            </div>
                            <hr className="my-5" />
                        </div>
                        {showBrands?
                        <div className="col-12">
                            <div className="form-group">
                                <label>Brand</label>
                                <Select
                                    value={selectedBrand}
                                    onChange={e=>this.handleSelectChange(e,'b')}
                                    options={brandOptions}
                                />
                            </div>
                            <hr className="my-5" />
                        </div>:''}
                        <div className="col-12">
                            <div className="form-group">
                                <label>Title</label>
                                <input type="text" name="title" value={title} className="form-control" onChange={e=>this.handleChange(e)} />
                            </div>
                            <hr className="my-5" />
                        </div>
                        <div className="col-12">

                            <div className="form-group">
                                <label>Logo</label>
                                <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                    <div className="dz-default dz-message">
                                        <Dropzone onDrop={e => this.onDropBody(e, 'single')} accept={'image/*'}>
                                            {({ getRootProps, getInputProps }) => (
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} className="back-inp c-pointer" />
                                                    <span className="dz-button center-box">{imageUploading?'uploading...':'Drop file here to upload'}</span>
                                                    {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                </div>
                                            )}
                                        </Dropzone>
                                        
                                    </div>
                                </div>
                                {logo?
                                    <div className="box-im d-flex align-items-center mt-4">
                                        <div className="img-box-s mr-2">
                                            <img className="drop-image" src={`${storageUrl+ logo}`} alt="..." />
                                        </div>

                                       
                                    </div>
                                    : null}
                            </div>
                            <hr className="my-5" />
                        </div>
                        
                        <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                    <button className="btn btn-primary " disabled={imageUploading} onClick={this.handleSubmit}>Create Resource</button>

                                </div>
                            </div>


                    </div>
                </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}