import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import {getAPI,deleteAPI,putAPI,postFile, postAPI} from '../utils/api'
import {storageUrl} from '../utils/BaseUrl';
import moment from 'moment';

export default class Promo extends Component {
    state = {
       name:'',
       image:'',
       link:'',
       content:{},
       type:0,
       title:'',
       desc:'',
       ispromoBanner:true,
       isform:false,
       promoImages:[],
       isLoading:false,
       imageUploading:false
      };

    componentDidMount(){
            this.setState({isLoading:true})
            this.getPromoBanners();
        }
    componentDidUpdate(prevProps){
        if (this.props.language !== prevProps.language) {
            this.setState({isLoading:true});
            this.getPromoBanners();       
        }
    }
    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value});
    }

    getPromoBanners=()=>{
        const url='promoImage/allpromoimages/'+this.props.langId;
        // this.setState({isLoading:true})
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({promoImages:data,isLoading:false})
            }else{
                this.setState({promoImages:[],isLoading:false})
            }
        }).catch(err=>{
            console.log(err);
        })
      }
      deletePromoBanner=(uid)=>{
        const url='promoImage/promoImage/'+uid;
        const data={};
        deleteAPI(url,data).then(res=>{
            const {status}=res.data;
            this.getPromoBanners();
        }).catch(err=>{
            console.log(err);
        })
      }

     updateStatus=(uid,status)=>{
         console.log(uid,status);
        if(status===true){
            let data={};
            putAPI(`promoImage/inactivate/${uid}`,data).then(res=>{
                this.getPromoBanners();
            }).catch(err=>{
                console.log(err);
            })
        }else{
            let data={};
            putAPI(`promoImage/updateStatus/${uid}`,data).then(res=>{
                this.getPromoBanners();
            }).catch(err=>{
                console.log(err);
            })
        }

     }
    handleClear=()=>{
        this.setState({name:'',image:'',content:{},link:'',type:this.state.isform?1:0,title:'',desc:''})
    } 
    onDropBody = (acceptedFiles) => {
      if (acceptedFiles.length>1) {
         alert('Only One Image allowed');
      }else{
         if(acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png'){
             this.setState({imageUploading:true});
            postFile('upload/upload/promoImages', acceptedFiles[0])
                .then(res => {
                    if(res.data.status===1){
                this.setState({
                    imageUploading: false,
                    image:storageUrl+res.data.data[0].s3key
                })}
                else{
                    alert('Image not Uploaded')
                }
                }).catch(err => console.log(err))
        }else{
            alert('Only JPEG and PNG file accepted.')
        }
    
    }}
    handleFormChange=(e,string)=>{
        if(string==='isform'){
            this.setState({isform:true,type:1,ispromoBanner:false,name:'',image:'',link:''})
        }else{
            this.setState({isform:false,type:0,ispromoBanner:true,title:'',desc:'',image:''})
        }
    }
    handleSubmit=()=>{
        const content={
            title:this.state.title,
            desc:this.state.desc
        }
        const data={
            name:this.state.name,
            link:this.state.link,
            image:this.state.image,
            languageUid:this.props.langId,
            type:this.state.type,
            content:content
        }
        console.log(data);
       
        postAPI('promoImage/promoImage',data).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                //console.log(data)
                this.getPromoBanners();
            }else{
                alert('promo not created')
            }
        }).catch(err=>{
            console.log(err);
        })
    }

    render() {
        const { promoImages,isLoading,isform,ispromoBanner } = this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Promo Images</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/mailer" className="btn btn-primary lift" >Mail Template</Link>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="#!" className="btn btn-primary lift" onClick={this.handleClear} data-toggle="modal" data-target="#promo" >Add Promo</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Promo </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th>NAME</th>
                                                    <th>Link</th>
                                                    <th>Type</th>
                                                    <th>CreatedOn</th>
                                                    <th colSpan="2">status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading?
                                                    <tr>
                                                    <td colspan="5"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>:
                                                    promoImages.map((item,idx)=>(
                                                       <tr key={idx}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="box_img">
                                                                <img className="avatar-img rounded mr-3" src={item.image} alt="..." />
                                                            </div>
                                                            {/* <div className="ml-3">
                                                                <h4 className="font-weight-normal mb-1">{item.name}</h4>
                                                            </div> */}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a href={item.link} target="_blank">{item.link}</a>
                                                    </td>
                                                    <td>{item.type==='0'?'PromoImage':'Form'}</td>
                                                    <td>{moment(item.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        {item.isActive?<span className="badge badge-soft-success">Active</span>:<span className="badge badge-soft-danger">Inactive</span>}
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="dropdown">
                                                            <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fe fe-more-vertical" />
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-right">
                                                                 <a href="#!" className="dropdown-item" onClick={e=>{this.updateStatus(item.uid,item.isActive)}}>
                                                                    {item.isActive?'Deactivate':'Activate' }
                                                                </a>
                                                                <a href="#!" className="dropdown-item" onClick={e=>{this.deletePromoBanner(item.uid)}}>
                                                                    Delete
                                                                </a>
                                                                 {/* <a href="#!" className="dropdown-item">
                                                                    Edit
                                                                </a> */}
                                                       
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr>  
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    {/* ============modal promo============ */}
                    <div className="modal fade" id="promo" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                           
                            
                            
                                <div className="modal-card card">
                                    <div className="card-body">
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                        <label htmlFor="male" className="mr-3">Promo Banner</label>
                                        <input type="radio" id="Promo" name="select" defaultValue="Promo" className="mr-3 top-3" defaultChecked={ispromoBanner} onClick={e=>this.handleFormChange(e,'ispromoBanner')}/>
                                        <label htmlFor="female" className="mr-3">NewsLetter</label>
                                        <input type="radio" id="Form" name="select" defaultValue="Form" className="top-3" defaultChecked={isform} onClick={e=>this.handleFormChange(e,'isform')}/>
                                        <form>
                                            {ispromoBanner
                                            ?<div><div className="form-group">
                                            <label>Name</label>
                                            <input type="text" value={this.state.name} className="form-control" name="name" onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Url</label>
                                            <input type="text" className="form-control" name="link" value={this.state.link} onChange={this.handleChange}/>
                                        </div>
                                        <div className="form-group">
                                            <label>Logo</label>
                                            <div className="upload-img-box border w-100 position-relative over-hidden f-out">
                                                <div className="dz-default dz-message py-5">
                                                    <Dropzone onDrop={e => this.onDropBody(e)}>
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} className="back-inp c-pointer" />
                                                                <span className="dz-button center-box">Drag and drop or click to upload</span>
                                                                {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </div>
                                               {this.state.image ?
                                                <div className="box-im d-flex align-items-center mt-4">
                                                    <div className="img-box-s mr-2">
                                                        <img className="drop-image" src={this.state.image} alt="..." />
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="text-center form-group">
                                        <button type="button" className="btn btn-primary mr-2" disabled={this.state.imageUploading} onClick={this.handleSubmit} data-dismiss="modal">Create</button>
                                        <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                                        </div></div>:<div><div className="form-group">
                                                <label>Title</label>
                                                <input type="text" value={this.state.title} className="form-control" name="title" onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Description</label>
                                                <input type="text" className="form-control" name="desc" value={this.state.desc} onChange={this.handleChange}/>
                                            </div>
                                            <div className="form-group">
                                                <label>Image</label>
                                                <div className="upload-img-box border w-100 position-relative over-hidden f-out">
                                                    <div className="dz-default dz-message py-5">
                                                        <Dropzone onDrop={e => this.onDropBody(e)} accept={'image/jpeg, image/png'}>
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div {...getRootProps()}>
                                                                    <input {...getInputProps()} className="back-inp c-pointer" />
                                                                    <span className="dz-button center-box">Drag and drop or click to upload</span>
                                                                    {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    </div>
                                                </div>
                                                   {this.state.image ?
                                                    <div className="box-im d-flex align-items-center mt-4">
                                                        <div className="img-box-s mr-2">
                                                            <img className="drop-image" src={this.state.image} alt="..." />
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className="text-center form-group">
                                            <button type="button" className="btn btn-primary mr-2" disabled={this.state.imageUploading} onClick={this.handleSubmit} data-dismiss="modal">Create</button>
                                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                                            </div></div>
                                            }
                                           
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* modal promo close */}
                </div>
            </>
        )
    }
}