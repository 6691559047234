import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {postAPI,putAPI} from '../../utils/api';



export default class CreateBlogCategories extends Component {

    state={
        name:'',
        errors:{
            name:''
        },
        isRequired:false
    }
    componentDidMount(){
        const {status,data}=this.props.location.state;
        if(status===1){
            this.setState({name:data.name})
        }
    }
    handleChange(e){
        let {errors}=this.state;
        let {name,value}=e.target;
        switch(name){
            case 'name':
                errors.name=
                 value.length<=0
                 ?'Field is Required!':'';
            break;
            default:
                break;     
        }
        this.setState({errors,[e.target.name]:e.target.value});
    } 
    handleCancel=()=> {
        let path = `/blogCategory`;
        this.props.history.push(path);
    }
    handleSubmit=()=>{
        const {status,data}=this.props.location.state;
        const {name,errors}=this.state
        const Categorydata={
            name:name,
            languageUid:this.props.langId
        }
        if(name.length===0){
            errors.name='Field is Required!';
            this.setState({
                isRequired:true,errors
            })
        }
        else
        {
        if(status===1){
          putAPI(`blogCategory/category/${data.uid}`,Categorydata).then(res=>{
            if(res.data.status===1){
                let path = `/blogCategory`;
                this.props.history.push(path);
            }
            else{
                alert('Something went wrong')
            }
          }).catch(err=>{
              console.log(err);
          })      

        }else{
        postAPI('blogCategory/category',Categorydata).then(res=>{
            if(res.data.status===1){
                let path = `/blogCategory`;
                this.props.history.push(path);
            }
            else{
                alert('Something went wrong')
            }
        }).catch(err=>{
            console.log(err);
        })
    }
}
    }



    render() {
        const {errors,isRequired}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> Blog Categories</h1>
                                    </div>
                                    <div className="col-auto">
                                       <Link to="/ CreateBlogCategories" className="btn btn-primary lift">
                                        Create Blog Categories
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                               <div className="form-group">
                                 <label>Blog Categories </label>
                                 <input type="text" name="name" value={this.state.name} className="form-control" onChange={(e)=>this.handleChange(e)}/>
                                 {isRequired && errors.name.length > 0?
                                <span  className="error-field-error">{errors.name}</span>:''}
                                </div>
                                <hr className="my-5"/>  
                            </div>
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                    <button className="btn btn-primary " onClick={this.handleSubmit}>Create</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
