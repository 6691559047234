import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { postAPI, postFile, putAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';


export default class AddgroupList extends Component {
   state={
       image:"",
       imageUploading:false,
       isCopied:false
   }
   onDropBody=(acceptedFile)=>{
    this.setState({imageUploading:true});
    postFile('upload/upload/static', acceptedFile[0])
    .then(res => {
        if (res.data.status === 1) {
            this.setState({
                imageUploading: false,
                image: storageUrl + res.data.data[0].s3key,
                isCopied:false
            })
        }
        else {
            alert('Image not Uploaded');
        }
    }).catch(err => console.log(err));
   } 

   handleChange=(e)=>{
       this.setState({[e.target.name]:e.target.value})
   }
   copyToClipboard=(e)=>{
    this.input.select();
    document.execCommand('copy');
    this.setState({ isCopied:true});
   }
    render() {
        const {image,imageUploading,isCopied}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Upload Files</h1>
                                    </div>
                                    <div className="col-auto">
                                        {/* <a href="#!" className="btn btn-primary lift">Create Type </a> */}
                
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                        <div className="col-12">
                                <div className="form-group">
                                    <label>  </label>
                                    <div className="upload-img-box border w-100 position-relative over-hidden f-out">

                                        <div className="dz-default dz-message py-5">
                                            <Dropzone onDrop={(e) => { this.onDropBody(e) }}  accept={'image/*, video/*,.pdf'}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} className="back-inp c-pointer" />
                                                        <span className="dz-button center-box">{imageUploading?'uploading...':'Drop file here to upload'}</span>
                                                        {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    {image? 
                                        <div className="box-im d-flex align-items-center mt-4">
                                            <div className="img-box-s mr-2 position-relative in_box_size" >
                                                <img className="drop-image" src={image} alt="..." />
                                            </div>

                                           

                                        </div>
                                     : null}
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12">
                                <div className="form-group d-flex input_copy w-100">
                                    <input type="text" 
                                    ref={(input) => this.input = input}
                                    name="image" 
                                    value={image} 
                                    placeholder="path..." 
                                    className="w-100 form-control border-0 border_right focus_out" 
                                    onChange={e=>this.handleChange(e)}
                                     />
                                    <button className="form-control border-0 btn_wid" onClick={e=>this.copyToClipboard(e)} disabled={imageUploading}>{isCopied?'Copied':'Copy'}</button>        
                                </div>
                                    <hr className="my-5" />
                            </div> 

                    </div>
                    </div>

                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}