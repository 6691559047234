import React, {Component} from 'react';
import UploadComponent from '../../Common/UploadComponent';

export default class HomeSocialBannerModal extends Component {
  constructor(){
    super();
    this.state = {
      title: '',
      sub: '',
      image: '',
      buttonText: '',
      buttonLink: '',
      file: {},
      blogspot:'',
      ravelry:'',
      facebook: '', instagram: '', twitter: '', youTube: '', pinterest: '', other: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    const {selectedBanner} = nextProps;
    if(selectedBanner){
      const {title, sub, image, buttonText, buttonLink, file, facebook, instagram,blogspot,ravelry, twitter, youTube, pinterest, other} = selectedBanner;
      return {title, sub, image, buttonLink, buttonText, file, facebook, instagram, twitter, youTube, pinterest, other,blogspot,ravelry}
    } else {
      return null
    }
  }

  render(){
    const {title, sub, image, buttonText, buttonLink, file, facebook, instagram,blogspot,ravelry, twitter, youTube, pinterest, other} = this.state;
    const {handleChange, handleSave, onDrop, submitting, imageUploading} = this.props;
    // console.log(image, file)
    return(
      <React.Fragment>
            <div
                className="modal fade fixed-right"
                id="home_social"
                tabIndex={-1}
                role="dialog"
                aria-modal="true"
              >
                <div className="modal-dialog modal-dialog-vertical" role="document">
                  <form className="modal-content" id="demoForm">
                    <div className="modal-body">
                      <a className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </a>
                      <h2 className="text-left mb-2">Create New Social Banner</h2>
                      <small className="form-text text-muted">Sub Title Banner</small>
                      {/* Divider */}
                      <hr className="mb-4" />
                      <div className="form-box">
                        <div className="form-group">
                          <label htmlFor="Title">Title</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="Title"
                            placeholder="Enter Title"
                            name="title"
                            value={title}
                            onChange={handleChange}
                          />
                        </div>
                        {/* <div className="form-group">
                          <label htmlFor="Description">Description</label>
                          <textarea className="form-control" id="Description" rows="3"></textarea>
                        </div> */}


                        <div className="w-grid">
                        <label htmlFor="Description">Social</label>
                          <div className="d-grid">
                            <div className="form-group d-flex align-items-center">
                              <span className="fe fe-facebook mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="facebook"
                                value={facebook}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <span className="fe fe-edit mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="blogspot"
                                value={blogspot}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                            <span className="fe fe-instagram mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="instagram"
                                value={instagram}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <span className="fe fe-youtube mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="youTube"
                                value={youTube}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <span className="fe fe-target mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="ravelry"
                                value={ravelry}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="form-group d-flex align-items-center">
                              <span className="fe fe-twitter mr-2" />
                              <input
                                type="text"
                                className="form-control focus-out p-2"
                                name="other"
                                value={other}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                      </div>
                        <div className="form-group">
                          <label htmlFor="subtitle">Sub Title</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="subtitle"
                            placeholder="Enter Sub Title"
                            name="sub"
                            value={sub}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="ButtonText">Button Text</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="ButtonText"
                            placeholder="Enter Button Text"
                            name="buttonText"
                            value={buttonText}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="ButtonLink">Button Link</label>
                          <input
                            type="text"
                            className="form-control focus-out"
                            id="ButtonLink"
                            placeholder="Enter Button Link"
                            name="buttonLink"
                            value={buttonLink}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="upload-img-box border w-100 position-relative">
                        <UploadComponent imageUploading={imageUploading} onDrop={onDrop}/>
                      </div>
                      {image.length ? 
                        <div className="box-im d-flex align-items-center mt-4">
                          <div className="img-box-s mr-2">
                            <img className="drop-image" src={image  && image }/>
                          </div>
                          <div className="show-img-info">
                              <h4 className="mb-0  f-size">{file.name}</h4>
                          </div>                                    
                        </div>
                        :null
                      }
                    </div>
                    <div className="modal-footer border-0 text-center">
                      <button type="button" className="btn btn-white ml-auto" data-dismiss="modal" disabled={imageUploading || submitting}>
                        Cancel
                      </button>
                      <button type="button" className="btn btn-primary mr-auto" disabled={imageUploading || submitting}
                        onClick={(e) => handleSave(e, 'socialBanner', 1)}>
                        {submitting ? 'Saving...' : 'Save'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
      </React.Fragment>
		)
	}
}