import React, { Component } from 'react';
// import logo from './logo.svg';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './Navbar/NavBar'
import Home from './Page/Home/Home'
import AboutUs from './Page/AboutUs/AboutUs'
import SocialContribution from './Page/SocialContribution/SocialContribution'
import OurPeople from './Page/OurPeople/OurPeople'
import Sustainability from './Page/Sustainability/Sustainability'
import Infrastructure from './Page/Infrastructure/Infrastructure'
import PrivacyPolicy from './Page/PrivacyPolicy/PrivacyPolicy'
import Terms from './Page/Terms/Terms'
import Login from './Page/Login/Login'
import Blog from './Page/Blog/Blog'
import Createblog from './Page/Blog/Createblog';
import Categories from './Page/Categories';
import Types from './Page/Types';
import Materials from './Page/Materials';
import Brands from './Page/Brands';
import Products from './Page/Products';
import CreateCategories from './Page/CreateCategories';
import CreateTypes from './Page/CreateTypes';
import CreateBrand from './Page/CreateBrand';
import CreateProduct from './Page/CreateProduct';
import CreateMaterials from './Page/CreateMaterials';
import Addsize from './Page/Addsize';
import GroupList from './Page/GroupList';
import CreateGroup from './Page/CreateGroup';
import Header from './Common/Header';
import StyleAndScript from './Page/StyleAndScript';
import SkuList from './Page/SkuList';
import Promo from './Page/Promo';
import Userreview from './Page/Userreview';
import StoreLocator from './Page/StoreLocator';
import Testimonial from './Page/Testimonial';
import CreateTestimonial from './Page/CreateTestimonial';
import Assets from './Page/Assets';
import CreateAssets from './Page/CreateAssets';
import BlogCategory from './Page/Blog/Blogcategory';
import CreateBlogCategories from './Page/Blog/CreateBlogCategories';
import Upload from './Page/Upload';
import BrandDescription from './Page/BrandDescription';
import CreateDescription from './Page/CreateDescription';
import Resources from './Page/Resources';
import Viewresources from './Page/Viewresources';
import Createresources from './Page/Createresources';
import MaterialDescription from './Page/materialDescription';
import CreateMaterialDescription from './Page/CreateMaterialDescription';
import KnittingNeedles from './Page/Container/knitting-needles';
import Crochet from './Page/Container/crochet';
import Faq from './Page/Container/faq';
import BagsCases from './Page/Container/bags-cases';
import KnittingCrochetSets from './Page/Container/knitting-crochet-sets';
import KnittingCrochetAccessories from './Page/Container/knitting-crochet-accessories';
import Newsletter from './Page/Newsletter';
import NewsletterUsers from './Page/NewsletterUsers';
import Productreplacement from './Page/Container/Productreplacement';
import CatalogueDownload from './Page/Container/CatalogueDownload';
import WhatsNew from './Page/Container/whats-new';
import Mailer from './Page/Mailer';
import Catalog from './Page/Container/catalog';
import Legal from './Page/Container/legal';
import WebUrls from './Page/Container/web-urls';
import RedirectionURL from './Page/RedirectionURL';
import TheMindfulCollection from './Page/Container/the-mindful-collection';
export default class App extends Component {
  state = {
    isLoading: true,
    authenticated: false,
    language: 'en',
    langId: 'c_Oh0PInlMb2H7_DAAAA'
  }

  async componentDidMount() {
    const token = localStorage.getItem('userToken');
    const language = localStorage.getItem('KP-admin-lang');
    const langId = localStorage.getItem("languageUid");
    if (token) {
      language
        ? this.setState({ authenticated: true, isLoading: false, language, langId })
        : this.setState({ authenticated: true, isLoading: false });
    } else {
      this.setState({ authenticated: false, isLoading: false });
    }
  }

  reload = async () => {
    await this.componentDidMount()
  }

  setLanguage = (e, lang, langId) => {
    e.preventDefault();
    localStorage.setItem("KP-admin-lang", lang);
    localStorage.setItem("languageUid", langId);
    this.componentDidMount();
  }

  authenticatedRoute = () => {
    const { language, langId } = this.state;
    const appProps = { setLanguage: this.setLanguage, reload: this.reload, language, langId }
    return (
      <>
        <Navbar />
        <Switch>
          <div className="main-content">
            <Header {...appProps} />
            <Route exact path="/" render={(props) => <Home {...props} {...appProps} />} />
            <Route exact path="/about_us" render={(props) => <AboutUs {...props} {...appProps} />} />
            <Route exact path="/social_contribution" render={(props) => <SocialContribution {...props} {...appProps} />} />
            <Route exact path="/our_people" render={(props) => <OurPeople {...props} {...appProps} />} />
            <Route exact path="/sustainability" render={(props) => <Sustainability {...props} {...appProps} />} />
            <Route exact path="/infrastructure" render={(props) => <Infrastructure {...props} {...appProps} />} />
            <Route exact path="/privacy_policy" render={(props) => <PrivacyPolicy {...props} {...appProps} />} />
            <Route exact path="/terms" render={(props) => <Terms {...props} {...appProps} />} />
            {/* <Route exact path="/login" component={Login} /> */}
            <Route exact path="/blog" component={(props) => <Blog {...props} {...appProps} />} />
            <Route exact path="/createblog" component={(props) => <Createblog {...props} {...appProps} />} />
            <Route exact path="/categories" render={(props) => <Categories {...props} {...appProps} />} />
            <Route exact path="/types" render={(props) => <Types {...props} {...appProps} />} />
            <Route exact path="/materials" render={(props) => <Materials {...props} {...appProps} />} />
            <Route exact path="/brands" render={(props) => <Brands {...props} {...appProps} />} />
            <Route exact path="/products" render={(props) => <Products {...props} {...appProps} />} />
            <Route exact path="/createcategories" render={(props) => <CreateCategories {...props} {...appProps} />} />
            <Route exact path="/createtypes" render={(props) => <CreateTypes {...props}{...appProps} />} />
            <Route exact path="/createbrand" render={(props) => <CreateBrand {...props} {...appProps} />} />
            <Route exact path="/createproduct" render={(props) => <CreateProduct {...props} {...appProps} />} />
            <Route exact path="/creatematerials" render={(props) => <CreateMaterials {...props} {...appProps} />} />
            <Route exact path="/addsize" render={(props) => <Addsize  {...props} {...appProps} />} />
            <Route exact path="/groupList" render={(props) => <GroupList  {...props} {...appProps} />} />
            <Route exact path="/creategroup" render={(props) => <CreateGroup  {...props} {...appProps} />} />
            <Route exact path="/style-script" render={(props) => <StyleAndScript {...props} {...appProps} />} />
            <Route exact path="/sizeList" render={(props) => <SkuList {...props} {...appProps} />} />
            <Route exact path="/promo" render={(props) => <Promo {...props} {...appProps} />} />
            <Route exact path="/userreview" render={(props) => <Userreview {...props} {...appProps} />} />
            <Route exact path="/store" render={(props) => <StoreLocator {...props} {...appProps} />} />
            <Route exact path="/testimonial" render={(props) => <Testimonial {...props} {...appProps} />} />
            <Route exact path="/createtestimonial" render={(props) => <CreateTestimonial {...props} {...appProps} />} />
            <Route exact path="/assets" render={(props) => <Assets {...props} {...appProps} />} />
            <Route exact path="/CreateAssets" render={(props) => <CreateAssets {...props} {...appProps} />} />
            <Route exact path="/blogCategory" render={(props) => < BlogCategory {...props} {...appProps} />} />
            <Route exact path="/createBlogCategories" render={(props) => < CreateBlogCategories {...props} {...appProps} />} />
            <Route exact path="/upload" render={(props) => < Upload {...props} {...appProps} />} />
            <Route exact path="/brandDescription" render={(props) => < BrandDescription {...props} {...appProps} />} />
            <Route exact path="/createDescription" render={(props) => < CreateDescription {...props} {...appProps} />} />
            <Route exact path="/resources" render={(props) => < Resources {...props} {...appProps} />} />
            <Route exact path="/viewresources" render={(props) => < Viewresources {...props} {...appProps} />} />
            <Route exact path="/createresources" render={(props) => < Createresources {...props} {...appProps} />} />
            <Route exact path="/materialDescription" render={(props) => < MaterialDescription {...props} {...appProps} />} />
            <Route exact path="/creatematerialdescription" render={(props) => < CreateMaterialDescription {...props} {...appProps} />} />
            <Route exact path="/knitting-needles" render={(props) => < KnittingNeedles {...props} {...appProps} />} />
            <Route exact path="/crochet" render={(props) => < Crochet {...props} {...appProps} />} />
            <Route exact path="/faq" render={(props) => < Faq {...props} {...appProps} />} />
            <Route exact path="/bags-cases" render={(props) => < BagsCases {...props} {...appProps} />} />
            <Route exact path="/knitting-crochet-sets" render={(props) => < KnittingCrochetSets {...props} {...appProps} />} />
            <Route exact path="/knitting-crochet-accessories" render={(props) => < KnittingCrochetAccessories {...props} {...appProps} />} />
            <Route exact path="/Newsletter" render={(props) => < Newsletter {...props} {...appProps} />} />
            <Route exact path="/newsletterusers" render={(props) => < NewsletterUsers {...props} {...appProps} />} />
            <Route exact path="/productreplacement" render={(props) => < Productreplacement {...props} {...appProps} />} />
            <Route exact path="/catalogue-download" render={(props) => < CatalogueDownload {...props} {...appProps} />} />
            <Route exact path="/whats-new" render={(props) => < WhatsNew {...props} {...appProps} />} />
            <Route exact path="/mailer" render={(props) => <Mailer {...props} {...appProps} />} />
            <Route exact path="/catalog" render={(props) => <Catalog {...props} {...appProps} />} />
            <Route exact path="/legal" render={(props) => <Legal {...props} {...appProps} />} />
            <Route exact path="/web-urls" render={(props) => <WebUrls {...props} {...appProps} />} />
            <Route exact path="/redirection-url" render={(props) => <RedirectionURL {...props} {...appProps} />} />
            <Route exact path="/the-mindful-collection" render={(props) => <TheMindfulCollection {...props} {...appProps} />} />
          </div>
        </Switch>
      </>
      // https://www.youtube.com/watch?v=930JPFaKg-s&t=314s
    )
  }

  unauthenticatedRoute = () => {
    return (
      <Switch>
        <div className="main-content">
          <Route exact path="/" render={(props) => <UnauthenticatedHome {...props} />} />
          <Route exact path="/login" render={(props) => <Login {...props} reload={this.reload} />} />
        </div>
      </Switch>
    )
  }

  render() {
    const { authenticated, isLoading } = this.state;
    return (!isLoading &&
      <div className="App">
        <Router>
          <div className="main-container">
            {
              authenticated ? this.authenticatedRoute() : this.unauthenticatedRoute()
            }
          </div>
        </Router>
      </div>
    );
  }
}

class UnauthenticatedHome extends Component {
  componentDidMount() {
    this.props.history.push('/login')
  }

  render() {
    return (
      <div />
    )
  }
}

