import React, { useEffect, useState } from "react"
import { deleteAPI, getAPI, postAPI, putAPI } from "../utils/api"

const demoData = [
    { id:"0", from: "www.google.com", to: "www.fb.com" },
    { id:"1", from: "www.google1.com", to: "www.fb1.com" },
    { id:"2", from: "www.google2.com", to: "www.fb2.com" },
    { id:"3", from: "www.google3.com", to: "www.fb3.com" },
    { id:"4", from: "www.google4.com", to: "www.fb4.com" },
    { id:"5", from: "www.google5.com", to: "www.fb5.com" },
]

const RedirectionURL = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [redirectionData, setRedirectionData] = useState([])
    const [newObj, setNewObj] = useState({
        from: "",
        to: "",
    })

    useEffect(() => {
        getAllRedirectUrl()
    }, [])

    const handleChange = (e) => {
        setNewObj({
            ...newObj,
            [e.target.name]: e.target.value
        })

    }

    const getAllRedirectUrl = () => {
        getAPI("redirectUrl/redirectUrl/all")
        .then(res => {
            setRedirectionData(res.data.data)
            setIsLoading(false)
        })
    }
    
    const updateRedirectUrl = (uid) => {
        if(newObj.from && newObj.to){
            setIsLoading(true)
            putAPI(`redirectUrl/redirectUrl/${uid}`, newObj)
            .then(res => getAllRedirectUrl())
        }
        
    }

    const createRedirectUrl = () => {
        if(newObj.from && newObj.to){
            setIsLoading(true)
            postAPI("redirectUrl/redirectUrl/new", newObj)
            .then(res => getAllRedirectUrl())
        }
    }

    const deleteRedirectUrl = (uid) => {
        setIsLoading(true)
        deleteAPI(`redirectUrl/redirectUrl/${uid}`)
        .then(res => getAllRedirectUrl())
    }

    return (
        <>
            <div className="main-section">
                {console.log(newObj)}
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Redirection URL</h1>
                                </div>
                                <div className="col-auto">
                                    <a href="#!" className="btn btn-primary lift" onClick={() => setNewObj({from:"", to:"", id:""})} data-toggle="modal" data-target="#redirectionURL">Create URL </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-header-title">All URL's </h4>
                                </div>
                                <div className="table-responsive mb-0">
                                    <table className="table table-sm table-wrap card-table">
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>To</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                isLoading
                                                    ?
                                                    <tr>
                                                        <td colspan="5">
                                                            <div className="text-center">
                                                                <div class="spinner-border text-danger" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    :
                                                    redirectionData && redirectionData.map(redirectObj => (
                                                        <tr>
                                                            <td>{redirectObj.from}</td>
                                                            <td>{redirectObj.to}</td>
                                                            <td className="text-right">
                                                                <div className="dropdown">
                                                                    <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fe fe-more-vertical" /></a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a href="#" className="dropdown-item" onClick={() => setNewObj(redirectObj)} data-toggle="modal" data-target="#redirectionURL">Edit</a>
                                                                        <a href="#" className="dropdown-item" onClick={() => deleteRedirectUrl(redirectObj.uid)}>Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ============modal promo============ */}
                <div className="modal fade" id="redirectionURL" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-card card">
                                <div className="card-body">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <label className="mr-3">Redirection URL</label>
                                    <form>
                                        <div><div className="form-group">
                                            <label>Redirect from</label>
                                            <input type="text" className="form-control" name="from" value={newObj?.from} onChange={handleChange} />
                                        </div>
                                            <div className="form-group">
                                                <label>Redirect to</label>
                                                <input type="text" className="form-control" name="to" value={newObj?.to} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            {
                                                newObj?.uid
                                                ?
                                                <button type="button" className="btn btn-primary mr-2" onClick={() => updateRedirectUrl(newObj?.uid)} data-dismiss="modal">Save</button>
                                                :
                                                <button type="button" className="btn btn-primary mr-2" onClick={createRedirectUrl} data-dismiss="modal">Create</button>
                                            }
                                            
                                            <button type="button" className="btn btn-outline-primary" data-dismiss="modal">Cancel</button>
                                        </div>
                                        
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default RedirectionURL