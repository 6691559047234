import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAPI, putAPI, deleteAPI } from '../utils/api';
import ReactHtmlParser from 'react-html-parser';


export default class Testimonial extends Component {
    state = {
        testimonialData: []
    }

    componentDidMount() {
        this.getTestimonials()
    }
    componentDidUpdate(prevProps){
            if (this.props.language !== prevProps.language) {
                this.getTestimonials();       
            }
        
    }
    getTestimonials = () => {
        getAPI(`testimony/testimony/${this.props.langId}`)
            .then(res => {
                let { data, status } = res.data
                if (status === 1) {
                    this.setState({
                        testimonialData: data
                    })
                } else {
                    this.setState({ testimonialData: [] })
                }
            })
            .catch(err => console.log(err))
    }

    publishTestinomial = (Uid, status) => {
        if (Uid !== "" && status !== null) {
            putAPI(`testimony/testimony/${Uid}?isPublished=${!status}`)
                .then(res => {
                    let { message, status } = res.data
                    if (status === 1) {
                        this.getTestimonials()
                    }
                })
                .catch(error => console.log(error))
        }
    }

    deleteTestinomial = (Uid) => {
        if (Uid !== "") {
            deleteAPI(`testimony/testimony/${Uid}`)
                .then(res => {
                    const { message, status } = res.data
                    if (status === 1) {
                        this.getTestimonials()
                    }
                })
                .catch(error => console.log(error))
        }
    }

    render() {
        const { testimonialData } = this.state
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> Testimonial</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to="/createtestimonial" className="btn btn-primary lift">
                                            Create Report
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Testimonial </h4>

                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th>name</th>
                                                    <th>content</th>
                                                    <th>Country</th>
                                                    <th>Category</th>
                                                    <th>Brand</th>
                                                    <th colSpan="2">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    testimonialData.length > 0 && testimonialData.map((ele, index) => (
                                                        <tr key={index}>
                                                            <td>{ele.name}</td>
                                                            <td>{ReactHtmlParser(ele.content)}</td>
                                                            <td>{ele.country}</td>
                                                            <td>{ele.category?.name}</td>
                                                            <td>{ele.brand?.name}</td>
                                                            <td>
                                                                {
                                                                    ele.isPublished
                                                                        ?
                                                                        <span className="badge badge-soft-success">Published</span>
                                                                        :
                                                                        <span className="badge badge-soft-danger">Unpublished</span>
                                                                }
                                                            </td>
                                                            <td className="text-right">
                                                                <div className="dropdown">
                                                                    <a className="dropdown-ellipses dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                        <i className="fe fe-more-vertical" />
                                                                    </a>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <Link className="dropdown-item" to={{ pathname: "/createtestimonial", state: { testimonial: ele } }}>
                                                                            Edit
                                                                        </Link>
                                                                        <Link className="dropdown-item" onClick={() => this.deleteTestinomial(ele.uid)}>
                                                                            Delete
                                                                        </Link>
                                                                        <Link className="dropdown-item" onClick={() => this.publishTestinomial(ele.uid, ele.isPublished)}>
                                                                            {ele.isPublished ? 'Unpublish' : 'Publish'}
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
