import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {getAPI,deleteAPI} from '../../utils/api'

export default class BlogCategory extends Component {

    state={
        categories:[],
        isLoading:false
    }
    componentDidMount(){
        this.getBlogCategories();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.getBlogCategories();
        }
      }
    getBlogCategories=()=>{
        const url = 'blogCategory/category/'+this.props.langId;
        this.setState({isLoading:true});
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({categories:data,isLoading:false})
            }
            else{
                this.setState({categories:[],isLoading:false})
            }
        })
    }
    handleDelete=(uid)=>{
        const data={};
        deleteAPI(`blogCategory/category/${uid}`,data).then(res=>{
            if(res.data.status===1){
                this.getBlogCategories();
            }
        })
    }
    render() {
        const {categories,isLoading}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> Blog Categories</h1>
                                    </div>
                                    <div className="col-auto">
                                       <Link to={{pathname:"/createBlogCategories",state:{status:0}}} className="btn btn-primary lift">
                                        Create Blog Categories
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Blog Categories </h4>
                                       
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                   <th colSpan="2">Category</th>
                                                 </tr>
                                            </thead>
                                            <tbody>
                                              {isLoading
                                              ?
                                              <tr>
                                                    <td colspan="2"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                              :
                                              categories.map((item,idx)=>(
                                                <tr key={idx}>
                                                <td>{item.name}</td>
                                                <td className="text-right">
                                                         <div className="dropdown">
                                                             <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                             <i className="fe fe-more-vertical" />
                                                             </a>
                                                             <div className="dropdown-menu dropdown-menu-right">
                                                             <Link to={{pathname:'/createBlogCategories',state:{status:1,data:item}}} href="#!" className="dropdown-item">
                                                                 Edit
                                                             </Link>
                                                             <a href="#!" className="dropdown-item" onClick={(e)=>this.handleDelete(item.uid)}>
                                                                Delete
                                                             </a>
                                                             </div>
                                                         </div>
                                                     </td>
                                                </tr>
                                              ))
                                            }                                                    
                                           </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
