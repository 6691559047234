import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill' // ES6
import 'react-quill/dist/quill.snow.css';
import { getAPI, postAPI, putAPI } from '../utils/api';
import Select from 'react-select';


export default class CreateTestimonial extends Component {
    constructor(props) {
        super();
        this.state = {
            desc: '',
            brandList: [],
            categoryList:[],
            selectedBrand: {},
            selectedCategory:{},
            postTestimonial: {
                "name": "",
                "country": "",
                "content": "",
                // "brandUid": "",
                "languageUid": "",
                "categoryUid":""
            },
            postTestimonialError: {
                "nameError": "",
                "countryError": "",
                "contentError": "",
                // "brandUidError": "",
                "categoryUidError":""
            },
            testmonialUid: ''
        }
        this.modules = {
            toolbar: [
                [{ 'font': [] }],
                [{ 'size': ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'align': [] }],
                [{ 'color': [] }, { 'background': [] }],
                ['link', 'image', 'video'],
                ['clean'],

            ]
        };

        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background',
            'link', 'image', 'video'
        ];
    }

    componentDidMount() {
        const { langId } = this.props
        this.getBrand(langId);
        this.getCategories(langId);
        let testimonial = this.props?.location?.state?.testimonial
        if (testimonial) {
            this.setState({
                postTestimonial: {
                    ...this.state.postTestimonial,
                    "name": testimonial.name,
                    "content": testimonial.content,
                    "languageUid": testimonial.languageUid,
                    "country": testimonial.country,
                    "brandUid": testimonial?.brandUid,
                    "categoryUid":testimonial.categoryUid
                },
                testmonialUid: testimonial.uid,
                selectedBrand: { label: testimonial?.brand?.name, value: testimonial?.brand?.uid },
                selectedCategory:{label:testimonial?.category?.name,value:testimonial?.category?.uid}
            })
            console.log(this.state.selectedCategory);
        }
    }

    getBrand = (langId) => {
        getAPI(`brand/brand/${langId}`)
            .then(res => {
                const brandList = res.data.data.map(v => {
                    return { value: v.uid, label: v.name }
                })
                this.setState({ brandList, brandLoading: false, postTestimonial: { ...this.state.postTestimonial, languageUid: langId } });
            })
            .catch(err => console.log('Brand list err: ', err))
    }
    getCategories=(langId)=>{
        getAPI(`category/category/${langId}`)
            .then(res => {
                const categoryList = res.data.data.map(v => {
                    return { value: v.uid, label: v.name }
                })
                this.setState({ categoryList});
            })
            .catch(err => console.log('Brand list err: ', err))
    }
    validatePost = async () => {
        let isValid = true
        for (const iter of Object.keys(this.state.postTestimonial)) {
            if (this.state.postTestimonial[iter].length === 0) {
                await this.setState({
                    postTestimonialError: {
                        ...this.state.postTestimonialError, [`${iter}Error`]: "Field Required*"
                    }
                })
                isValid = false
            }
        }
        return isValid
    }

    handleChange = async (e) => {
        if(e?.label){
            await this.setState({ 
                postTestimonial: {
                    ...this.state.postTestimonial, brandUid: e.value
                },
                selectedBrand: e,
                postTestimonialError: {
                    ...this.state.postTestimonialError, brandUidError: ""
                }
            })
        }else if(typeof(e) === 'string'){
            await this.setState({
                postTestimonial: {
                    ...this.state.postTestimonial, content: e
                },
                postTestimonialError: {
                    ...this.state.postTestimonialError, contentError: ""
                }
            })
        }
        else{
            let { name, value } = e.target
            await this.setState({
                postTestimonial: {
                    ...this.state.postTestimonial, [name]: value
                },
                postTestimonialError: {
                    ...this.state.postTestimonialError, [`${name}Error`]: ""
                }
            })
        }
    }
    handleCategoryChange=(e)=>{
        this.setState({
            selectedCategory:e,
            postTestimonial: {
            ...this.state.postTestimonial, categoryUid: e.value,
        },
        postTestimonialError: {
            ...this.state.postTestimonialError, categoryUidError: ""
        }
    })
    }
    createTestimonial = async (e) => {
        e.preventDefault()
        if(this.validatePost()){
            if(this.state.testmonialUid !== ''){
                putAPI(`testimony/updateTestimony/${this.state.testmonialUid}`, this.state.postTestimonial)
                .then(res => {
                    console.log(res);
                    let { status, data } = res.data
                    if(status === 1){
                        this.props.history.push('/testimonial')
                    }
                })
                .catch(error => console.log(error))
            }else{
                postAPI('testimony/testimony', this.state.postTestimonial)
                .then(res => {
                    console.log(res);
                    let { status, data } = res.data
                    if(status === 1){
                        this.props.history.push('/testimonial')
                    }
                })
                .catch(error => console.log(error))
            }
        }
    }

    render() {
        const { brandList, postTestimonial, postTestimonialError, selectedBrand,selectedCategory,categoryList } = this.state
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Testimonial</h1>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                        <div className="col-12">
                                <div className="form-group">
                                    <label> Category</label>
                                    <Select
                                        value={selectedCategory}
                                        onChange={(e) => this.handleCategoryChange(e)}
                                        options={categoryList}
                                        // isDisabled={brandLoading}
                                        // isLoading={brandLoading}
                                        placeholder="Select Brand..."
                                    />
                                    <span className="text-danger">{postTestimonialError.categoryUidError}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Brand</label>
                                    <Select
                                        value={selectedBrand}
                                        onChange={(e) => this.handleChange(e)}
                                        options={brandList}
                                        // isDisabled={brandLoading}
                                        // isLoading={brandLoading}
                                        placeholder="Select Brand..."
                                    />
                                    <span className="text-danger">{postTestimonialError.brandUidError}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Name </label>
                                    <input type="text" name="name" value={postTestimonial.name} className="form-control" onChange={(e) => this.handleChange(e)}/>
                                    <span className="text-danger">{postTestimonialError.nameError}</span>
                                </div>


                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Country </label>
                                    <input type="text" name="country" value={postTestimonial.country} className="form-control" onChange={(e) => this.handleChange(e)}/>
                                    <span className="text-danger">{postTestimonialError.countryError}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Description</label>
                                    <ReactQuill
                                        theme="snow"
                                        modules={this.modules}
                                        formats={this.formats}
                                        onChange={(e) => this.handleChange(e)}
                                        value={postTestimonial.content}
                                    />
                                    <span className="text-danger">{postTestimonialError.contentError}</span>
                                </div>
                                <hr className="my-5" />
                            </div>
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={() => this.props.history.push('/testimonial')}>Cancel</button>
                                    <button className="btn btn-primary" onClick={this.createTestimonial}>Create</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
