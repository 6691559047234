import React, { Component } from 'react';
import Select from 'react-select';
import UploadComponent from '../Common/UploadComponent'
import { getAPI,postMultipleFile, putAPI , postFile } from '../utils/api';
import {storageUrl} from '../utils/BaseUrl';

// import Modal from 'react-modal';
// const customStyles = {
//     content : {
//       top                   : '50%',
//       left                  : '50%',
//       right                 : 'auto',
//       bottom                : 'auto',
//       marginRight           : '-50%',
//       transform             : 'translate(-50%, -50%)'
//     }
//   };

export default class Viewresources extends Component {
    state={
        resourceType:'',
        title:'',
        logo:'',
        images:[],
        imagesUploading:false,
        islink:false,
        showTypesSelect:false,
        options:[],
        selectedType:null,
        link:'',
        showModalIs : false,
        uid:'',
        isSubmitting:false,
        brandUid:''
    };

    handleShowModal = ()=>{
        this.setState({showModalIs : true,selectedType:null, imageName:"" })
    }

    handleClodeModal = () =>{
        this.setState({showModalIs : false})
    }

    componentDidMount(){
        // this.getProductTypes();
        const {data}=this.props.location.state;
        if(data){
            this.setState({
                resourceType:data.resourceType.name[this.props.language],
                title:data.title,
                images:data.assets,
                logo:data.logo,
                showTypesSelect:data.resourceType.hasSubtype,
                uid:data.uid,
                brandUid:data?.brandUid
            },()=>this.getProductTypes())
        }
    }
    getProductTypes=()=>{
        getAPI(`type/types/${this.state.brandUid}?langId=${this.props.langId}`).then(res=>{
            const {status,data}=res.data;
            
            if(status===1){
                const options = data.map(d => {
                    let a = {};
                    a['label'] = d.name;
                    a['value'] = d.uid;
                    return a;
                })
                // console.log(options);
                this.setState({options})
            }else{
                this.setState({options:[]})
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    handleChange=(e)=>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleLinkInput=()=>{
        this.setState({islink:!this.state.islink,link:''})
    }
    handleSelect=selectedType=>{
        this.setState({selectedType});
        console.log('selected Type',selectedType);
    }
    handleUploadImageAndLink=(acceptedFiles,state)=>{
        const imagesArray=this.state.images;
        if(state==='image'){
            this.setState({imagesUploading:true})
            postMultipleFile('upload/upload/resources',acceptedFiles).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    for (let i = 0; i < data.length; i++) {
                        let obj={
                            type:0,
                            value:data[i].s3key,
                            order:imagesArray.length,
                            productTypeId:this.state.selectedType?.value,
                            productType:this.state.selectedType?.label,
                            imageName:this.state.imageName
                        };
                        imagesArray.push(obj);
                    }
                    console.log(imagesArray);
                    this.setState({images:imagesArray,imagesUploading:false})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
        else{
            let link = this.state.link;
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = link.match(regExp);
            let video_id='';
            if(match&&match[7].length===11){
                video_id=match[7];
            }
            let obj={
                type:1,
                order:imagesArray.length,
                productTypeId:this.state.selectedType?.value,
                productType:this.state.selectedType?.label,
                value:link,
                video_id:video_id
            }
            //console.log(video_id);
            imagesArray.push(obj);
             console.log(imagesArray);
            this.setState({link:'',images:imagesArray,islink:false});
            
        }
    }
    removeImage=(e,value)=>{
        let imagesArray=this.state.images;
        let newArray=[];
        for(let i=0;i<imagesArray.length;i++){
            if(imagesArray[i].value!==value){
                imagesArray[i].order=newArray.length;
                newArray.push(imagesArray[i]);
            }
        }
        console.log(newArray);
        this.setState({images:newArray});
    }
    handleCancel=()=>{
        this.props.history.push('/resources');
    }
    handleUploadImage=(files)=>{
        if(files.length>1){
            alert("Only single file allowed");
        }else{
            this.setState({imagesUploading:true});
            postFile('upload/upload/resources',files[0]).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    this.setState({logo:data[0].s3key,imagesUploading:false})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
    }
    handleSubmit=()=>{
        this.setState({isSubmitting:true})
        const d={
            assets:this.state.images,
            logo:this.state.logo,
            title:this.state.title
        }
        // console.log(d);
        putAPI(`resource/resource/${this.state.uid}`,d).then(res=>{
            const {status}=res.data;
            if(status===1){
            this.setState({isSubmitting:false})
            // console.log(status,data);
            this.props.history.push('/resources');
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
  
        const {resourceType,title,logo,images,islink,imagesUploading,showTypesSelect,options,selectedType,link,isSubmitting,imageName}=this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle">Resource Type</h6>
                                    <h1 className="header-title">{resourceType}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                   <div className="avatar avatar-xl">
                                        <img className="img-fluid rounded" src={`${storageUrl+ logo}`} alt="..." />
                                        <button type="button" className="btn btn-outline-secondary btn-sm" data-toggle="modal" data-target="#updateContent"><i className="fe fe-edit" /> logo</button>
                                    </div>
                                </div>
                                <div className="col ml-n2 text-center">
                                    <h4 className="mb-1"> {title} </h4>
                                 </div>
                            </div>
                            
                        </div>
                        <div class="col-auto">

                
                  <button class="btn btn-primary lift" data-target="#addimage" data-toggle="modal"  onClick={this.handleShowModal}>
                    Add Images
                  </button>

                </div>
                <div className="col-12">
                <hr className="my-5" />
                </div>
                     </div>
                     <div className="row">
                         <div className="col-12">
                             <h2>Images</h2>
                         </div>
                         {images.map((item,idx)=>(
                             <div className="col-2 mb-5" key={idx}>
                             {item.type===0?<img className="avatar-img rounded" src={`${ storageUrl+item.value}`} alt="..." />:<img className="avatar-img rounded" src={`https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`} alt="..." />}
                             <button type="button" class="btn_cr close-img-icon" onClick={e=>this.removeImage(e,item.value)}><span class="d-block">X</span></button>
                             {item.productType?<h6 className="text-center">{item.productType}</h6>:""}
                             {item.imageName?<h6 className="text-center">{item.imageName}</h6>:""}
                             </div>
                         ))}
                        
                     </div>
                     <div className="col-12">
                <hr className="my-5" />
                </div>
                     <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                    <button className="btn btn-primary" disabled={imagesUploading || isSubmitting} onClick={this.handleSubmit}>{isSubmitting?'Saving...':'Save'}</button>
                                </div>
                            </div>
                    
                 </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="addimage" tabIndex={-1} role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                     <div className="modal-content">
                         <div className="modal-card card">
                           <div className="card-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            <div className="row">
                                {showTypesSelect?
                                <div className="col-12">
                                    <div className="form-group">
                                    <label>Type</label>
                                    <Select
                                            value={selectedType}
                                            onChange={e=>this.handleSelect(e)}
                                            options={options}
                                        />
                                    </div>
                                    <div className="form-group">
                                    <label>Image Name</label>
                                    <input type="text" name="imageName" value={imageName} className="form-control" onChange={e=>this.handleChange(e)} />
                                    </div>
                                </div>:''}
                                <div className="col-12">
                                    <div className="form-group">
                                    <label>Upload</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple rem_border">
                                    <UploadComponent imageUploading={imagesUploading} onDrop={files => this.handleUploadImageAndLink(files,'image')} accept={'image/*'}/>
                                    <div className="upload_link section text-center">
                                        <h2 className="color_text">or</h2>
                                        <button className="btn_cs" onClick={this.handleLinkInput}>upload link</button>
                                        {islink?<div className="link_box d-flex justify-content-center mb-4">
                                            <input type="text" className="link_text d-block" name="link" value={link} onChange={(e)=>this.handleChange(e)} placeholder="url.."/>
                                            <button type="button" className="btn_submit" onClick={e=>this.handleUploadImageAndLink(e,'link')}>add</button>
                                        </div>:''}
                                    </div>
                                </div>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                         </div>
                    </div>
                    </div>
                </div> 
                <div className="modal fade" id="updateContent" tabIndex={-1} role="dialog" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                     <div className="modal-content">
                         <div className="modal-card card">
                           <div className="card-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                    <label>Title</label>
                                    <input type="text" name="title" value={title} className="form-control" onChange={e=>this.handleChange(e)} />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group">
                                    <label>Logo</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple rem_border">
                                    <UploadComponent imageUploading={imagesUploading} onDrop={files => this.handleUploadImage(files)} accept={'image/*'}/>
                                    
                                </div>
                                    </div>
                                </div>
                                
                            </div>
                          </div>
                         </div>
                    </div>
                    </div>
                </div>         
            </div>
            
        )
    }
}