import React, {Component} from 'react';
import Dropzone from 'react-dropzone'

export default class UploadComponent extends Component {
    render(){
        const {imageUploading, onDrop, accept} = this.props;
        return(
            <div className="dz-default dz-message">
                <Dropzone  onDrop={onDrop} disabled={imageUploading} accept={(accept === 'css' || accept === 'js') ? null : 'image/jpeg, image/png,image/gif'}>
                    {({getRootProps, getInputProps}) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} className="back-inp c-pointer"/>
                            <span className="dz-button center-box">
                                {imageUploading ? 'uploading....' : 'Drag and drop or click to upload'}
                            </span>
                            {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                        </div>
                    )}
                </Dropzone>
            </div>
        )
    }
}