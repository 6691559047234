import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { deleteAPI, getAPI, putAPI,postAPI } from '../utils/api';
import ReactHtmlParser from 'react-html-parser';
import { storageUrl } from '../utils/BaseUrl';
import ReactPaginate from 'react-paginate';


export default class products extends Component {
    state = {
        isLoading: true,
        allProducts: [],
        products:[],
        prodObj: {},
        allProductsCopy: [],
        currentPage:0,
        totalPages:0,
        iswebButton:false
    }

    getProduct = () => {        
        getAPI(`product/products/${this.props.langId}`)
            .then(res => {
                let totalPages=Math.ceil(res.data.data.length /10);
                let currentPageData=res.data.data.slice(0,10)
                this.setState({ allProducts: res.data.data,products:currentPageData, allProductsCopy: res.data.data, totalPages,isLoading: false })
            })
            .catch(err => console.log('Product list error: ', err))
    }

    componentDidMount() {
        this.setState({isLoading:true})
        this.getProduct();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.setState({isLoading:true})
            this.getProduct();
        }
    }
    handleDelete = (e) => {
        e.preventDefault();
        const uid = this.state.prodObj.uid;
        deleteAPI(`product/product/${uid}`).then(res => {
            console.log(res.data);
            this.getProduct();
        }).catch(err => console.log('Delete product error: ', err))
    }
    handleSelect = (e, item) => {
        this.setState({ prodObj: item });
    }
    handleBestSeller = (e, flag, productUid) => {
        let bool = 1;
        if (flag === true) {
            bool = 0;
        }
        const url = 'product/bestSeller?' + 'puid=' + productUid + '&bestSeller=' + bool
        console.log(url);
        const data = {};
        putAPI(url, data).then(res => {
            const { status } = res.data;
            if (status === 1) {
                this.getProduct();
                console.log('BestSeller Upgraded');
            } else {
                alert('not updated')
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    handleMulticolor=(e,flag,productUid)=>{
        // console.log("12345566",flag,productUid);
        const url='product/multicolor/'+productUid+'?isMulticolor='+!flag
        console.log(url);
        const data={};
        putAPI(url, data).then(res => {
            const { status } = res.data;
            if (status === 1) {
                 this.getProduct();
                console.log('Multicolor Upgraded');
            } else {
                alert('not updated')
            }
        }).catch(err=>{
            console.log(err);
        })

    }

    handleWebSearch=(e)=>{
        this.setState({iswebButton:true})
       postAPI('product/elasticsearch',{}).then(res=>{
           const {data,status}=res.data;
           if(status===1){
               this.setState({iswebButton:false})
               alert(`${data.count.count} product added for search`)
           }else{
               alert('product not added for search')
           }
       }).catch(err=>{
           console.log(err);
       })
    }

    searchFilter = async (e) => {
        let { value } = e.target
        let filterObj = this.state.allProductsCopy.filter(ele =>ele?.tagline.toLowerCase().includes(value.toLowerCase()) || ele?.brand?.name.toLowerCase().includes(value.toLowerCase()) || ele?.category?.name.toLowerCase().includes(value.toLowerCase()) )
        await this.setState({ products: filterObj })
    }
    handlePageClick=(selectedPage)=>{
        // console.log(selectedPage);
        const PER_PAGE = 10;
        // console.log("Hi",this.state.currentPage)
        const offset = (selectedPage.selected) * PER_PAGE;
        // console.log('offset',offset)
        const currentPageData = this.state.allProducts.slice(offset, offset + PER_PAGE)
        // console.log('current datat',currentPageData)
        this.setState({products:currentPageData,currentPage:selectedPage.selected})
    }
    render() {
        const { allProducts, isLoading,currentPage,totalPages,products, iswebButton } = this.state;
        // console.log(this.state.allProducts);
        return (

            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Overview</h6>
                                    <h1 className="header-title">Products</h1>
                                    
                                </div>
                                <div >
                                    <input type="text" className="form-control" placeholder="Search..." name="search" onChange={this.searchFilter} />
                                </div>
                                <div className="col-auto">
                                    <Link to="/createproduct" className="btn btn-primary lift">Create Products  </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                
                    <div className="card">
                    
                        <div className="card-header">
                            <h4 className="card-header-title">All Products  </h4>
                            <div className="col-auto">
                                    <button className="btn btn-info btn-sm text-right" type="submit" disabled={iswebButton} onClick={this.handleWebSearch}>Update Website Search</button>
                                </div>
                                <h5 className="card-header-title">{allProducts.length>0?`Total : ${allProducts.length}`:'' }</h5>
                        </div>
                        <div className="table-responsive mb-0">
                            <table className="table table-sm table-wrap card-table">
                                <thead>
                                    <tr>
                                        <th className="w-10">Order</th>
                                        <th>Product Name</th>
                                        {/* <th>Description</th> */}
                                        <th>Brand</th>
                                        <th>Category</th>
                                        <th>Material</th>
                                        <th >Type</th>
                                        <th>Multicolored</th>
                                        <th colSpan="2">BestSeller</th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading
                                        ? <tr>
                                        <td colspan="7"> 
                                            <div className="text-center">
                                                <div class="spinner-border text-danger" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                        :
                                        products.map((item, idx) => (
                                            
                                            <tr key={idx}>
                                                <td>{item.order}</td>
                                                <td>
                                                    <Link to="#" className="avatar avatar-sm">
                                                        <img src={`${storageUrl+ item.thumbnailImage}`} alt="..." className="avatar-img rounded-circle" />
                                                    </Link>
                                                    <span>{item.tagline}</span>
                                                </td>
                                                
                                                {/* <td className="width-40">{ReactHtmlParser(item.desc)}</td> */}
                                                <td>{item.brand != null ? item.brand.name : ''}</td>
                                                <td>{item.category != null ? item.category.name : ''}</td>
                                                <td>{item.material != null ? item.material.name : ''}</td>
                                                <td>{item.productType != null ? item.productType.name : ''} </td>
                                                <td><div className="custom-control custom-checkbox checklist-control d-block"> <input type="checkbox" checked={item.isMultiColor} onChange={(e) => this.handleMulticolor(e, item.isMultiColor, item.uid)} /></div></td>

                                                <td><div className="custom-control custom-checkbox checklist-control d-block"> <input type="checkbox" checked={item.isBestseller} onChange={(e) => this.handleBestSeller(e, item.isBestseller, item.uid)} /></div></td>

                                                <td className="text-right">
                                                    <div className="dropdown">
                                                        <a href="#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i className="fe fe-more-vertical"></i>
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                            <Link to={{
                                                                pathname: "/createproduct",
                                                                state: {
                                                                    productDetail: item
                                                                }
                                                            }} className="dropdown-item">Edit</Link>

                                                            <Link to={{ pathname: "/groupList", state: { productUid: item.uid, data: item } }} className="dropdown-item">
                                                                Add Group/Pack
                                                            </Link>
                                                            <Link to="#/" className="dropdown-item" data-target="#delete_Product_popUp" data-toggle="modal" onClick={(e) => this.handleSelect(e, item)}>
                                                                Delete
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <nav className="pb-4" aria-label="Page navigation example">
                    <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    disableInitialCallback={true}
                    // forcePage={
                    //   this.state.dataError && currentPage - 1
                    // }
                    pageCount={totalPages}
                    initialPage={currentPage - 1}
                    activeLinkClassName={"page-link active"}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    containerClassName="pagination pagination-lg justify-content-center"
                    onPageChange={e=>this.handlePageClick(e)}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    disabledClassName={"disabled"}
                    activeClassName="page-item active"></ReactPaginate></nav>
                    {/* <nav aria-label="Page navigation example">
                                <ul className="pagination pagination-lg justify-content-center">
                                <li className="page-item"><a className="page-link" href="#!">Previous</a></li>
                                <li className="page-item active"><a className="page-link" href="#!">1</a></li>
                                <li className="page-item"><a className="page-link" href="#!">2</a></li>
                                <li className="page-item"><a className="page-link" href="#!">Next</a></li>
                                </ul>
                            </nav> */}
                </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="delete_Product_popUp" tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="card-header">
                                <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Product <span className="text-color">{this.state.prodObj.tagline} ? </span>  </h4>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-12 text-center">
                                    <button type="button" className="btn btn-primary btn-lg mr-3" onClick={(e) => this.handleDelete(e)} data-dismiss="modal">  Yes  </button>
                                    <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal" >  No  </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}