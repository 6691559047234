import React, { Component } from 'react'
import {getAPI,postAPI,putAPI,postFile} from '../utils/api';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import UploadComponent from '../Common/UploadComponent';
import { storageUrl } from '../utils/BaseUrl';


export default class CreateDescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryOptions:[],selectedCategory:null,seoTitle:"",seoMeta:"",seoKeywords:"",seoDesc:"",
            selectedBrand:null , brandOptions:[] ,desc:"",coverImage:'',imageUploading:false,
            ogFields: {
                ogTitle: "",
                ogDescription: "",
                ogImage: ""
            }
          };
        this.modules = {
            toolbar: [
              [{ 'font': [] }],
              [{ 'size': ['small', false, 'large', 'huge'] }],
              ['bold', 'italic', 'underline'],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              [{ 'align': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['clean']
            ]
        };
    
        this.formats = [
            'font',
            'size',
            'bold', 'italic', 'underline',
            'list', 'bullet',
            'align',
            'color', 'background'
          ];
    }
      
    componentDidMount(){
        const status=this.props.location?.state?.status;
        const data=this.props.location?.state?.data;
        if(status===1){
            this.setState({
                desc:data.desc,
                selectedBrand:{label:data.brand,value:data.brandUid},
                selectedCategory:{label:data.category,value:data.categoryUid},
                coverImage:data?.coverImage,
                seoMeta:data.seoFields?.seoMeta,
                seoDesc:data?.seoFields?.seoDesc,
                seoKeywords:data?.seoFields?.seoKeywords,
                seoTitle:data?.seoFields?.seoTitle,
                ogFields:data?.seoFields?.ogFields,
            })
        }
        this.getCategories();
        this.getBrands();
    }  

    getCategories(){
        getAPI(`category/category/${this.props.langId}`)
        .then(res => {
            const {data,status} = res.data;
            const optionArray = data.map(d => {
                let a = {};
                a['label'] = d.name;
                a['value'] = d.uid;
                return a;
            })
            this.setState({ categoryOptions: optionArray });
        }).catch(err => console.log('lang error: ', err.response))
    }
    
    getBrands(){
        const url='brand/brand/'+this.props.langId;
        getAPI(url).then(res=>{
            const {status,data}=res.data;
            const optionArray = data.map(d => {
                let a = {};
                a['label'] = d.name;
                a['value'] = d.uid;
                return a;
            })
            this.setState({ brandOptions: optionArray });
        }).catch(err=>{
            console.log(err);
        })
    }
    
    handleChange = (e,selectedOption) => {
        if(selectedOption==='selectedCategory'||selectedOption==='selectedBrand'){
            this.setState({[selectedOption]:e})
             }else{
                 this.setState({[e.target.name]:e.target.value})
             }
      };
    
    handleChangeEditor = (e) => {
        this.setState({desc:e})
    }
    handleSubmit=(e)=>{
        const status=this.props.location?.state?.status;
        const seoFields={
            "seoMeta":this.state.seoMeta,
            "seoDesc":this.state.seoDesc,
            "seoTitle":this.state.seoTitle,
            "seoKeywords":this.state.seoKeywords,
            "ogFields":this.state.ogFields
        }
        const data={
            desc:this.state.desc,
            brandUid:this.state.selectedBrand?.value,
            categoryUid:this.state.selectedCategory?.value,
            coverImage:this.state.coverImage,
            seoFields:seoFields

        }
        if(status && status===1){
            putAPI(`brandDesc/description/${this.props.location.state.data.uid}`,data).then(res=>{
                const {status,data}=res.data;
            if(status===1){
                let path = `/brandDescription`;
                this.props.history.push(path);
            }
            }).catch(err=>{console.log(err)});
        }else{
        postAPI(`brandDesc/description`,data).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                let path = `/brandDescription`;
                this.props.history.push(path);
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    }
    handleCancel=()=> {
        let path = `/brandDescription`;
        this.props.history.push(path);
    }  
    onDrop=(acceptedFiles)=>{
        if(acceptedFiles.length>1){
            alert("Only one Image allowed")
        }else{
            this.setState({imageUploading:true})
            postFile('upload/upload/brandCoverImages',acceptedFiles[0]).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    this.setState({coverImage:data[0].s3key,imageUploading:false})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
    }

    handleOgDataChange = e => {
        console.log(e.target.getAttribute('name'), e.target.value)
        this.setState({
            ogFields:{
                ...this.state.ogFields,
                [e.target.getAttribute('name')] : e.target.value
                
            }
        })
    }

    render() {
        const { selectedCategory,selectedBrand,categoryOptions,brandOptions,imageUploading,coverImage,seoDesc,seoKeywords,seoMeta,seoTitle, ogFields } = this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Create Description</h1>
                                    </div>
                                    {/* <div className="col-auto">
                                       <a href="/#" className="btn btn-primary lift">Create Categories </a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                <label>Category</label>
                                <Select
                                        value={selectedCategory}
                                        onChange={e=>this.handleChange(e,'selectedCategory')}
                                        options={categoryOptions}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                            <div className="form-group">
                                <label>Brand</label>
                                <Select
                                        value={selectedBrand}
                                        onChange={e=>this.handleChange(e,'selectedBrand')}
                                        options={brandOptions}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                            <div className="form-group">
                            
                            <label>Cover Image</label>
                            <div className="upload-img-box border w-100 position-relative over-hidden f-out">
                                <UploadComponent imageUploading={imageUploading} onDrop={files => this.onDrop(files)}/>
                            </div>
                            </div>
                            {coverImage ?
                                        <div className="box-im d-flex align-items-center mt-4">
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={`${storageUrl +coverImage}`} alt="..." />
                                            </div>

                                            {/* <div className="show-img-info">
                                            <h4 className="mb-0  f-size">{this.state.files_body_content.name?this.state.files_body_content.name:this.state.icon.split('/').pop()}</h4>
                                        </div> */}

                                        </div>
                                        : null}
                            </div>
                           <div className="col-12">
                               <label></label>
                               <ReactQuill theme="snow" modules={this.modules}
                                formats={this.formats}  onChange={this.handleChangeEditor}
                                value={this.state.desc}/>
                            <hr className="my-5"/>
                           </div>
                           <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Title</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo title..."
                                 name="seoTitle"
                                 value={seoTitle}
                                 onChange={e => this.handleChange(e)}
                                 />
                                
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Meta </label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo meta..."
                                 
                                 name="seoMeta"
                                 value={seoMeta}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Description</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo description..."
                                 
                                 name="seoDesc"
                                 value={seoDesc}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Keywords</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo keywords..."
                                 required="required"
                                 name="seoKeywords"
                                 value={seoKeywords}
                                 onChange={e => this.handleChange(e)}
                                 />
                                </div>
                                <hr className="my-5"/>
                            </div>

                            {/* OG tags fields */}
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Title</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Title..."
                                        required="required"
                                        name="ogTitle"
                                        value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Description</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Description..."
                                        required="required"
                                        name="ogDescription"
                                        value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>OG Image</label>
                                    <input type="text" 
                                        className="form-control"
                                        placeholder="OG Image..."
                                        required="required"
                                        name="ogImage"
                                        value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                                        onChange={e => this.handleOgDataChange(e)}
                                    />
                                </div>
                                <hr className="my-5"/>
                            </div>
                         
                            <div className="col-12 text-center ">
                                <div className="form-group " >
                                <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                <button className="btn btn-primary "onClick={this.handleSubmit} disabled={imageUploading}>Create Description</button>                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}

