import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getAPI ,deleteAPI} from '../utils/api';


export default class Categories extends Component {
    constructor(){
        super();
        this.state={categ:[],catObj:{},isLoading:false}
    }
    componentDidMount(){
        this.apiData();        
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.setState({categ:[]});
            this.apiData();
        }
      }
    apiData(){
        const url='category/category/'+this.props.langId;
        this.setState({isLoading:true})
        getAPI(url)
        .then(res => {
            const {data, status} = res.data;
          if(status===1&&data.length>=0){
              this.setState({categ:data,isLoading:false})
          }else{
              this.setState({categ:[],isLoading:false})
          }
        }).catch(err => console.log('lang error: ', err.response))
    }  
    
    popUp=(obj)=>{
       this.setState({catObj:obj,show:true});
    }

    handleCancel=()=>{
        this.setState({catObj:{}});
    }
    handleDelete=()=>{
        const url='category/category/'+this.state.catObj.uid;
        const data={};
        deleteAPI(url,data).then(res =>{
            const {status}=res.data;
            if(status===1){
                this.setState({catObj:{}});
                this.apiData();
            }
        }).catch(err=>{
            console.log(err);
        })
       
    }
    handleEdit=(obj)=>{
       this.setState({catObj:obj});
    }
    render() {
        
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Categories</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={{pathname:"/createcategories",state:{status:0}}} className="btn btn-primary lift">Create Category </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Categories </h4>
                                        {/* <h5 className="card-header-title">{this.state.categ.length>0?`Total : ${this.state.categ.length}`:'' }</h5> */}
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    
                                                   <th className="w-10">Order</th>
                                                   <th colSpan="2">CATEGORY NAME</th>
                                                  
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.isLoading
                                                ?<tr>
                                                    <td colspan="2"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                :
                                                 this.state.categ.map((obj,idx)=>(
                                                     <tr  key={idx}>
                                                    <td>{obj.order}</td>
                                                     <td>{obj.name}</td>
                                                     <td className="text-right">
                                                       <div className="dropdown">
                                                       <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                           <i className="fe fe-more-vertical"></i>
                                                       </a>
                                                       <div className="dropdown-menu dropdown-menu-right">
                                                           {/* <span class="dropdown-item" role="button" data-toggle="modal" data-target="#all_ategories_mode">
                                                          Delete
                                                           </span > */}
                                                           {/* <span class="dropdown-item">
                                                           Edit
                                                           </span> */}
                                                           <Link to={{pathname:"/createcategories",state:{data:obj,status:1}}} className="dropdown-item">
                                                        Edit
                                                        </Link>
                                                        <a href="#!" className="dropdown-item" data-id={obj.uid} data-target="#all_ategories_modes" data-toggle="modal" 
                                                        onClick={(e) => {
                                                            // e.preventDefault(); 
                                                            this.popUp(obj)}}
                                                            >
                                                       Delete
                                                        </a>

                                                       </div>
                                                       </div>
                                                   </td>
                                                   </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
    
                    </div>
                    {/* modal for delete */}
                    <div className="modal fade" id="all_ategories_modes" tabIndex={-1} role="dialog" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Category <span className="text-color">{this.state.catObj.name } ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                    <div className="modal-body">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-primary btn-lg mr-3"   onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                            <button type="button" className="btn btn-secondary btn-lg"  data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    {/* End */}
                    
                </div>

            </>
        )
    }
}