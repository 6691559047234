import React, { Component } from 'react'
import { getAPI, deleteAPI } from '../utils/api';
import { Link } from 'react-router-dom'


export default class Types extends Component {
    constructor() {
        super();
        this.state = { types: [], typeObj: {}, isLoading: false };
    }
    componentDidMount() {
        this.getTypes();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.setState({ types: [] });
            this.getTypes();

        }
    }

    getTypes() {
        const url = 'type/type/' + this.props.langId;
        this.setState({ isLoading: true });
        getAPI(url).then(res => {
            const { data, status } = res.data;
            if (status === 1 && data.length >= 0) {
                this.setState({ types: data, isLoading: false })
            } else {
                this.setState({ types: [], isLoading: false })
            }
        }).catch(err => {
            console.log('error', err)
        })

    }
    selectType = (obj) => {
        this.setState({ typeObj: obj })
    }
    handleDelete = () => {
        const url = 'type/type/' + this.state.typeObj.uid;
        const data = {};
        deleteAPI(url, data).then(res => {
            const { status } = res.data;
            if (status === 1) {
                this.setState({ typeObj: {} });
                this.getTypes();
            }
        }).catch(err => {
            console.log(err);
        })

    }
    handleCancel = () => {
        this.setState({ typeObj: {} });
    }
    render() {
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Types </h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={{ pathname: "/createtypes", state: { status: 0 } }} className="btn btn-primary lift">Create Types  </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">All Types  </h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                    <th className="w-10">Order</th>
                                                    <th>Name</th>
                                                    <th colSpan="2"> Categories</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.isLoading
                                                    ? <tr>
                                                    <td colspan="3"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    :
                                                    this.state.types.map((obj, idx) =>
                                                    (<tr key={idx}>
                                                        <td>{obj.order}</td>
                                                        <td>{obj.name}</td>
                                                        <td className="category-multiple">
                                                            {obj.categories.map((category, idx) => (
                                                                <span key={idx} className="badge badge-soft-secondary">{category.name}</span>)
                                                            )}
                                                        </td>
                                                        <td className="text-right">
                                                            <div className="dropdown">
                                                                <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fe fe-more-vertical"></i>
                                                                </a>
                                                                <div className="dropdown-menu dropdown-menu-right">

                                                                    <Link to={{ pathname: "/createtypes", state: { status: 1, data: obj } }} className="dropdown-item">
                                                                        Edit
                                                                    </Link>
                                                                    <a href="#!" className="dropdown-item" data-id={obj.uid} data-target="#open_modal_popUp" data-toggle="modal" onClick={(e) => { this.selectType(obj) }}>
                                                                        Delete
                                                                    </a>

                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal fade" id="open_modal_popUp" tabIndex={-1} role="dialog" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="card-header">
                                    <h4 className="card-header-title text-center" id="exampleModalCenterTitle">Delete Type <span className="text-color">{this.state.typeObj.name} ? </span>  </h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="col-12 text-center">
                                        <button type="button" className="btn btn-primary btn-lg mr-3" onClick={this.handleDelete} data-dismiss="modal">  Yes  </button>
                                        <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal" onClick={this.handleCancel} >  No  </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}