import React, { Component } from 'react';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import { getAPI, postAPI, postFile, putAPI } from '../utils/api';
import { storageUrl } from '../utils/BaseUrl';

export default class CreateAssets extends Component {
    state = {
        postAssets: {
            "images": [],
            "brandUid": "",
            "languageUid": "",
            "categoryUid": "",
            "materialUid": ""
        },
        postAssetsError: {
            "imagesError": "",
            "brandUidError": "",
            "categoryUidError": "",
            "materialUidError": ""
        },
        brandSelect: {},
        categorySelect: {},
        materialSelect: {},
        brandList: [],
        categories: [],
        materials: [],
        files_body_content: {},
        assetsUid: ""
    }

    componentDidMount() {
        this.getBrand()
        this.getCategories()
        this.getMaterials()
        let assets = this.props?.location?.state?.assets
        if (assets) {
            this.setState({
                postAssets: {
                    ...this.state.postAssets,
                    "images": assets.image,
                    "brandUid": assets.brandUid,
                    "languageUid": assets.languageUid,
                    "categoryUid": assets.categoryUid,
                    "materialUid": assets.materialUid
                },
                brandSelect: { label: assets.brand.name, value: assets.brand.uid },
                categorySelect: { label: assets.category.name, value: assets.category.uid },
                materialSelect: { label: assets.material.name, value: assets.material.uid },
                assetsUid: assets.uid
            })
        }
    }

    getBrand = () => {
        let langId = this.props.langId
        getAPI(`brand/brand/${langId}`)
            .then(res => {
                const brandList = res.data.data.map(v => {
                    return { value: v.uid, label: v.name, name: 'brand' }
                })
                this.setState({ brandList, postAssets: { ...this.state.postAssets, languageUid: langId } });
            })
            .catch(err => console.log('Brand list err: ', err))
    }

    getCategories = () => {
        let langId = this.props.langId
        getAPI(`category/category/${langId}`)
            .then(res => {
                const categories = res.data.data.map(v => {
                    return { value: v.uid, label: v.name, name: 'category' }
                })
                this.setState({ categories, categoryLoading: false })
            })
            .catch(err => console.log('Category list err: ', err))
    }

    getMaterials = () => {
        const url = 'material/material/' + this.props.langId;
        getAPI(url).then(res => {
            const { data, status } = res.data;
            if (status === 1) {
                let materials = data.map(ele => {
                    return { label: ele.name, value: ele.uid, name: 'material' }
                })
                this.setState({ materials })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    onDropBody = (acceptedFiles) => {
        if (acceptedFiles[0].type !== 'image/jpeg' && acceptedFiles[0].type !== 'image/png') {
            alert('Only JPEG and PNG file accepted.')
        } else {
            postFile('upload/upload/brand', acceptedFiles[0])
                .then(res => {
                    if (res.data.status === 1) {
                        this.setState({
                            postAssets: {
                                ...this.state.postAssets, images: [res.data.data[0].s3key]
                            },
                            files_body_content: acceptedFiles[0],
                            postAssetsError: {
                                ...this.state.postAssetsError, imagesError: ""
                            }
                        })
                    }
                    else {
                        alert('Image not Uploaded')
                    }
                }).catch(err => console.log(err))
        }

    }

    handleChange = selectedOption => {
        if (selectedOption) {
            this.setState({
                [`${selectedOption.name}Select`]: selectedOption,
                postAssets: {
                    ...this.state.postAssets, [`${selectedOption.name}Uid`]: selectedOption.value
                },
                postAssetsError: {
                    ...this.state.postAssetsError, [`${selectedOption.name}UidError`]: ""
                }
            })
        }
    }

    validateAssetsPost = async () => {
        let isValid = true
        for (const iter of Object.keys(this.state.postAssets)) {
            if (this.state.postAssets[iter].length === 0) {
                await this.setState({
                    postAssetsError: {
                        ...this.state.postAssetsError, [`${iter}Error`]: "Field Required*"
                    }
                })
                isValid = false
            }
        }
        return isValid
    }

    handleclickAssets = async (e) => {
        e.preventDefault()
        let validate = await this.validateAssetsPost()
        if (validate) {
            if (this.state.assetsUid !== "") {
                putAPI(`assets/asset/${this.state.assetsUid}`, this.state.postAssets)
                    .then(res => {
                        let { status, data } = res.data
                        if (status === 1) {
                            this.props.history.push("/assets")
                        }
                    })
                    .catch(error => console.log(error))
            } else {
                postAPI('assets/asset', this.state.postAssets)
                    .then(res => {
                        let { status, data } = res.data
                        if (status === 1) {
                            this.props.history.push("/assets")
                        }
                    })
                    .catch(error => console.log(error))
            }
        }
    }

    render() {
        const { brandList, categories, materials, brandSelect, materialSelect, categorySelect } = this.state;
        const { images } = this.state.postAssets
        const { imagesError, brandUidError, categoryUidError, materialUidError } = this.state.postAssetsError
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> Assets Management</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label> Brands </label>
                                    <Select
                                        value={brandSelect}
                                        onChange={this.handleChange}
                                        options={brandList}
                                    />
                                    <span className="error-field-error">{brandUidError}</span>
                                </div>
                                <div className="form-group">
                                    <label> Category </label>
                                    <Select
                                        value={categorySelect}
                                        onChange={this.handleChange}
                                        options={categories}
                                    />
                                    <span className="error-field-error">{categoryUidError}</span>
                                </div>
                                <div className="form-group">
                                    <label> materials </label>
                                    <Select
                                        value={materialSelect}
                                        onChange={this.handleChange}
                                        options={materials}
                                    />
                                    <span className="error-field-error">{materialUidError}</span>
                                    <hr class="my-5" />
                                </div>
                                <div className="form-group">
                                    <label> Images</label>
                                    <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple">
                                        <div className="dz-default dz-message">
                                            <Dropzone onDrop={e => this.onDropBody(e)} accept={'image/jpeg, image/png'}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} className="back-inp c-pointer" />
                                                            <span className="dz-button center-box">Drop file here to upload</span>
                                                        </div>
                                                    </>
                                                )}
                                            </Dropzone>
                                        </div>
                                    </div>
                                    <span className="error-field-error">{imagesError}</span>
                                    {images.length > 0 ?
                                        <div className="box-im d-flex align-items-center mt-4">
                                            <div className="img-box-s mr-2">
                                                <img className="drop-image" src={`${storageUrl+ images[0]}`} alt="..." />
                                            </div>

                                            <div className="show-img-info">
                                                <h4 className="mb-0  f-size">{this.state.files_body_content.name ? this.state.files_body_content.name : images.length > 0 && images[0].split('/').pop()}</h4>
                                            </div>

                                        </div>
                                        : null}
                                </div>
                                <hr class="my-5" />
                            </div>
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={() => this.props.history.push('/assets')}>Cancel</button>
                                    <button className="btn btn-primary" onClick={this.handleclickAssets}>Craete</button>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
