import React, { Component } from 'react'
import HomeBannerModal from './HomeBannerModal'
import HomeFeatureBannerModal from './HomeFeatureBannerModal'
import HomeFeatureBannerTwoModal from './HomeFeatureBannerTwoModal'
import HomeTextBannerModal from './HomeTextBannerModal'
import HomeFeatureBannerModal2 from './HomeFeatureBannerModal2'
import HomeFeatureBannerModal4 from './HomeFeatureBannerModal4'
import HomeFeatureBannerModal5 from './HomeFeatureBannerModal5'
import HomeSocialBannerModal from './HomeSocialBannerModal'
import BannerLogin from '../../Assets/img/covers/back-side.jpg'
import { deleteAPI, getAPI, postAPI, postFile, putAPI } from '../../utils/api'
import { storageUrl } from '../../utils/BaseUrl'
// import Header from '../../Common/Header'

export default class Home extends Component {
  state = {
    isLoading: true,
    imageUploading: false,
    submitting: false,
    topBannerList: null,
    bannerList1: null,
    bannerList2: null,
    bannerList3: null,
    bannerList4: [{ image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null }],
    bannerList5: [{ image: '', title: 'New Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null }],
    socialBanner: [{
      image: '', title: 'Social Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null,
      facebook: '', instagram: '', twitter: '', youTube: '', pinterest: '', other: '',ravelry:'',blogspot:''
    }],
    textBanner: [{ image: '', title: 'Text Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null, country: "", name:"" }],
    productList: {
      title: '',
      list: [{ image: '', title: 'Text Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null }]
    },

    apiList: [
      { name: 'top_banner', state: 'topBannerList' },
      { name: 'feature_banner1', state: 'bannerList1' },
      { name: 'feature_banner2', state: 'bannerList2' },
      { name: 'feature_banner3', state: 'bannerList3' },
      { name: 'feature_banner4', state: 'bannerList4' },
      { name: 'feature_banner5', state: 'bannerList5' },
      { name: 'socialBanner', state: 'socialBanner' },
      { name: 'textBanner', state: 'textBanner' },
      { name: 'productList', state: 'productList' },
    ],
    selectedBanner: null,
    ogFields: {
      ogTitle: "",
      ogDescription: "",
      ogImage: "",
    },
    ogLoading: false
  }

  componentDidMount() {
    console.log(this.props.language)
    const errors = []
    getAPI(`template/homepageOgFields`).then(res => this.setState({ogFields: res.data.data.ogFields[this.props.language]}))
    this.state.apiList.map(item => {
      getAPI(`template/getTemplate?menuId=1&type=${item.name}&lang=${this.props.language}`)
        .then(res => {
          const { data, status } = res.data;
          data && (status === 1 ? this.setState({ [item.state]: data }) : errors.push(item.state))
        }).catch(err => { console.log(`error${item.name}:`, err) });

      return null
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.componentDidMount();
    }
  }

  textLimitter = (text) => {
    if (text.length > 20) {
      return text.substring(0, 20) + " ...";
    } else {
      return text
    }
  }

  productListOnChange = () => {
    const { productList } = this.state;

  }

  handleChange = (e) => {
    const { selectedBanner } = this.state;
    const newData = { ...selectedBanner, [e.target.name]: e.target.value }
    this.setState({ selectedBanner: newData })
  }

  createBanner = (name) => {
    const { bannerList2 } = this.state;
    const obj = {
      image: '',
      title: 'New Banner',
      sub: '',
      buttonText: '',
      buttonLink: '',
      file: null,
      country: "",
      name: ""
    }
    if (name !== 'bannerList2' || !bannerList2 || bannerList2.length < 4) {
      const newArr = this.state[name] ? this.state[name].concat(obj) : [obj];
      // console.log(newArr);
      this.setState({ [name]: newArr })
    }
  }

  editBanner = (e, obj) => {
    e.preventDefault();
    if (obj.id) {
      if (!obj.file) {
        obj.file = { name: obj.image }
      }
    }
    this.setState({ selectedBanner: obj })
  }

  deleteBanner = (e, obj, type, idx) => {
    e.preventDefault();

    if (obj.id) {
      const data = {
        "type": type,
        "menuId": "1",
        "templateDataId": obj.id
      }

      if (window.confirm(`Are you sure you want to delete ${obj.title}?`)) {

        deleteAPI('template/deleteTemplate', data).then(res => {
          // console.log(res);
          // this.componentDidMount();
          this.setState({ submitting: false, [type]: res.data.templateData }, () => this.componentDidMount());
        }).catch(error => {
          console.log(error)
        })
      }
    } else {
      const arr = this.state[type];
      arr.splice(idx, 1);
      this.setState({ [type]: arr })
      // alert('No need to delete as this banner has not been saved yet.')
    }
  }

  handleSave = (e, name, menuId) => {
    const { language } = this.props;
    const { selectedBanner } = this.state;
    const { image } = selectedBanner;

    if (image.length || name === 'textBanner') {
      this.setState({ submitting: true });
      let newObj = {
        ...selectedBanner,
        buttonLink:{[language]:selectedBanner.buttonLink},
        image:{[language]:selectedBanner.image},
        title: { [language]: selectedBanner.title },
        sub: { [language]: selectedBanner.sub },
        buttonText: { [language]: selectedBanner.buttonText },
        country: { [language]: selectedBanner.country },
        name: { [language]: selectedBanner.name },
      }
      // newObj.image = 'testImageRename';
      // delete newObj.file;

      if (newObj.id) {
        const data = {
          "type": name,
          "menuId": menuId,
          "templateData": newObj
        }
        console.log(data)
        putAPI('template/editTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({ submitting: false });
        }).catch(error => {
          console.log(error)
        })
      } else {
        const data = {
          "templateType": name,
          "menuId": menuId,
          "templateData": newObj
        }
        postAPI('template/addTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({ submitting: false });
        }).catch(error => {
          console.log(error)
        })
      }

    } else {
      alert('Image also needed.')
    }

    // this.setState({selectedBanner: null})
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      alert(`Only one file is allowed. You tried ${acceptedFiles.length} files.`)
    } else if (acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png') {
      this.setState({ imageUploading: true })
      postFile('upload/upload/homeBanner', acceptedFiles[0])
        .then(res => {
          console.log(res.data.data[0].s3key);
          this.setState({
            selectedBanner: {
              ...this.state.selectedBanner, image: storageUrl + res.data.data[0].s3key,
              file: { name: acceptedFiles[0].name }
            }, imageUploading: false
          });
        }).catch(err => console.log(err))
    } else {
      alert('Only JPEG and PNG file accepted.')
    }
  }

  handleOgDataChange = e => {
    // console.log(e.target.getAttribute('name'), e.target.value)
    this.setState({
        ogFields:{
            ...this.state.ogFields,
            [e.target.name] : e.target.value
            
        }
    })
  }

  saveHomeOgTag = () => {
    this.setState({ogLoading: true})
    const ogFields = { ogFields: this.state.ogFields }
    putAPI(`template/homepageOgFields?lang=${this.props.language}`, ogFields).then(res => {
      this.setState({ogLoading: false})
    }).catch(error => {
      console.log(error)
    })
  }

  render() {
    const { selectedBanner, topBannerList, bannerList1, bannerList2, bannerList4, bannerList5, socialBanner, textBanner,
      productList, imageUploading, submitting, ogFields, ogLoading } = this.state;
    const homeProps = {
      selectedBanner, handleChange: this.handleChange, handleSave: this.handleSave, onDrop: this.onDrop,
      textLimitter: this.textLimitter, submitting, imageUploading
    }

    return (
      <React.Fragment>
        <div className="main-section mt-5">
          <div className="container-fluid">
            <div className="row">
            <div className="spac w-100" />
              {/* <Header /> */}

              {/* OG tags fields */}
              <div className="col-12">
              <div className="d-flex">
                  <h2 className="header-title">Homepage Og Tags</h2>
                  <button className="btn btn-primary ml-auto btn-sm" onClick={() => this.saveHomeOgTag()}>
                    {ogLoading? "...Saving" :"Save tags"}
                  </button>
                </div>
                <div className="spac w-100" />
                  <div className="form-group">
                      <label>OG Title</label>
                      <input type="text" 
                          className="form-control"
                          placeholder="OG Title..."
                          required="required"
                          name="ogTitle"
                          value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                          onChange={e => this.handleOgDataChange(e)}
                      />
                  </div>
              </div>
              <div className="col-12">
                  <div className="form-group">
                      <label>OG Description</label>
                      <input type="text" 
                          className="form-control"
                          placeholder="OG Description..."
                          required="required"
                          name="ogDescription"
                          value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                          onChange={e => this.handleOgDataChange(e)}
                      />
                  </div>
              </div>
              <div className="col-12">
                  <div className="form-group">
                      <label>OG Image</label>
                      <input type="text" 
                          className="form-control"
                          placeholder="OG Image..."
                          required="required"
                          name="ogImage"
                          value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                          onChange={(e) => this.handleOgDataChange(e)}
                      />
                  </div>
                  <hr className="my-5"/>
              </div>


              <div className="col-sm-12">
                <div className="d-flex">
                  <h2 className="header-title">Top Banner</h2>
                  <button
                    className="btn btn-primary ml-auto btn-sm"
                    onClick={() => this.createBanner('topBannerList')}
                  >
                    Create Banner
                  </button>
                </div>
              </div>
              <div className="spac w-100" />
              {!topBannerList ? '' :
                topBannerList.map((item, idx) => (
                  <div className="col-sm-12 col-md-3" key={idx}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <a href="/" onClick={(e) => this.editBanner(e, item)} className="dropdown-item" data-toggle="modal" data-target="#cr_banner">
                                    Edit
                              </a>
                                  <a href="/" onClick={(e) => this.deleteBanner(e, item, 'top_banner', idx)} className="dropdown-item">
                                    Delete
                              </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <hr className="mt-4 mb-5" />
          {/* Feature Start */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h2 className="header-title">Feature Banner 1</h2>
                  <button className="btn btn-primary ml-auto btn-sm" onClick={() => this.createBanner('bannerList1')} >
                    Create Feature
                  </button>
                </div>
              </div>
              <div className="spac w-100" />
              {!bannerList1 ? '' :
                bannerList1.map((item, idx) => (
                  <div className="col-sm-12 col-md-3" key={idx}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <a href="/" onClick={(e) => this.editBanner(e, item)} className="dropdown-item" data-toggle="modal" data-target="#home_feature">
                                    Edit
                              </a>
                                  <a href="/" onClick={(e) => this.deleteBanner(e, item, 'feature_banner1', idx)} className="dropdown-item">
                                    Delete
                              </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Feature End */}
          <hr className="mt-4 mb-5" />
          {/* Feature 2 Start */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h2 className="header-title">Feature Banner 2</h2>
                  {(!bannerList2 || bannerList2.length < 4) && <button
                    className="btn ml-auto btn-primary btn-sm"
                    onClick={() => this.createBanner('bannerList2')}
                  >
                    Create Feature
                  </button>}
                </div>
              </div>
              <div className="spac w-100" />
              {!bannerList2 ? '' :
                bannerList2.map((item, idx) => (
                  <div className="col-sm-12 col-md-3" key={idx}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <a href="/" onClick={(e) => this.editBanner(e, item)} className="dropdown-item" data-toggle="modal" data-target="#home_feature2">
                                    Edit
                            </a>
                                  {/* <a href="/" onClick={(e) => this.deleteBanner(e, item, 'feature_banner2')} className="dropdown-item">
                              Delete
                            </a> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Feature 2 End */}
          <hr className="mt-4 mb-5" />
          {/* Product List 1 Start */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h2 className="header-title">Product List 1</h2>
                </div>
              </div>
              <div className="spac w-100" />
              <div className="col-sm-12 col-md-4">
                <div className="form-group">
                  <label>Title</label>
                  <input
                    type="text"
                    className="form-control focus-out"
                    placeholder="Feature Bestsellers"
                    name='title'
                    value={productList[0]}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="product-card-box border box-height position-relative w-100 alpha-back" style={{ background: `url(${BannerLogin})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                  <div className="wrapp-select center-select">
                    <div className="form-group mb-0 px-3">
                      <label htmlFor="exampleFormControlSelect1">
                        Select Product List
                      </label>
                      <select
                        className="form-control focus-out"
                        id="exampleFormControlSelect1"
                      >
                        <option>Needles</option>
                        <option>Needles 1</option>
                        <option>Needles 2</option>
                        <option>Needles 3</option>
                        <option>Needles 4</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Product List 1 End  */}
          <hr className="mt-4 mb-5" />
          {/* Feature 4 5 Start */}
          <div className="container-fluid">
            <div className="row">
              {!bannerList4 ? '' :
                bannerList4.map((item, idx) => (
                  <div className="col-sm-12 col-md-6" key={idx}>
                    <h2 className="header-title">Feature Banner 4</h2>
                    <div className="spac w-100" />
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item c-pointer" data-toggle="modal" data-target="#home_feature4">
                                    Edit
                            </span>
                                  {/* <span className="dropdown-item c-pointer">
                              Delete
                            </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {!bannerList5 ? '' :
                bannerList5.map((item, idx) => (
                  <div className="col-sm-12 col-md-6" key={idx}>
                    <h2 className="header-title">Feature Banner 5</h2>
                    <div className="spac w-100" />
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item c-pointer" data-toggle="modal" data-target="#home_feature5">
                                    Edit
                            </span>
                                  {/* <span className="dropdown-item c-pointer">
                              Delete
                            </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Feature 4 5 End */}


          <hr className="mt-4 mb-5" />
          {/* Social Banner Start */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="header-title">Social Banner</h2>
              </div>
              <div className="spac w-100" />
              {!socialBanner ? '' :
                socialBanner.map((item, idx) => (
                  <div className="col-sm-12 col-md-4" key={idx}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-12 col-md-12 d-flex align-items-center">
                            <div className="left-side">
                              <span className="avatar avatar-4by3">
                                <img src={item.image}
                                  alt="knitpro"
                                  className="avatar-img rounded"
                                />
                              </span>
                            </div>
                            <div className="middle-side ml-3">
                              <span className="h2 mb-0">
                                <h4 className="mb-1">{item.title}</h4>
                              </span>
                              <p className="card-text small text-muted">
                                <time dateTime="2018-05-24">{item.sub}</time>
                              </p>
                            </div>
                            <div className="right-side ml-auto">
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                  <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item c-pointer" data-toggle="modal" data-target="#home_social">
                                    Edit
                              </span>
                                  {/* <span className="dropdown-item c-pointer">
                                Delete
                              </span> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          {/* Feature 4 5 6 End */}
          <hr className="mt-4 mb-5" />
          {/* Text banner Start */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h2 className="header-title">Text banner</h2>
                  <button
                    className="btn btn-primary ml-auto btn-sm"
                    onClick={() => this.createBanner('textBanner')}
                  >
                    Create Text Banner
                  </button>
                </div>
              </div>
              <div className="spac w-100" />
              {!textBanner ? '' :
                textBanner.map((item, idx) => (
                  <div className="col-sm-12 col-md-4" key={idx}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-sm-12">
                            <span className="h2 mb-0">
                              <h4 className="mb-1">{item.name}</h4>
                            </span>
                          </div>
                          <div className="col">
                            <p className="card-text small text-muted">{item.sub}
                            </p>
                          </div>
                          <div className="col">
                            <p className="card-text small">{item?.country}
                            </p>
                          </div>
                          <div className="col-auto">
                            <div className="dropdown">
                              <a
                                href="#"
                                className="dropdown-ellipses dropdown-toggle"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i className="fe fe-more-vertical" />
                              </a>
                              <div className="dropdown-menu dropdown-menu-right" style={{}}>
                                <a href='/' onClick={(e) => this.editBanner(e, item)} className="dropdown-item" data-toggle="modal" data-target="#txtbanner">
                                  Edit
                                </a>
                                <a onClick={(e) => this.deleteBanner(e, item, 'textBanner', idx)} className="dropdown-item">
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
            <div className="container-fluid">
              <div className="d-block text-white text-center">
                <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
              </div>
            </div>
          </div>
          {/* Text banner End */}

          {/* Modal Section */}

          {/* Banner Modal Start */}
          <HomeBannerModal {...homeProps} />
          {/* Banner Modal End */}

          {/* Feature  Modal Start */}
          <HomeFeatureBannerModal {...homeProps} />
          {/* Feature  Modal End */}

          {/* Feature 2  Modal Start */}
          <HomeFeatureBannerModal2 {...homeProps} />
          <HomeFeatureBannerTwoModal />
          {/* Feature 2 Modal End */}

          {/* Text Banner 2 Modal Start */}
          <HomeTextBannerModal {...homeProps} />
          {/* Text Banner 2 Modal End */}
          <HomeFeatureBannerModal4 {...homeProps} />
          <HomeFeatureBannerModal5 {...homeProps} />
          <HomeSocialBannerModal {...homeProps} />
          {/* Modal Section End */}
        </div>
      </React.Fragment>
    )

  }
}