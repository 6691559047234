import React, { Component } from 'react';
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import {getAPI} from '../utils/api';
import moment from 'moment';
import CsvDownloader from 'react-csv-downloader';
import Modal from "react-modal";

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      width                  : '500px',
      transform             : 'translate(-50%, -50%)'
    }
  };
export default class NewsletterUsers extends Component {

    state={
        from:'',
        to:'',
        languages:[],
        selectedLanguage:null,
        options:[],
        disabled:true,
        users:[],
        show:true,
        isRequired:false,
        errors:{
            from:'',
            to:'',
            language:''
        },
        showModal:false,
        closeModal:true
    }

    componentDidMount(){
        this.getLanguages();
    }
    getLanguages=()=>{
        getAPI('language/language?lang=en')
            .then(res => {
                const {data}=res.data;
                const optionArray = data.map(d => {
                    let a = {};
                    a['label'] = d.name;
                    a['value'] = d.uid;
                    a['shortName']=d.shortName;
                    return a;
                })
                this.setState({ options: optionArray });
                // console.log(optionArray)
            }).catch(err => console.log('lang error: ', err.response))
    }
  
    handleChange = selectedOption => {
        let errors=this.state.errors;
        errors.language=selectedOption?'':'Field is Required!';
        this.setState({ selectedLanguage:selectedOption,errors });
        console.log(`Option selected:`, selectedOption);
    };
    handleDownload=()=>{
        const {from,to,selectedLanguage,errors}=this.state;
        console.log(from,to,selectedLanguage);
        if(from.length===0|| to.length===0|| !selectedLanguage){
            if(from.length===0){
                errors.from = 'Field is Required*'
                this.setState({errors,isRequired:true})
            }
            if(to.length===0){
                errors.to = 'Field is Required*'
                this.setState({errors,isRequired:true})
            } 
            if(!selectedLanguage){
                errors.language = 'Please Select Language*'
                this.setState({errors,isRequired:true})
            } 
        }else{
            let fromDate=moment(from[0]).format("YYYY-MM-DD");
            let toDate=moment(to[0]).format("YYYY-MM-DD");
            //  this.setState({show:false})
        getAPI(`newsletter/subscribedUsers?lang=${selectedLanguage.shortName}&from=${fromDate}&to=${toDate}`).then(res=>{
            const {status,data}=res.data;
            if(status===1){
                this.setState({users:data,show:false});
                // console.log(data);
                
            }else{
                this.setState({users:[],showModal:true,closeModal:false,show:true})
            }
        }).catch(err=>{
            console.log(err);
        })
    }
    }
    handleCSV=()=>{
        this.setState({show:true})
    }
    handleDate=(date,str)=>{
        let {errors}=this.state;
        if(str==='from'){
            errors.from=date[0].length<=0?'Field is Required!':''
            this.setState({from:date,errors})
        }else{
            errors.to=date[0].length<=0?'Field is Required!':''
            this.setState({to:date,errors})
        }

    }
    handleModal=()=>{
        this.setState({
            showModal:false,closeModal:true
        })
    }
    render() {
        const { options,selectedLanguage,errors,isRequired ,showModal,closeModal,show} = this.state;
        return (
            <div className="main-section">
                <div className="header">
                    <div className="container-fluid">
                        <div className="header-body">
                            <div className="row align-items-end">
                                <div className="col">
                                    <h6 className="header-pretitle"> Newsletter</h6>
                                    <h1 className="header-title">Users</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>From</label>
                                <Flatpickr className="form-control"
                                    onChange={date => this.handleDate(date,'from')}
                                />
                                {isRequired && errors.from.length > 0 ?
                                            <span className="error-field-error">{errors.from}</span> : ''}
                            </div>

                        </div>
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label>To</label>
                                <Flatpickr className="form-control"
                                   onChange={date => this.handleDate(date,'to')}
                                />
                                {isRequired && errors.to.length > 0 ?
                                            <span className="error-field-error">{errors.to}</span> : ''}
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Language</label>
                                <Select
                                    onChange={this.handleChange}
                                    options={options}
                                    value={selectedLanguage}
                                />
                                {isRequired && errors.language.length > 0 ?
                                            <span className="error-field-error">{errors.language}</span> : ''}
                            </div>
                            <hr className="my-5" />
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-6 text-center">
                            <div className="form-group">
                            <button className="btn btn-primary " onClick={e=>this.handleDownload()}>Submit</button>
                            </div>

                        </div>
                        <div className="col-sm-6 text-center">
                            <div className="form-group">
                            <CsvDownloader
                            filename="Users.csv"
                            // separator=";"
                            // wrapColumnChar="'"
                            // columns={columns}
                            datas={this.state.users && this.state.users}
                            text="DOWNLOAD .csv" 
                            >
                               
                                <button className={`btn ${show?`btn-outline-info ` :`btn-info` }`} onClick={e=>this.handleCSV()} disabled={this.state.show}>Download .CSV</button>
                         </CsvDownloader> 
                            </div>

                        </div>
                       
                    </div>
                </div>
                <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                    <div className="container-fluid">
                        <div className="d-block text-white text-center">
                            <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span>
                            <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
                     <Modal
                            isOpen={showModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="My dialog"
                        >
                            <div className="form-group text-center">No Users Found</div>
                            <div className="text-center">
                            <button className="btn btn-primary text-center" onClick={e=>this.handleModal()}>Ok</button>
                            </div>
                            
                        </Modal>
            </div>
            
        )
    }
    
}