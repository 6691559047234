import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import {postAPI,postMultipleFile,putAPI} from '../utils/api';
import {storageUrl} from '../utils/BaseUrl';
import UploadComponent from '../Common/UploadComponent';


export default class AddgroupList extends Component {
    constructor(){
        super();
        this.state = {
            name:'',
            images:[],
            order:'',
            color:'',
            code:'',
            islink:'',
            link:"",
            imagesUploading:false,
            files_Body  : null,
            files_body_content:[],
            files_Movement_content:[],
            files_Performace_content:[],
            files_Movement : null,
            files_Performace : null,
            err_image_msg:'',
            err_image_msgg:'',
            err_image_msggg:'',
            errors:{
                name:'',
                order:''
            },
            isRequired:false
        }
    }
    componentDidMount(){
         const {status,data}=this.props.location.state;
        if(status===1){
            this.setState({
                name:data.size,
                images:data.images,
                order:data.order,
                color:data.color,
                code:data.code
            })
        }
    }
    handleChange(e){
        const {errors}=this.state;
        const {name,value}=e.target;
        switch (name) {
      case 'name': 
        errors.name = 
          value.length <=0
            ? 'Field is Required!'
            : '';
        break;
      case 'order': 
        errors.order = 
            value.length<=0
            ? 'Field is Required!'
            : '';
        break;
      default:
        break;
    }
        this.setState({errors,[e.target.name]:e.target.value});
    }
      
    handleUploadImageAndLink=(acceptedFiles,state)=>{
        const imagesArray=this.state.images;
        if(state==='image'){
            postMultipleFile('upload/upload/productImages',acceptedFiles).then(res=>{
                const {status,data}=res.data;
                if(status===1){
                    for (let i = 0; i < data.length; i++) {
                        let obj={
                            type:0,
                            value:data[i].s3key,
                            order:imagesArray.length
                        };
                        imagesArray.push(obj);
                    }
                    // console.log(imagesArray);
                    this.setState({images:imagesArray})
                }else{
                    alert('something went wrong');
                }
                
            }).catch(err=>{
                console.log(err);
            })
        }
        else{
            let link = this.state.link;
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            var match = link.match(regExp);
            let video_id='';
            if(match&&match[7].length==11){
                video_id=match[7];
            }
            let obj={
                type:1,
                order:imagesArray.length,
                value:link,
                video_id:video_id
            }
            //console.log(video_id);
            imagesArray.push(obj);
            // console.log(imagesArray);
            this.setState({link:'',images:imagesArray,islink:false});
            
        }
    }
    handleLinkInput = () => {
        this.setState({ islink: !this.state.islink,link:''});
    }
    removeImage=(e,value)=>{
        let imagesArray=this.state.images;
        let newArray=[];
        for(let i=0;i<imagesArray.length;i++){
            if(imagesArray[i].value!==value){
                imagesArray[i].order=newArray.length;
                newArray.push(imagesArray[i]);
            }
        }
        console.log(newArray);
        this.setState({images:newArray});
    }
    handleCancel=()=>{
        const {status,uid}=this.props.location.state;
        if(status===0){
        this.props.history.push({
            pathname: '/sizeList',
            state:{status:0,data:{uid:uid}}
        })
    }
        else{
            this.props.history.push({
            pathname: '/sizeList',
            state:{status:0,data:{uid:this.props.location.state.data.productGroupUid}}
        })
        }
    }
    handleSubmit=()=>{
       const {status,uid}=this.props.location.state;
        const data={
            size:this.state.name,
            images:this.state.images,
            productGroupUid:uid,
            color:this.state.color,
            code:this.state.code,
            order:this.state.order
        }
        const {errors}=this.state;
        if(data.size.length===0||data.order.length===0){
            if(data.size.length===0&&data.order.length===0){
                errors.name='Field is Required!';
                errors.order='Field is Required!';
                this.setState({errors,isRequired:true})
            }
            else{
                if(data.size.length===0){
                    errors.name='Field is Required!';
                    this.setState({isRequired:true,errors});
                }
                if(data.order.length===0){
                    errors.order='Field is Required!';
                    this.setState({isRequired:true,errors});
                }
            }
        }
        else if(status===0){
            postAPI('productSku/productSku',data).then(res=>{
                const {status}=res.data;
                if(status===1){
                    this.props.history.push({
                    pathname: '/sizeList',
                    state:{status:0,data:{uid:uid}}
                    })
                }
            }).catch(err=>{
                console.log(err);
            })
        }
        else{
            const url='productSku/productSku/'+this.props.location.state.data.uid;
            putAPI(url,data).then(res=>{
                const {status}=res.data;
                if(status===1){
                   this.props.history.push({
                    pathname: '/sizeList',
                    state:{status:0,data:{uid:this.props.location.state.data.productGroupUid}}
                })
                }else{
                    alert('group not edited')
                }
            }).catch(err=>{
                console.log(err);
            })
        }
    }
    render() {
       
        // const {status}=this.props.location.state;
         const {errors,isRequired,images,islink,link,imagesUploading}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Create Sku </h1>
                                    </div>
                                   <div className="col-auto">
                                       {/* <a href="#!" className="btn btn-primary lift">Create Type </a> */}
                                       <div className="d-flex align-items-center">
                                        <span className="mr-2">Sort Order:</span>
                                        <input type="text" className="form-control width-auto" name="order" value={this.state.order} onChange={e=>{this.handleChange(e)}}/>
                                        </div>
                                        {isRequired && errors.order.length > 0?
                                <span  className="error-field-error">{errors.order}</span>:''}
                                        
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input type="text" name="name" value={this.state.name} className="form-control" onChange={(e)=>this.handleChange(e)}/>
                                    {isRequired && errors.name.length > 0?
                                <span  className="error-field-error">{errors.name}</span>:''}
                                </div>
                               
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Code</label>
                                    <input type="text" name="code" value={this.state.code} className="form-control" onChange={(e)=>this.handleChange(e)}/>
                                    
                                </div>
                               
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Color</label>
                                    <input type="text" name="color" value={this.state.color} className="form-control" onChange={(e)=>this.handleChange(e)}/>
                                    
                                </div>
                               
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <label>Images</label>

                                <div className="upload-img-box  w-100 position-relative over-hidden f-out dz-preview-multiple rem_border">
                                    <UploadComponent imageUploading={imagesUploading} onDrop={files => this.handleUploadImageAndLink(files,'image')}/>
                                    <div className="upload_link section text-center">
                                        <h2 className="color_text">or</h2>
                                        <button className="btn_cs" onClick={this.handleLinkInput}>upload link</button>
                                        {islink?<div className="link_box d-flex justify-content-center mb-4">
                                            <input type="text" className="link_text d-block" name="link" value={this.state.link} onChange={(e)=>this.handleChange(e)} placeholder="url.."/>
                                            <button type="button" className="btn_submit" onClick={e=>this.handleUploadImageAndLink(e,'link')}>add</button>
                                        </div>:''}
                                    </div>
                                </div>
                                {images.length ?
                                    <div className="box-im d-flex align-items-center mt-4">
                                        {
                                            images.map((item, idx) => (
                                                <div className="img-box-s mr-2 position-relative in_box_size" key={idx}>
                                                    {item.type===0?<img className="drop-image" src={`${storageUrl+ item.value}`} />:<img className="drop-image" src={`https://img.youtube.com/vi/${item.video_id}/hqdefault.jpg`} />}
                                                    <button type="button" className="btn_cr" onClick={e=>this.removeImage(e,item.value)}><span className="d-block">X</span></button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    : null
                                }


                                <hr className="my-5" />
                            </div>
                            <div className="col-12 text-center">
                                <div className="form-group ">
                                    <button className="btn btn-outline-primary mr-3" onClick={this.handleCancel}>Cancel</button>
                                    <button className="btn btn-primary" disabled={this.state.imagesUploading} onClick={this.handleSubmit}>Create Sku</button>
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}