import React, { Component } from 'react'
import {postAPI,putAPI} from '../utils/api';



export default class CreateCategories extends Component {
    constructor(){
        super();
        this.state={name:"",slug:"",seoTitle:"",seoMeta:"",seoDesc:"",seoKeywords:"",order:'',errors:{name:'',slug:'',slugexist:'',order:''},isValid:false,isAlready:false};
    }

    componentDidMount() {
        if(this.props.location.state.status===1){
        this.setState({
            name: this.props.location.state.data.name,
            slug: this.props.location.state.data.slug,
            seoTitle:this.props.location.state.data.seoFields?.seoTitle,
            seoMeta:this.props.location.state.data.seoFields?.seoMeta,
            seoDesc:this.props.location.state.data.seoFields?.seoDesc,
            seoKeywords:this.props.location.state.data.seoFields?.seoKeywords,
            order:this.props.location.state.data.order
        })
      }
    }

    routeChange=()=> {
        let path = `/categories`;
        this.props.history.push(path);
    }
   
    handleChange=(e)=>{
        let errors=this.state.errors;
        let {name,value}=e.target;
         
        switch (name) {
      case 'name': 
        errors.name = 
          value.length <=0
            ? 'Field is Required!'
            : '';
        this.setState({slug:e.target.value.replace(/\s+/g, '-').toLowerCase()});    
        break;
      case 'slug': 
        errors.slug = 
            value.length<=0
            ? 'Field is Required!'
            : '';
        break;
      case 'order':
          errors.order=
            value.length<=0
            ? 'Field is Required!'
            : ''; 
      default:
        break;
    }

        this.setState({errors,[e.target.name]: e.target.value});
    }
    handleClick=(e)=>{
        const {status}=this.props.location.state;
        const categoryName=this.state.name;
        const order=this.state.order;
        const languageUid=this.props.langId;
        const slug =this.state.slug;
        const errors=this.state.errors;
        if(categoryName.length===0||slug.length===0||order.length===0){
            if(categoryName.length<=0&&slug.length<=0&&order.length<=0){
                errors.name="Field is Required!";
                errors.slug="Field is Required!";
                errors.order="Field is Required!"
                this.setState({errors,isValid:true});
            }else if(categoryName.length<=0){
                errors.name='Field is Required!'
                this.setState({errors,isValid:true});
            }
            else if(order.length<=0){
                errors.order='Field is Required!'
                this.setState({errors,isValid:true});                

            }
                else{
                errors.slug='Field is Required!'
                this.setState({errors,isValid:true});
            }
        }
        else if(status===0){
            const seoFields={
                seoTitle:this.state.seoTitle,
                seoMeta:this.state.seoMeta,
                seoDesc:this.state.seoDesc,
                seoKeywords:this.state.seoKeywords
            }
            const data={
                "name":categoryName,
                "languageUid":languageUid,
                "slug":slug,
                "seoFields":seoFields,
                "order":order
            }
            postAPI('category/category',data).then(res=>{
                if(res.data.status===1){
                    this.props.history.push({
                        pathname: '/categories'
                    })
                  
                }else if(res.data.status===2){
                    errors.slugexist='Slug Already Exists!'
                    this.setState({isAlready:true,errors});
                    this.setErrorTimeout();
                }else{
                    alert('Category not created')
                }
               
            }).catch(err=>{
                console.log(err);
            })
           
       
    }
    else{
        const catId=this.props.location.state.data.uid;
        const seoFields={
            seoTitle:this.state.seoTitle,
            seoMeta:this.state.seoMeta,
            seoDesc:this.state.seoDesc,
            seoKeywords:this.state.seoKeywords
        }
        const data={
            name:categoryName,
            slug:slug,
            seoFields:seoFields,
            order:order
        }
        const url='category/category/'+catId
        putAPI(url,data).then(res=>{
            if(res.data.status===1){
                this.props.history.push({
                    pathname: '/categories',
                    //prevState: res.data.data 
                })  
            }else if(res.data.status===2){
                    errors.slugexist='Slug Already Exists!'
                    this.setState({isAlready:true,errors});
                    this.setErrorTimeout();
                }else{
                    alert('Category not created')
                }
        }).catch(err=>{

            console.log(err);
        })
    }
     
    }
    setErrorTimeout=()=>{
    setTimeout(()=>this.setState({isAlready:false}), 5000);

  };
    render() {
        const {status}=this.props.location.state;
        const {errors,isValid,isAlready}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title"> {status===1?'Edit Category':'Create Category'} </h1>
                                    </div>
                                    <div className="col-auto">
                                        {/* <a href="#!" className="btn btn-primary lift">Create Type </a> */}
                                        <div className="d-flex align-items-center">
                                            <span className="mr-2">Sort Order:</span>
                                            <input type="text" className="form-control width-auto" name="order" value={this.state.order} onChange={e => { this.handleChange(e) }} />
                                        </div>
                                        {isValid && errors.order.length > 0 ?
                                            <span className="error-field-error">{errors.order}</span> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        {isAlready && errors.slugexist.length>0?<div className="alert alert-danger text-center" role="alert">
                                          <div className="text-center">{errors.slugexist}</div>
                                        </div>:''}
                        <div className="row">
                            
                            <div className="col-12">
                                <div className="form-group">
                                 <label> Category Name</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="Name..."
                                 required="required"
                                 name="name"
                                 value={this.state.name}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 {isValid && errors.name.length > 0?
                                <span  className="error-field-error">{errors.name}</span>:''}
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Slug</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="slug..."
                                 required="required"
                                 name="slug"
                                 value={this.state.slug}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 {isValid && errors.slug.length > 0?
                                <span  className="error-field-error">{errors.slug}</span>:''}
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Title</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo title..."
                                 
                                 name="seoTitle"
                                 value={this.state.seoTitle}
                                 onChange={e => this.handleChange(e)}
                                 />
                                
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Meta </label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo meta..."
                                 
                                 name="seoMeta"
                                 value={this.state.seoMeta}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Description</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo description.."
                                 
                                 name="seoDesc"
                                 value={this.state.seoDesc}
                                 onChange={e => this.handleChange(e)}
                                 />
                                 
                                </div>
                                <hr className="my-5"/>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                 <label>Seo Keywords</label>
                                 <input type="text" 
                                 className="form-control"
                                 placeholder="seo keywords..."
                                 required="required"
                                 name="seoKeywords"
                                 value={this.state.seoKeywords}
                                 onChange={e => this.handleChange(e)}
                                 />
                                </div>
                                <hr className="my-5"/>
                            </div>
                          
                       
                            <div className="col-12 text-center">
                                <div className="form-group " >
                                <button className="btn btn-outline-primary mr-3" onClick={this.routeChange}>Cancel</button>
                                <button className="btn btn-primary "onClick={this.handleClick}>{status===1?'Save':'Create Category'}</button>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}