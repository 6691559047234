import React, {Component} from 'react'
import SocialBannerModal from './SocialBannerModal'
import SocialFeatureBannerModal from './SocialFeatureBannerModal'
import SocialFeatureBannerTwoModal from './SocialFeatureBannerTwoModal'
import SocialFeatureBannerModal2 from './SocialFeatureBannerModal2'
import SocialFeatureBannerModal3 from './SocialFeatureBannerModal3'
import SocialFeatureBannerModal4 from './SocialFeatureBannerModal4';
import { getAPI, postAPI, postFile, putAPI, deleteAPI } from '../../utils/api'
import { storageUrl } from '../../utils/BaseUrl';

import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/markdown/markdown';

export default class SocialContribution extends Component {
  state = {
    isLoading: true,
    submitting: false,
    
    textBanner1: [{image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null}],

    apiList: ['textBanner1'],

    selectedBanner: {title: 'code'},
    ogFields: {}
  }

  componentDidMount(){
    console.log(this.props.language)
    const errors = []
    this.state.apiList.map(item => {
      getAPI(`template/getTemplate?menuId=3&type=${item}&lang=${this.props.language}`)
        .then(res => {
          const {data, status} = res.data;
          data && (status === 1 
            ? this.setState({[item]: data, selectedBanner: data[0], isLoading: false, ogFields: (data[0].ogFields)[this.props.language]}) 
            : errors.push(item))
        }).catch(err => {console.log(`error${item}:`, err)});
      
      return null 
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.language !== prevProps.language) {
      this.setState({isLoading: true});
      this.componentDidMount();
    }
  }

  onCodeChange = (code) => {
    const {selectedBanner} = this.state;
    const newData = {...selectedBanner, title: code}
    this.setState({selectedBanner: newData});
  }

  handleSave = (e, name, menuId) => {
    const {language} = this.props;
    const {selectedBanner, ogFields} = this.state;
    const {image} = selectedBanner;
    
    if(image.length || name === 'textBanner1'){   
      this.setState({submitting: true}); 
      let newObj = {
        ...selectedBanner,
        title: {[language]: selectedBanner.title},
        sub: {[language]: ''},
      }
      
      if(newObj.id){
        const data = {
          "type": name,
          "menuId": menuId,
          "templateData": newObj,
          "ogFields": ogFields,
          "lang":this.props.language
        }
        console.log(data)
        putAPI('template/editTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({submitting: false});
        }).catch(error => {
          console.log(error)
        })
      } else {
        const data = {
          "templateType": name,
          "menuId": menuId,
          "templateData": newObj,
          "ogFields": ogFields,
          "lang":this.props.language
        }
        postAPI('template/addTemplate', data).then(res => {
          console.log(res);
          this.componentDidMount();
          this.setState({submitting: false});
        }).catch(error => {
          console.log(error)
        })
      }
      
    } else {
      alert('Image also needed.')
    }
    
    // this.setState({selectedBanner: null})
  }

  handleOgDataChange = e => {
    console.log(e.target.getAttribute('name'), e.target.value)
    this.setState({
        ogFields:{
            ...this.state.ogFields,
            [e.target.getAttribute('name')] : e.target.value
            
        }
    })
  }

  render(){
    const {selectedBanner, submitting, isLoading, ogFields} = this.state;
    const {title} = selectedBanner;
    const options = {
			lineNumbers: true,
		};
    // console.log(selectedBanner)

    return(isLoading ? '' : 
      <React.Fragment>
        <div className="main-section mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h1 className="header-title">Social Contribution</h1>
                </div>
              </div>
              <div className="spac w-100" />
              
              <div className="col-sm-12">
                <div className="form-group" style={{border: '1px solid rgba(0, 0, 0, 0.125)'}}>
                  <CodeMirror value={title} onChange={this.onCodeChange} options={options} />
                </div>
                {/* OG tags fields */}
                <div className="col-12">
                  <div className="spac w-100" />
                    <div className="form-group">
                        <label>OG Title</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Title..."
                            required="required"
                            name="ogTitle"
                            value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>OG Description</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Description..."
                            required="required"
                            name="ogDescription"
                            value={ogFields?.ogDescription ? ogFields?.ogDescription : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>OG Image</label>
                        <input type="text" 
                            className="form-control"
                            placeholder="OG Image..."
                            required="required"
                            name="ogImage"
                            value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                            onChange={e => this.handleOgDataChange(e)}
                        />
                    </div>
                    <hr className="my-5"/>
                </div>
                <div className='form-group'>
                  <button type="button" className="btn btn-primary mr-auto" disabled={submitting}
                    onClick={(e) => this.handleSave(e, 'textBanner1', 3)}
                  >
                    {submitting ? 'Saving...' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
	    )
	  }
  }
  
  class SocialContributionOld extends Component {
    state = {
      isLoading: true,
      imageUploading: false,
      submitting: false,
      topBanner: [{image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null}],
      textBanner1: [{image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null}],
      bannerList1: [
        {image: '', title: 'New Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null},
        {image: '', title: 'New Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null},
        {image: '', title: 'New Banner', sub: 'Subtitle ', buttonText: '', buttonLink: '', file: null}
      ],
      bannerList2: [
        {image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null},
        {image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null},
        {image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null},
      ],
      banner3: [{image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null}],
      banner4: [{image: '', title: 'New Banner', sub: 'Subtitle', buttonText: '', buttonLink: '', file: null}],
      
      apiList: [ 'topBanner', 'textBanner1', 'bannerList1', 'bannerList2', 'banner3', 'banner4'],
  
      selectedBanner: null
    }
  
    componentDidMount(){
      console.log(this.props.language)
      const errors = []
      this.state.apiList.map(item => {
        getAPI(`template/getTemplate?menuId=3&type=${item}&lang=${this.props.language}`)
          .then(res => {
            const {data, status} = res.data;
            data && (status === 1 ? this.setState({[item]: data}) : errors.push(item))
          }).catch(err => {console.log(`error${item}:`, err)});
        
        return null 
      })
    }
  
    componentDidUpdate(prevProps) {
      if (this.props.language !== prevProps.language) {
        this.componentDidMount();
      }
    }
  
    textLimitter = (text) => {
      if (text.length > 20) {
        return text.substring(0, 20) + " ...";
      } else {
        return text
      }
    } 
  
    handleChange = (e) => {
      const {selectedBanner} = this.state;
      const newData = {...selectedBanner, [e.target.name]: e.target.value}
      this.setState({selectedBanner: newData})
    }
  
    createBanner = (name) => {
      const {bannerList2} = this.state;
      const obj = {
        image: '',
        title: 'New Banner',
        sub: '',
        buttonText: '',
        buttonLink: '',
        file: null
      }
      if(name !== 'bannerList2' || !bannerList2 || bannerList2.length < 4){
        const newArr = this.state[name] ? this.state[name].concat(obj) : [obj];
        // console.log(newArr);
        this.setState({[name]: newArr})
      }
    }
  
    editBanner = (e, obj) => {
      e.preventDefault();
      if(obj.id){
        if(!obj.file){
          obj.file = {name: obj.image}
        }
      }
      this.setState({selectedBanner: obj})
    }
  
    deleteBanner = (e, obj, type, idx) => {
      e.preventDefault();
      
      if(obj.id){
          const data = {
          "type": type,
          "menuId":"3",
          "templateDataId": obj.id
        }
  
        if(window.confirm(`Are you sure you want to delete ${obj.title}?`)){
          
          deleteAPI('template/deleteTemplate', data).then(res => {
            // console.log(res);
            this.componentDidMount();
            this.setState({submitting: false});
          }).catch(error => {
            console.log(error)
          })
        }    
      } else {
        const arr = this.state[type];
        arr.splice(idx, 1);
        this.setState({[type]: arr})
        // alert('No need to delete as this banner has not been saved yet.')
      }
    }
  
    handleSave = (e, name, menuId) => {
      const {language} = this.props;
      const {selectedBanner} = this.state;
      const {image} = selectedBanner;
      
      if(image.length || name === 'textBanner1'){   
        this.setState({submitting: true}); 
        let newObj = {
          ...selectedBanner,
          title: {[language]: selectedBanner.title},
          sub: {[language]: selectedBanner.sub},
          buttonText: {[language]: selectedBanner.buttonText},
        }
        // newObj.image = 'testImageRename';
        // delete newObj.file;
        
        if(newObj.id){
          const data = {
            "type": name,
            "menuId": menuId,
            "templateData": newObj
          }
          console.log(data)
          putAPI('template/editTemplate', data).then(res => {
            console.log(res);
            this.componentDidMount();
            this.setState({submitting: false});
          }).catch(error => {
            console.log(error)
          })
        } else {
          const data = {
            "templateType": name,
            "menuId": menuId,
            "templateData": newObj
          }
          postAPI('template/addTemplate', data).then(res => {
            console.log(res);
            this.componentDidMount();
            this.setState({submitting: false});
          }).catch(error => {
            console.log(error)
          })
        }
        
      } else {
        alert('Image also needed.')
      }
      
      // this.setState({selectedBanner: null})
    }
  
    onDrop = (acceptedFiles) => {    
      if(acceptedFiles.length > 1){
        alert(`Only one file is allowed. You tried ${acceptedFiles.length} files.`)
      } else if(acceptedFiles[0].type === 'image/jpeg' || acceptedFiles[0].type === 'image/png') {
        this.setState({imageUploading: true})
        postFile('upload/upload/testImage', acceptedFiles[0])
        .then(res => {
          console.log(res.data.data[0].s3key);
          this.setState({
            selectedBanner: {...this.state.selectedBanner, image: storageUrl + res.data.data[0].s3key, 
              file: {name: acceptedFiles[0].name}}, imageUploading: false
          });
        }).catch(err => console.log(err)) 
      } else {
        alert('Only JPEG and PNG file accepted.')
      }
    }
  
    render(){
      const {selectedBanner, topBanner, textBanner1, bannerList1, bannerList2, banner3, banner4,  
        imageUploading, submitting} = this.state;
      const socialProps = {
        selectedBanner, handleChange: this.handleChange, handleSave: this.handleSave, onDrop: this.onDrop, 
        submitting, imageUploading 
      }
      return(
                <React.Fragment>
                  <div className="main-section mt-5">
                    {/* Banner Top Start */}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="d-flex">
                            <h1 className="header-title">Top Banner</h1>
                            {/* <button
                              className="btn btn-md btn-white ml-auto"
                            >
                              Create New Banner
                            </button> */}
                          </div>
                        </div>
                        <div className="spac w-100" />
                        {!topBanner ? '' : 
                          topBanner.map((item, idx) => (
                        <div className="col-sm-12 col-md-3" key={idx}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 d-flex align-items-center">
                                    <div className="left-side">
                                    <span className="avatar avatar-4by3">
                                      <img src={item.image}
                                        alt="..."
                                        className="avatar-img rounded"
                                      />
                                    </span>
                                    </div>
                                    <div className="middle-side ml-3">
                                    <span className="h2 mb-0">
                                        <h4 className="mb-1">{item.title}</h4>
                                      </span>
                                      <p className="card-text small text-muted">
                                        <time dateTime="2018-05-24"></time>
                                      </p>
                                    </div>
                                    <div className="right-side ml-auto">
                                  <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item"  data-toggle="modal" data-target="#social_banner">
                                          Edit
                                        </span>
                                        {/* <span className="dropdown-item">
                                          Delete
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          ))}
                      </div>
                    </div>
                    {/* Banner Top End  */}
                    <hr className="mt-4 mb-5" />
                    {/* 1 Banner Start */}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="d-flex">
                            <h1 className="header-title">Feature Banner 1</h1>
                          </div>
                        </div>
                        <div className="spac w-100" />
                        {!textBanner1 ? '' : 
                          textBanner1.map((item, idx) => (
                        <div className="col-sm-12 col-md-4" key={idx}>
                          <div className="card">
                            <div className="card-body">
                              <div className="row align-items-center">
                                <div className="col-sm-12">
                                  <span className="h2 mb-0">
                                    <h4 className="mb-1">{item.title}</h4>
                                  </span>
                                </div>
                                <div className="col">
                                  <p className="card-text small text-muted">
                                    <time dateTime="2018-05-24">
                                      {item.sub}
                                    </time>
                                  </p>
                                </div>
                                <div className="col-auto">
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="dropdown-ellipses dropdown-toggle"
                                      role="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="fe fe-more-vertical" />
                                    </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item"  data-toggle="modal" data-target="#social_feature">
                                          Edit
                                        </span>
                                        {/* <span className="dropdown-item">
                                          Delete
                                        </span> */}
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                          ))}
                        <div className="col-sm-12 col-md-4"></div>
                        <div className="col-sm-12 col-md-4"></div>
                        {!bannerList1 ? '' : 
                          bannerList1.map((item, idx) => (
                        <div className="col-sm-12 col-md-3" key={idx}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 d-flex align-items-center">
                                    <div className="left-side">
                                    <span className="avatar avatar-4by3">
                                      <img src={item.image}
                                        alt="..."
                                        className="avatar-img rounded"
                                      />
                                    </span>
                                    </div>
                                    <div className="middle-side ml-3">
                                    <span className="h2 mb-0">
                                        <h4 className="mb-1">{item.title}</h4>
                                      </span>
                                      <p className="card-text small text-muted">
                                        <time dateTime="2018-05-24">{item.sub}</time>
                                      </p>
                                    </div>
                                    <div className="right-side ml-auto">
                                  <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item"  data-toggle="modal" data-target="#social_subfeature">
                                          Edit
                                        </span>
                                        {/* <span className="dropdown-item">
                                          Delete
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          ))}
                        
                      
                      </div>
                    </div>
                    {/* Feature 1 Banner End */}
                    <hr className="mt-4 mb-5" />
                    {/* Feature 2 Banner Start */}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="d-flex">
                            <h1 className="header-title">Feature Banner 2</h1>
                          </div>
                        </div>
                        <div className="spac w-100" />
                        {!bannerList2 ? '' : 
                          bannerList2.map((item, idx) => (
                        <div className="col-sm-12 col-md-3" key={idx}>
                        <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 d-flex align-items-center">
                                    <div className="left-side">
                                    <span className="avatar avatar-4by3">
                                      <img src={item.image}
                                        alt="..."
                                        className="avatar-img rounded"
                                      />
                                    </span>
                                    </div>
                                    <div className="middle-side ml-3">
                                    <span className="h2 mb-0">
                                        <h4 className="mb-1">{item.title}</h4>
                                      </span>
                                      <p className="card-text small text-muted">
                                        <time dateTime="2018-05-24">{item.sub}</time>
                                      </p>
                                    </div>
                                    <div className="right-side ml-auto">
                                  <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item"  data-toggle="modal" data-target="#social_feature2">
                                          Edit
                                        </span>
                                        {/* <span className="dropdown-item">
                                          Delete
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          ))}
                        
                      
                      </div>
                    </div>
                    {/* Feature 2 Banner End */}
                    <hr className="mt-4 mb-5" />
                    {/* Feature 3 Banner Start */}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="d-flex">
                            <h1 className="header-title">Feature Banner 3</h1>
                          </div>
                        </div>
                        <div className="spac w-100" />
                        {!banner3 ? '' : 
                          banner3.map((item, idx) => (
                        <div className="col-sm-12 col-md-3" key={idx}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 d-flex align-items-center">
                                    <div className="left-side">
                                    <span className="avatar avatar-4by3">
                                      <img src={item.image}
                                        alt="..."
                                        className="avatar-img rounded"
                                      />
                                    </span>
                                    </div>
                                    <div className="middle-side ml-3">
                                    <span className="h2 mb-0">
                                        <h4 className="mb-1">{item.title}</h4>
                                      </span>
                                      <p className="card-text small text-muted">
                                        <time dateTime="2018-05-24">{item.sub}</time>
                                      </p>
                                    </div>
                                    <div className="right-side ml-auto">
                                  <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item"  data-toggle="modal" data-target="#social_feature3">
                                          Edit
                                        </span>
                                        {/* <span className="dropdown-item">
                                          Delete
                                        </span> */}
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          ))}
                      </div>
                    </div>
                    {/* Feature 3 Banner End */}
                    <hr className="mt-4 mb-5" />
                    {/* Feature 4 Banner Start */}
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="d-flex">
                            <h1 className="header-title">Feature Banner 4</h1>
                            <button
                              className="btn btn-primary ml-auto btn-sm"
                              onClick={() => this.createBanner('banner4')}
                            >
                              Create Banner
                            </button>
                          </div>
                        </div>
                        <div className="spac w-100" />
                        {!banner4 ? '' : 
                          banner4.map((item, idx) => (
                        <div className="col-sm-12 col-md-3" key={idx}>
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 d-flex align-items-center">
                                    <div className="left-side">
                                    <span className="avatar avatar-4by3">
                                      <img src={item.image}
                                        alt="..."
                                        className="avatar-img rounded"
                                      />
                                    </span>
                                    </div>
                                    <div className="middle-side ml-3">
                                    <span className="h2 mb-0">
                                        <h4 className="mb-1">{item.title}</h4>
                                      </span>
                                      <p className="card-text small text-muted">
                                        <time dateTime="2018-05-24">{item.sub}</time>
                                      </p>
                                    </div>
                                    <div className="right-side ml-auto">
                                  <div className="dropdown">
                                      <a
                                        href="#"
                                        className="dropdown-ellipses dropdown-toggle"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="fe fe-more-vertical" />
                                      </a>
                                      <div className="dropdown-menu dropdown-menu-right">
                                        <span onClick={(e) => this.editBanner(e, item)} className="dropdown-item c-pointer"  data-toggle="modal" data-target="#social_feature4">
                                          Edit
                                        </span>
                                        <span onClick={(e) => this.deleteBanner(e, item, 'banner4', idx)} className="dropdown-item c-pointer">
                                          Delete
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                          ))}
                      </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                          <div className="container-fluid">
                              <div className="d-block text-white text-center">
                                   <span className="mr-2 navbar-brand footer-logo"> <img src={require('../../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                  <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                              </div>
                          </div>
                      </div>
                    {/* Feature 4 Banner End */}
  
                    {/* Modal Section */}
  
                    {/* Banner Modal Start */}
                    <SocialBannerModal {...socialProps} /> 
                    {/* Banner Modal End */}
  
                    {/* Feature  Modal Start */}
                    <SocialFeatureBannerModal {...socialProps} /> 
                    {/* Feature  Modal End */}
                    
                    {/* Feature 2  Modal Start */}
                    <SocialFeatureBannerTwoModal {...socialProps} /> 
                    {/* Feature 2 Modal End */}
                    <SocialFeatureBannerModal2 {...socialProps} /> 
                    <SocialFeatureBannerModal3 {...socialProps} /> 
                    <SocialFeatureBannerModal4 {...socialProps} /> 
                    {/* Modal Section End */}
                  </div>
                </React.Fragment>
    )
    }
    }