import React, { Component } from "react";
import { Link } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/airbnb.css";
import ReactQuill, { Quill } from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import Dropzone from "react-dropzone";
import { storageUrl } from "../../utils/BaseUrl";
import {
  getAPI,
  postAPI,
  postFile,
  postMultipleFile,
  putAPI,
} from "../../utils/api";
import Select from "react-select";
import moment from "moment";
import AceEditor from "react-ace";
import "react-ace-builds/webpack-resolver-min";

import jquery from "jquery";

import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css"; // import styles
import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/tooltip";
import "bootstrap/dist/css/bootstrap.css";

export default class Createblog extends Component {
  constructor(props) {
    super();

    this.state = {
      date: new Date(),
      blogPost: {
        title: "",
        content: "",
        image: "",
        order: "",
        bloggerName: "",
        languageUid: "",
        slug: "",
        hashtag: "",
        desc: "",
        categoryUid: "",
        publishedOn: "",
        ogFields: {
          ogTitle: "",
          ogDescription: "",
          ogImage: "",
        },
      },
      blogPostError: {
        titleError: "",
        contentError: "",
        imageError: "",
        orderError: "",
        bloggerNameError: "",
        slugError: "",
        descError: "",
        categoryUidError: "",
      },
      files_body_content: {},
      blogUid: "",
      blogCategories: [],
      categorySelect: {},
      isValid: true,
    };

    this.editor = React.createRef(null);

    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        ["blockquote", "code"],
        [({ list: "ordered" }, { list: "bullet" })],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link", "image", "video"],
        ["clean"],
      ],
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background",
      "link",
      "image",
      "video",
      "code",
    ];
  }

  componentDidMount() {
    if (this.props.langId) {
      this.setState({
        blogPost: {
          ...this.state.blogPost,
          languageUid: this.props.langId,
        },
      });
    }
    let blog = this.props?.location?.state?.blog;
    if (blog) {
      this.setState({
        blogPost: {
          ...this.state.blogPost,
          title: blog.title,
          content: blog.content,
          image: blog.image,
          order: blog.order,
          bloggerName: blog.bloggerName,
          languageUid: blog.languageUid,
          slug: blog.slug,
          hashtag: blog.hashtag,
          desc: blog.desc,
          publishedOn: blog.publishedOn,
          categoryUid: blog.blogCategoryUid,
          ogFields: blog.ogFields,
        },
        categorySelect: {
          label: blog.blogCategory?.name,
          value: blog.blogCategory?.uid,
        },
        blogUid: blog.uid,
      });
    }
    this.getCategoryBlogs();
  }

  getCategoryBlogs = () => {
    getAPI(`blogCategory/category/${this.props.langId}`)
      .then((res) => {
        let { data, status } = res.data;
        if (status === 1) {
          let blogCategories = data.map((ele) => {
            return { label: ele.name, value: ele.uid };
          });
          this.setState({
            blogCategories: blogCategories,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  handleChange = (e, s) => {
    if (typeof e === "string") {
      this.setState({
        blogPost: { ...this.state.blogPost, content: e },
        blogPostError: { ...this.state.blogPostError, contentError: "" },
      });
    } else if (s === "date") {
      this.setState({
        blogPost: {
          ...this.state.blogPost,
          publishedOn: moment(e[0]).format("YYYY-MM-DD"),
        },
      });
    } else if (e?.label) {
      this.setState({
        blogPost: {
          ...this.state.blogPost,
          categoryUid: e.value,
        },
        categorySelect: e,
        blogPostError: { ...this.state.blogPostError, categoryUidError: "" },
      });
    } else {
      let { name, value } = e.target;
      this.setState({
        blogPost: {
          ...this.state.blogPost,
          [name]: value,
        },
        blogPostError: {
          ...this.state.blogPostError,
          [`${name}Error`]: "",
        },
      });
    }
  };

  onImageUploadSumm = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      postFile("upload/upload/brand", acceptedFiles[0])
        .then((res) => {
          if (res.data.status === 1) {
            ReactSummernote.insertImage(
              storageUrl + res.data.data[0].s3key,
              "insertIMage"
            );
          } else {
            alert("Image not Uploaded");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  onDropBody = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      postFile("upload/upload/brand", acceptedFiles[0])
        .then((res) => {
          if (res.data.status === 1) {
            this.setState({
              blogPost: {
                ...this.state.blogPost,
                image: res.data.data[0].s3key,
              },
              files_body_content: acceptedFiles[0],
              blogPostError: {
                ...this.state.blogPostError,
                imageError: "",
              },
            });
          } else {
            alert("Image not Uploaded");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  validateBlogPost = async () => {
    let isValid = true;
    for (const iter of Object.keys(this.state.blogPost)) {
      if (this.state.blogPost[iter].length === 0) {
        await this.setState({
          blogPostError: {
            ...this.state.blogPostError,
            [`${iter}Error`]: "Field Required*",
          },
        });
        isValid = false;
      }
    }
    return isValid;
  };

  handleBlogPost = async (e) => {
    e.preventDefault();
    let validate = true;
    if (validate) {
      if (this.state.blogUid !== "") {
        putAPI(`blog/blog/${this.state.blogUid}`, this.state.blogPost)
          .then((res) => {
            const { status, data, message } = res.data;
            if (status === 1) {
              this.props.history.push("/blog");
            } else if (status === 2) {
              this.setState({
                blogPostError: {
                  ...this.state.blogPostError,
                  slugError: message,
                },
              });
            }
          })
          .catch((error) => console.log(error));
      } else {
        console.log(this.state.blogPost);
        postAPI("blog/addBlog", this.state.blogPost)
          .then((res) => {
            const { status, data, message } = res.data;
            if (status === 1) {
              this.props.history.push("/blog");
            } else if (status === 2) {
              this.setState({
                blogPostError: {
                  ...this.state.blogPostError,
                  slugError: message,
                },
              });
            }
          })
          .catch((error) => console.log(error));
      }
    }
  };

  handleOgDataChange = (e) => {
    console.log(e.target.getAttribute("name"), e.target.value);
    this.setState({
      blogPost: {
        ...this.state.blogPost,
        ogFields: {
          ...this.state.blogPost.ogFields,
          [e.target.getAttribute("name")]: e.target.value,
        },
      },
    });
  };

  render() {
    const { date, blogCategories, categorySelect } = this.state;
    const {
      titleError,
      slugError,
      contentError,
      imageError,
      bloggerNameError,
      orderError,
      descError,
      categoryUidError,
    } = this.state.blogPostError;
    const {
      title,
      slug,
      content,
      image,
      bloggerName,
      order,
      hashtag,
      desc,
      categoryUid,
      publishedOn,
      ogFields,
    } = this.state.blogPost;
    return (
      <>
        {console.log(this.state)}
        <div className="main-section mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="d-flex">
                  <h1 className="header-title">Blog</h1>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <span className="mr-2">Sort Order:</span>
                  <input
                    type="number"
                    className="form-control width-auto"
                    name="order"
                    value={order}
                    onChange={this.handleChange}
                  />
                  <span className="error-field-error">{orderError}</span>
                </div>
                {/* {isRequired && errors.order.length > 0 ?
                                    <span className="error-field-error">{errors.order}</span> : ''} */}
              </div>
            </div>
            <hr className="mt-4 mb-5" />
          </div>
          <div class="container">
            <form>
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Category</label>
                    <Select
                      value={categorySelect}
                      onChange={(e) => this.handleChange(e)}
                      options={blogCategories}
                      placeholder="Select Category..."
                    />
                    <span className="error-field-error">
                      {categoryUidError}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Title</label>
                    <input
                      type="text"
                      class="form-control"
                      name="title"
                      value={title}
                      placeholder="Name"
                      onChange={this.handleChange}
                    />
                    <span className="error-field-error">{titleError}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div class="form-group">
                    <label>Publised On</label>
                    <Flatpickr
                      className="form-control"
                      name="publishedOn"
                      value={publishedOn}
                      onChange={(e) => {
                        this.handleChange(e, "date");
                      }}
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    {/* <label>Description</label> */}
                    <ReactSummernote
                      value={content}
                      // codeview={true}
                      options={{
                        height: 350,
                        dialogsInBody: true,
                        toolbar: [
                          ["style", ["style"]],
                          ["font", ["bold", "underline", "clear"]],
                          ["fontname", ["fontname"]],
                          ["para", ["ul", "ol", "paragraph"]],
                          ["table", ["table"]],
                          ["insert", ["link", "picture", "video"]],
                          ["view", ["fullscreen", "codeview"]],
                        ],
                      }}
                      onImageUpload={(e) => this.onImageUploadSumm(e)}
                      onChange={this.handleChange}
                    />
                    {/* <TextEditor content={content} handleChange={this.handleChange} /> */}
                    {/* <AceEditor
                                            name='brace-editor'
                                            ref="aceEditor"
                                            mode='html'
                                            theme="xcode"
                                            autoScrollEditorIntoView={true}
                                            onChange={this.handleChange}
                                            value={content}
                                            fontSize={20}
                                            wrapEnabled={true}
                                            width={1000}
                                            height={700}
                                        /> */}
                    {/* <ReactQuill 
                                            theme="snow"
                                            modules={this.modules}
                                            formats={this.formats}
                                            onChange={this.handleChange}
                                            value={content}
                                        /> */}
                    <span className="error-field-error">{contentError}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Description</label>
                    <input
                      type="text"
                      class="form-control"
                      name="desc"
                      value={desc}
                      placeholder="Description"
                      onChange={this.handleChange}
                    />
                    <span className="error-field-error">{descError}</span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label> Images </label>
                    <div className="upload-img-box border w-100 position-relative over-hidden f-out">
                      <div className="dz-default dz-message py-5">
                        <Dropzone
                          onDrop={(e) => {
                            this.onDropBody(e);
                          }}
                          accept={"image/jpeg, image/png"}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="back-inp c-pointer"
                              />
                              <span className="dz-button center-box">
                                Drop file here to upload
                              </span>
                              {/* <img className="drop-image" src={this.state.files_Body  && this.state.files_Body }/> */}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                    <span className="error-field-error">{imageError}</span>
                    {image ? (
                      <div className="box-im d-flex align-items-center mt-4">
                        <div className="img-box-s mr-2">
                          <img
                            className="drop-image"
                            src={`${storageUrl + image}`}
                            alt="..."
                          />
                        </div>

                        <div className="show-img-info">
                          <h4 className="mb-0  f-size">
                            {this.state.files_body_content.name
                              ? this.state.files_body_content.name
                              : image.split("/").pop()}
                          </h4>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {/* <hr className="my-5" /> */}
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Blogger Name</label>
                    <input
                      type="text"
                      class="form-control"
                      name="bloggerName"
                      placeholder="Blogger Name"
                      value={bloggerName}
                      onChange={this.handleChange}
                    />
                    <span className="error-field-error">
                      {bloggerNameError}
                    </span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Slug</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Slug"
                      name="slug"
                      value={slug}
                      onChange={this.handleChange}
                    />
                    <span className="error-field-error">{slugError}</span>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Hashtag</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Hashtag"
                      value={hashtag}
                      name="hashtag"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                {/* OG tags fields */}
                <div className="col-12">
                  <div className="form-group">
                    <label>OG Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="OG Title..."
                      required="required"
                      name="ogTitle"
                      value={ogFields?.ogTitle ? ogFields?.ogTitle : ""}
                      onChange={(e) => this.handleOgDataChange(e)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>OG Description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="OG Description..."
                      required="required"
                      name="ogDescription"
                      value={
                        ogFields?.ogDescription ? ogFields?.ogDescription : ""
                      }
                      onChange={(e) => this.handleOgDataChange(e)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>OG Image</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="OG Image..."
                      required="required"
                      name="ogImage"
                      value={ogFields?.ogImage ? ogFields?.ogImage : ""}
                      onChange={(e) => this.handleOgDataChange(e)}
                    />
                  </div>
                </div>
                <hr className="my-5" />
              </div>

              <hr class="my-5" />
              <div class="row justify-content-center form-group">
                <div class="col-auto">
                  <button
                    class="btn  btn-white"
                    type="reset"
                    onClick={() => this.props.history.push("/blog")}
                  >
                    Cancel
                  </button>
                </div>
                <div class="col-auto">
                  <Link class="btn btn-primary" onClick={this.handleBlogPost}>
                    Publish
                  </Link>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
            <div className="container-fluid">
              <div className="d-block text-white text-center">
                <span className="mr-2 navbar-brand footer-logo">
                  {" "}
                  <img
                    src={require("../../Assets/img/kintlogo/knitpro-logo.svg")}
                    alt="..."
                  />{" "}
                </span>
                <p className="mb-0 opacity-8 font-sm">
                  Copyright © 2020 KnitPro. All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
