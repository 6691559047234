import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './Assets/css/theme.css'
import './Assets/css/style.css'
import './Assets/fonts/feather/feather.css'
// import './Assets/js/dropzone.min.js'

// import $ from 'jquery';
// import 'bootstrap/dist/css/bootstrap.css';
// window.jQuery = $;
// require('bootstrap');

ReactDOM.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
