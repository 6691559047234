import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import {getAPI ,deleteAPI} from '../utils/api';
import ReactHtmlParser from 'react-html-parser';
import { storageUrl } from '../utils/BaseUrl';


export default class MaterialDescription extends Component {
    
    state={
        descriptions:[],
        isLoading:false
    }
    componentDidMount(){
        this.getDescriptions();
    }
    componentDidUpdate(prevProps) {
        if (this.props.language !== prevProps.language) {
            this.getDescriptions();
        }
      }
    getDescriptions(){
        this.setState({isLoading:true})
        getAPI(`materialDesc/description/${this.props.langId}`).then(res=>{
            const {status,data}=res.data;
            console.log(data,status);
            if(status===1){
                this.setState({descriptions:data,isLoading:false})
            }else{
                this.setState({descriptions:[],isLoading:false})
            }
        }).catch(err=>{console.log(err)})
    }
    handleDelete=(e,uid)=>{
        const d={};
        deleteAPI(`materialDesc/description/${uid}`,d).then(res=>{
            const {status,data}=res.data;
            // console.log(status,data);
            this.getDescriptions();
        }).catch(err=>{
            console.log(err);
        })
    }
    render() {
        const {descriptions,isLoading}=this.state;
        return (
            <>
                <div className="main-section">
                    <div className="header">
                        <div className="container-fluid">
                            <div className="header-body">
                                <div className="row align-items-end">
                                    <div className="col">
                                        <h6 className="header-pretitle"> Overview</h6>
                                        <h1 className="header-title">Material Description</h1>
                                    </div>
                                    <div className="col-auto">
                                        <Link to={{pathname:"/creatematerialdescription"}} className="btn btn-primary lift">Add Description</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h4 className="card-header-title">Material Descriptions</h4>
                                    </div>
                                    <div className="table-responsive mb-0">
                                        <table className="table table-sm table-wrap card-table">
                                            <thead>
                                                <tr>
                                                <th >Category</th>
                                                <th >Material</th>
                                                <th colSpan="2">Description</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {isLoading
                                                    ? <tr>
                                                    <td colspan="3"> 
                                                        <div className="text-center">
                                                            <div class="spinner-border text-danger" role="status">
                                                                <span class="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                    :
                                                    descriptions.map((item,idx)=>(
                                                        <tr key={idx}>
                                                     <td>

                                                    <a href={item.icon} target="_blank"  className="avatar avatar-xs d-inline-block mr-2">
                                                    <img src={`${storageUrl+item?.coverImage}`} alt="..." className="avatar-img rounded-circle" />
                                                    </a>

                                                    <span>{item.category}</span>

                                                    </td>
                                                  <td>
                                                        {item.material}
                                                  </td>
                                                  <td className="width-40">{ReactHtmlParser(item.desc)}</td>
                                                  <td className="text-right">
                                                      <div className="dropdown">
                                                        <a href="/#" className="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe fe-more-vertical" />
                                                        </a>
                                                        <div className="dropdown-menu dropdown-menu-right">
                                                        <Link to={{pathname:"/creatematerialdescription",state:{status:1,data:item}}} className="dropdown-item">
                                                            Edit
                                                        </Link >
                                                        <a href="#!" className="dropdown-item" onClick={e=>{this.handleDelete(e,item.uid)}}>
                                                            Delete
                                                        </a>
                                                        </div>
                                                    </div>
                                                  </td>
                                                  
                                               </tr>
                                               
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer.border-0 d-md-none d-block bg-gradient py-3">
                        <div className="container-fluid">
                            <div className="d-block text-white text-center">
                                 <span className="mr-2 navbar-brand footer-logo"> <img src={require('../Assets/img/kintlogo/knitpro-logo.svg')} alt="..." />  </span> 
                                <p className="mb-0 opacity-8 font-sm">Copyright © 2020 KnitPro. All Rights Reserved.</p>
                            </div>
                        </div>
    
                    </div>
                    {/* modal for delete */}
                    {/* End */}
                    
                </div>

            </>
        )
    }
}